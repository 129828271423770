import React, { useEffect, useState } from "react";


const TableFooter = ({ range, setPage, page, slice, rowsPerPage, data }) => {
    const [numberOfPages, setNumberOfPages] = useState()

  useEffect(() => {
    const last = range[range.length - 1];
    setNumberOfPages(last)
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const handlePreviousPage = () => {
    if(page > 1){
        let prev = page -1 
        setPage(prev)
    }else {
        setPage(1)
    }

  }

  const handleNextPage = () => {
    if(page < numberOfPages){
        let prev = page + 1 
        setPage(prev)
    }else {
        setPage(numberOfPages)
    }
  }

  const handleCurrentPages = (amountOfRows, currentPage, data) => {
    let totalEmails = data.length

    let currentRange = currentPage*amountOfRows
    let currentStartingRange = currentRange - amountOfRows + 1

    let string = `${currentStartingRange} - ${currentRange} of ${totalEmails}`

    return string


  }
  return (
    <div className={'flex items-center'}>

        <div className='text-gray-600 dark:text-gray-300 pr-4 text-sm'>
            {handleCurrentPages(rowsPerPage, page, data)}
        </div>
        <nav >
            <ul className="flex items-center -space-x-px h-10 text-base">
                <li>
                    <button onClick={handlePreviousPage} className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <span className="sr-only">Previous</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                        </svg>
                    </button>
                </li>
                {/* {range.map((el, index) => (
                    <li>
                        <button 
                            key={index}
                            onClick={() => setPage(el)} 
                            className={`flex items-center justify-center px-4 h-10 leading-tight bg-white border border-gray-300 text-gray-400 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-white ${
                                    page === el ? 'text-gray-600 bg-gray-100 dark:text-white dark:bg-gray-900' : 'text-gray-300'
                                }`}
                        >
                        {el}
                        </button>
                    </li>
                ))} */}
                <li>
                <button onClick={handleNextPage} className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    <span className="sr-only">Next</span>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                    </svg>
                </button>
                </li>
            </ul>
        </nav>

    </div>
  );
};

export default TableFooter;