import React, { useEffect, useState } from 'react'
import PieChart from '../components/charts/PieChart';
import LineAreaChart from '../components/charts/LineAreaChart';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import UptimeLineChart from '../components/charts/UptimeLineChart';
import DeviceInfoModal from '../components/modals/deviceModals/DeviceInfoModal';

function DeviceOverviewPage({selectedDevice, deviceInfo}) {
  const [storagePieChartData, setStoragePieChartData] = useState([])
  const [totalSizeSummary, setTotalSizeSummary] = useState('')
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [todayPieChartData, setTodayPieChartData] = useState([])
  const [todaysTotalSumSummary, setTodaysTotalSumSummary] = useState('')
  const [todaysTotalSumInteger, setTodaysTotalSumInteger] = useState('')
  const [monthsPieChartData, setMonthsPieChartData] = useState([])
  const [monthsTotalSumSummary, setMonthsTotalSumSummary] = useState('')
  const [monthsTotalSumInteger, setMonthsTotalSumInteger] = useState('')
  const [interactionsData, setInteractionsData] = useState([])
  const [uptimeData, setUptimeData] = useState([])
  
  useEffect(() =>{
      try {
          if(selectedOrganization && selectedDevice && Object.keys(selectedDevice).length > 0){
              getStorageData()
              getTodaysData()
              getMonthsData()
              getHistoricalInteractions()
              getUptimeData()
          }
          
      } catch (error) {
          
      }
  },[selectedOrganization, selectedDevice])

  const getUptimeData = () => {
      getData(apiLink + '/v1/clients/' + selectedDevice.device_uuid + '/historical_status/highcharts')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data && data.data && Object.keys(JSON.parse(data.data)).length > 0 ){

          let upData =  JSON.parse(data.data)
          setUptimeData(upData)
          
        }
      }
    });
  }

  const getHistoricalInteractions = () => {
      getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/interaction_count/historical/highcharts')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        if(data && data.data && Object.keys(JSON.parse(data.data)).length > 0 ){

          let historicalData =  JSON.parse(data.data)
          setInteractionsData(historicalData)
          
        }
      }
    });
  }

  const getStorageData = () => {
      // Get data messages sizes
      getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/data_streams_interaction_size')
      .then(async response => {
      if(response.ok ){
          const data = await response.json();
          
          if(data && data.data){

            var dataSizes = data.data.map(result =>({
              name: result.data_stream_name,
              y: parseInt(result.size),
              prettyY: formatBytes(result.size),
              device_uuid: result.data_stream_uuid
            }))

            let sizeSum = 0
            for(var i = 0; i < Object.keys(dataSizes).length; i++) {
              sizeSum = sizeSum + dataSizes[i].y
            }

          var formattedSize = formatBytes(sizeSum)

          setTotalSizeSummary(formattedSize)
          setStoragePieChartData(dataSizes)

          }
      }
      });
  }

  const getTodaysData = () => {// Get today project interactions
      getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/data_streams_interaction_count/today')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data && data.data && Object.keys(data.data).length > 0){
            
  
            var todaysData = data.data.map(result =>({
                name: result.device_name,
                y: parseInt(result.count),
                device_uuid: result.device_uuid
            }))
            var todaysSum = 0
            for(var i = 0; i < Object.keys(todaysData).length; i++) {
              todaysSum = todaysSum + todaysData[i].y
            }
            var todaysSumInteger = todaysSum;
            if(todaysSumInteger >= 1000000){
              todaysSum = (Number.parseFloat(todaysSumInteger)/1000000.00).toFixed(1)
              todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
              todaysSum = todaysSum+"M"
            }
            else if( todaysSumInteger >= 100000  ){
              todaysSum = (Number.parseFloat(todaysSumInteger)/1000.00).toFixed(1)
              todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
              todaysSum = todaysSum+"k"
            }
            else{
              todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
            }
  
            setTodaysTotalSumInteger(todaysSumInteger)
            setTodaysTotalSumSummary(todaysSum)
            setTodayPieChartData(todaysData)
  
            
          }
        }
      });
  }

  const getMonthsData = () => {
  
      // Get months project interactions
      getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/data_streams_interaction_count/this_month')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data && data.data && Object.keys(data.data).length > 0){
            
            var monthsData = data.data.map(result =>({
                name: result.device_name,
                y: parseInt(result.count),
                device_uuid: result.device_uuid
            }))
  
  
            var monthsSum = 0
            for(var i = 0; i < Object.keys(monthsData).length; i++) {
              monthsSum = monthsSum + monthsData[i].y
            }
  
            var monthsSumInteger = monthsSum;
            if(monthsSum > 1000000){
              monthsSum = (Number.parseFloat(monthsSum)/1000000.00).toFixed(2)
              monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
              monthsSum = monthsSum+"M"
            }
            else if( monthsSum > 100000 ){
              monthsSum = (Number.parseFloat(monthsSum)/1000.00).toFixed(1)
              monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
              monthsSum = monthsSum+"k"
            }
            else {
              monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
            }
            
            setMonthsTotalSumInteger(monthsSumInteger)
            setMonthsTotalSumSummary(monthsSum)
            setMonthsPieChartData(monthsData)
          }
        }
      });
  }

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
  }

  return (
    <div>
      <div className='flex flex-col'>
          {deviceInfo && Object.keys(deviceInfo).length > 0 ?(
            <div className='flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 '>
              <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full '>
                <UptimeLineChart data={uptimeData} chartName='Uptime' tooltipTitle='Uptime'/>
              </div>
              <div className='relative bg-slate-50 dark:bg-slate-800 rounded-xl w-full max-w-[600px] mt-2 flex flex-col p-4'>
                <div className='absolute top-0 right-0'>
                  <DeviceInfoModal btnType='tableBtn' device={selectedDevice} />
                </div>
                <div className='flex w-full justify-around'>
                  <div className='flex flex-col p-2'>
                    <span className='text-gray-500 dark:text-gray-400'>% CPU</span>
                    <span className='text-gray-600 dark:text-gray-300 text-lg'>{deviceInfo.cpu_load_percent ? deviceInfo.cpu_load_percent : "-" }</span>
                  </div>
                  <div className='flex flex-col p-2'>
                    <span className='text-gray-500 dark:text-gray-400'>Memory used</span>
                    <span className='text-gray-600 dark:text-gray-300 text-lg'>{formatBytes(deviceInfo.memory_used_bytes)}</span>
                  </div>
                  <div className='flex flex-col p-2'>
                    <span className='text-gray-500 dark:text-gray-400'>Memory free</span>
                    <span className='text-gray-600 dark:text-gray-300 text-lg'>{formatBytes(deviceInfo.memory_free_bytes)}</span>
                  </div>
                </div>
                <div className='flex flex-col justify-between h-full pt-2'>
                  <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 text-sm'>MAC Address:&nbsp; </span>
                    <span className='text-gray-600 dark:text-gray-300 '>{deviceInfo.default_network_mac}</span>
                  </div>
                  <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 text-sm'>IPv4 Address:&nbsp;</span>
                    <span className='text-gray-600 dark:text-gray-300 '>{deviceInfo.default_network_ip4}</span>
                  </div>
                  <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 text-sm'>IPv4 Subnet Mask:&nbsp; </span>
                    <span className='text-gray-600 dark:text-gray-300 '>{deviceInfo.default_network_ip4subnet}</span>
                  </div>
                  <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 text-sm'>OS Architecture:&nbsp;</span>
                    <span className='text-gray-600 dark:text-gray-300 '>{deviceInfo.os_architecture}</span>
                  </div>
                  <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 text-sm'>OS Distribution:&nbsp;</span>
                    <span className='text-gray-600 dark:text-gray-300 '>{deviceInfo.os_distribution}</span>
                  </div>
                </div>
              </div>
            </div>
          ):(
            <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full mt-2'>
              <UptimeLineChart data={uptimeData} chartName='Uptime' tooltipTitle='Uptime'/>
            </div>
          )}
          <div className='flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-2 mt-2'>
              <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                  <PieChart chartName='Storage' data={storagePieChartData} centerValue={totalSizeSummary} subCenterValue='' />
              </div>
              <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                  <PieChart chartName="Today's Messages" data={todayPieChartData} centerValue={todaysTotalSumSummary} subCenterValue={todaysTotalSumInteger} />
              </div>
              <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                  <PieChart chartName="Month's Messages" data={monthsPieChartData} centerValue={monthsTotalSumSummary} subCenterValue={monthsTotalSumInteger} />
              </div>
          </div>
          <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full mt-2'>
              <LineAreaChart data={interactionsData} chartName='Published Messages' tooltipTitle='Interactions' />
          </div>
      </div>
    </div>
  )
}

export default DeviceOverviewPage