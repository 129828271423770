import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'

function GeneralFormInput({label, type, placeholder, name, onChange, value, defaultValue}) {
    const isDark = useRecoilValue(darkModeState);
  return (
    <div>
        <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={name}>
            {label}
        </label>
        <input 
        className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
        id={name} 
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        />
    </div>
  )
}

export default GeneralFormInput