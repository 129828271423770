import {atom} from 'recoil'

export const userInfo = atom({
    key:'userInfo',
    default: {},
})
// export const addSensorModalState = atom({
//     key:'addSensorModalState',
//     default: false,
// })
// export const addGatewayModalState = atom({
//     key:'addGatewayModalState',
//     default: false,
// })