import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { getData, putData } from "../ApiCalls/DataApis";
import { apiLink } from "../ApiCalls/ApisVariables";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import toast from 'react-hot-toast';
import CardInfoRow from "./CardInfoRow";
import CurrentInvoiceTab from "./CurrentInvoiceTab";
import PastInvoicesTab from "./PastInvoicesTab";
import AddCardModal from "../components/modals/billingModals/AddCardModal";
import {darkModeState} from '../atoms/darkModeState'

const subNav = [
  {
      name:'Current Invoice',
      url:'currentInvoice'
  },
  {
      name:'Invoices',
      url:'previousInvoices'
  },
]


function BillingPage() {
  const isDark = useRecoilValue(darkModeState);
  const [selectedSubNav, setSelectedSubNav] = useState('currentInvoice')
  const [selectedTab, setSelectedTab] = useState(subNav[0]);
  const [userCards, setUserCards] = useState([]);
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [editInvoiceEmail, setEditInvoiceEmail] = useState('');
  const [defaultPayment, setDefaultPayment] = useState('');
  const [loadingInvoiceEmail, setLoadingInvoiceEmail] = useState(false);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);

 

  useEffect(() =>{
      setSelectedSubNav(selectedTab.url)
  },[selectedTab])

  useEffect(() => {
    if(selectedOrganization){
      getPaymentCards()
      getCustomerBilling()
    }
  }, [selectedOrganization])

  const getPaymentCards = () =>{
    try{
      getData(apiLink + '/v1/organizations/' + selectedOrganization + '/payment_methods/card')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();

          if(data.data){
            setUserCards(data.data)
          }

          if(data.data == ''){
            // Remove alert later after modal is created
            alert('Payment Required. Please add credit card to your account')
          }
        }
      });
    } catch (error){
      console.log(error.message)
    }
  }

  const getCustomerBilling = () => {
    try {
      getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/customer')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data && data.invoice_settings.default_payment_method){
            setDefaultPayment(data.invoice_settings.default_payment_method)
          }

          if(data && data.invoice_settings.default_payment_method){
            setInvoiceEmail(data.email)
          }
        }
      });
    } catch(error){
      console.log(error.message)
    }
  }
  


  const handleSubmitInvoiceEmailChange = () => {
    try{
      if(!loadingInvoiceEmail){
        setLoadingInvoiceEmail(true)
        putData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/email', 
          {'email': invoiceEmail})
            .then(async response => {
              if(response.ok){
                toast.success('Invoice email updated')
                setLoadingInvoiceEmail(false)
                setEditInvoiceEmail(false)
                getCustomerBilling()
              }
              else{
                try{
                  const data = await response.json();
                  toast.error(data.message)
                }catch (e){
                  toast.error('Something went wrong')
                }
              }
            })
      }
    } catch (error){
      toast.error(error.message)
    }
  }


  return (
    <div className='pl-6 pr-6 py-1 mt-4 max-h-[calc(100vh-5rem)] mx-auto overflow-scroll scrollbar-hide' >

      <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
        <span className='text-lg font-bold text-gray-800 dark:text-gray-300'>{'Account'}</span>
          {/* <AddCardModal btnType={'standardBtn'} isDark={isDark} selectedOrganization={selectedOrganization} getPaymentCards={getPaymentCards}/> */}
      </div>

      <div className='flex flex-col space-y-4 mt-4 text-base pl-2 mb-6 pb-4 border-b-[0.01em] dark:border-slate-700'>
        <div className='flex'>
            <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Customer Billing</div>
            <div className='flex '>
                <input 
                    className={`bg-transparent w-full max-w-[300px] border rounded-md py-1 px-3 shadow-sm text-sm 
                    focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                    dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                    placeholder:text-gray-400 text-gray-600 border-gray-200 
                    disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                    id={'invoiceEmail'} 
                    type={'text'}
                    name={'invoiceEmail'}
                    value={invoiceEmail}
                    onChange={(e) => setInvoiceEmail(e.target.value)}
                    disabled={!editInvoiceEmail}
                />
                {editInvoiceEmail ? (
                  <div className='flex mt-auto'>
                    <button className={`shadow rounded-lg py-1 px-2 border-[1px] text-sm dark:text-white dark:hover:text-gray-200 dark:border-gray-400 text-gray-600 hover:text-gray-900 border-gray-300 cursor-pointer flex ml-2`} onClick={() => setEditInvoiceEmail(false)}>
                        Cancel
                    </button>
                    <button 
                      className={`relative shadow rounded-lg ml-1 py-1 px-2 border-[1px] border-blue-600 text-sm bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                      onClick={handleSubmitInvoiceEmailChange}
                      disabled={loadingInvoiceEmail}>
                        Save
                        {loadingInvoiceEmail && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                    </button>
                  </div>

                ):(
                  <div className='mt-auto'>
                    <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditInvoiceEmail(true)} >
                        <span className='mr-1'>Edit</span>
                        <svg className='w-4 h-4' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                    </button>
                  </div>
                )}
            </div>
        </div>
      </div>

      <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
        <span className='text-lg font-bold text-gray-800 dark:text-gray-300'>Cards</span>
          <AddCardModal btnType={'standardBtn'} isDark={isDark} selectedOrganization={selectedOrganization} getPaymentCards={getPaymentCards}/>
      </div>

      {/* Cards list */}
      <div className='space-y-2 min-h-[120px] max-h-[250px] overflow-scroll scrollbar-hide px-1 pb-16 pt-4 border-b-[0.01em] dark:border-slate-700'>
        {selectedOrganization && userCards && Object.keys(userCards).length > 0 &&
          userCards.map((card, index) => (
            <div key={card.id} >
              <CardInfoRow card={card} index={index} defaultPayment={defaultPayment} getCustomerBilling={getCustomerBilling} selectedOrganization={selectedOrganization} getPaymentCards={getPaymentCards} />
            </div>
          ))
        }
      </div>


      <div className='z-[-1] mt-4'>
          <div className='ml-4 mr-4 mt-4'>
              <div className='flex flex-col-reverse sm:flex-row w-full mt-3'>
                  <nav>
                      <div className='flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-3 border-b border-blue-600 w-fit'>
                      {subNav.map((item) => (
                          <div
                          key={item.url}
                          className='relative pt-0.5 pb-1.5'
                          onClick={() => setSelectedTab(item)}
                          >
                          <span className={`${item.url === selectedTab.url ? 'font-bold':' font-semibold'} mr-1 pb-1 text-gray-600 dark:text-gray-100  hover:font-black  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap`}>{`${item.name}`}</span>
                          {item === selectedTab ? (
                              <motion.div className="underline" layoutId="underline" />
                          ) : null}
                          </div>
                      ))}
                      </div>
                  </nav>
              </div>
          </div>
          {selectedOrganization && subLinkPage(selectedSubNav, selectedOrganization)}
      </div>


    </div>
  )
}

export default BillingPage



const subLinkPage = (link, selectedOrganization) => {
  const allLinks = {
      currentInvoice: { link: "currentInvoice", content: <CurrentInvoiceTab selectedOrganization={selectedOrganization} />},
      previousInvoices: { link: "previousInvoices", content: <PastInvoicesTab selectedOrganization={selectedOrganization}/> },
      insights: { link: "insights", content: <div className='text-[28px]'>🧀 🥬</div> },
  }

    return (
      <AnimatePresence mode='wait'>
      <motion.div
      key={allLinks[link].link}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {allLinks[link].content}
      </motion.div>
    </AnimatePresence>
  )  

          
}