import IoTFlowsIcon from '../images/IoTFlowsIcon.png'
import { useState, Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useRecoilState } from 'recoil';
import {addOrgModalState} from '../atoms/modalStates'
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useNavigate, useLocation } from 'react-router-dom';
import {globalSelectedOrganization} from '../atoms/orgStates'


function OrganizationDropdownList({orgList, showGODview}) {
    const [selectedOrganization, setSelectedOrganization] = useState(orgList[0])
    const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useRecoilState(addOrgModalState)
    const [currentSelectedOrg, setCurrentSelectedOrg] = useRecoilState(globalSelectedOrganization)
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        if(location && location.pathname){
            let paths = location.pathname.split("/")

            if(orgList && Object.keys(orgList).length > 0 && paths && paths[1]){
                orgList.map(org => {
                    if(org.organization_uuid == paths[1]){
                        setSelectedOrganization(org)
                        setCurrentSelectedOrg(org.organization_uuid)
                    }
                }) 
            }else{
                setSelectedOrganization(orgList[0])
                setCurrentSelectedOrg(orgList[0].organization_uuid)
                navigate(`/`)
            }

        }
    },[orgList])

    const handleSelectedOrganizationChange = (e) => {
        
        if(e === 'newOrganization'){
            console.log(e)
            setIsAddOrgModalOpen(true)
        }else{
            if(e && e.organization_uuid){
                setSelectedOrganization(e)
                setCurrentSelectedOrg(e.organization_uuid)
                navigate(`/${e.organization_uuid}`)
            }
        }
        
    }

   

  return (
    <div>   
        

        <Listbox as="div" value={selectedOrganization} onChange={handleSelectedOrganizationChange} className='relative h-7 z-[999]'>
            <Listbox.Button>
            <div className='flex space-x-1 max-w-[200px] items-center cursor-pointer'>
                {selectedOrganization && selectedOrganization.organization_logo_url?(
                        <img
                            src={selectedOrganization.organization_logo_url + '?'+ String(Math.random())}
                            alt={selectedOrganization.organization_name}
                            className='h-7 w-7 rounded-md cursor-pointer'
                        />
                    ) : (
                        <div className='text-gray-400 dark:text-gray-300 h-7 w-7 rounded-md cursor-pointer items-center  group-hover:text-gray-300'>
                            <CorporateFareIcon style={{height:'1.50rem', width:'1.50rem', margin:'auto'}} />
                        </div>
                            
                    )}
                <div className='text-gray-600 dark:text-gray-100 text-sm hidden sm:inline-flex truncate'>{selectedOrganization?.organization_name}</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 text-gray-600 dark:text-gray-100 hidden sm:inline-flex">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>

            </div>
            </Listbox.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Listbox.Options className="absolute left-32 w-[260px] max-h-[600px] scrollbar-hide overflow-y-scroll -translate-x-1/2 bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg ">
                {orgList.map((org) => (
                    <Listbox.Option key={org.organization_uuid} value={org}>
                    <div className='group flex w-full items-center cursor-pointer rounded-lg py-1 pl-1 pr-3 hover:bg-blue-600 text-gray-600 hover:text-white'>
                    {org.organization_logo_url?(
                        <img
                            src={org.organization_logo_url}
                            alt={org.organization_name}
                            className='h-7 w-7 rounded-md cursor-pointer'
                        />
                    ) : (
                        <div className='text-gray-400 dark:text-gray-300 h-7 w-7 rounded-md cursor-pointer items-center group-hover:text-gray-300'>
                            <CorporateFareIcon style={{height:'1.50rem', width:'1.50rem', margin:'auto'}} />
                        </div>
                            
                    )}
                        <div className='pl-1 dark:text-gray-100 text-sm truncate'>{org.organization_name}</div>
                    </div>
                    </Listbox.Option>
                ))}
                    {showGODview &&
                    <Listbox.Option  value={'newOrganization'}>
                    <div className='flex space-x-1 items-center cursor-pointer rounded-lg py-1.5 mb-1 pl-1 pr-3 hover:bg-blue-600 text-gray-600 hover:text-white'>
                        <svg className='h-5 w-5 mx-1 dark:text-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        <div className=' dark:text-gray-100 text-sm truncate'>Add Organization</div>
                    </div>
                    </Listbox.Option>
                    }
                </Listbox.Options> 
            </Transition>
        </Listbox>
    </div>
  )
}

export default OrganizationDropdownList