import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {darkModeState} from '../atoms/darkModeState'
import {globalSelectedOrganization} from '../atoms/orgStates'
import OrganizationDropdownList from './OrganizationDropdownList';
import ProfileDropdown from './ProfileDropdown';
import MemberRoleButton from './MemberRoleButton';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import MembersHeader from '../MyMembers/MembersHeader';
import { useNavigate } from 'react-router-dom';


function Header({orgList, user, updateOrganizationsList}) {
    const navigate = useNavigate()
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [showGODview, setShowGODview] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)



    useEffect(() => {
      // Determining if user had GOD view access
      getData(apiLink + '/v1/users/iotflows')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
            setShowGODview(true)
          } 
        }
      });

    }, [])

    const handleChangeDarkMode = (e) => {
      setIsDark(e.target.checked)
      putData(apiLink + '/v1/users/darkmode', 
        {'user_prefers_dark_mode': e.target.checked})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // setLoading(false)
              // getPartList()
              // toast.success('Part has been edited')
              // closeModal()
            }
            else{
              try{
                const data = await response.json();
                // toast.error(data.message)
              }catch (e){
                // toast.error('Something went wrong')
              }
            }
          })

    }


    
    return (
      <>
        <div className={`shadow-sm border-b dark:bg-slate-900 dark:border-slate-700 sticky top-0 z-50`}>
          <header className="bg-slate-100 grid grid-cols-10 gap-2 dark:bg-slate-900 items-center py-0.5">
            <div className='flex col-span-5 items-center ml-2 sm:ml-4' >
              <OrganizationDropdownList orgList={orgList} showGODview={showGODview} /> 
            </div>

            {/* right side items */}
            <div className="col-span-5 ml-auto flex items-center justify-end space-x-4 mr-4">
              {selectedOrganization && <MembersHeader selectedOrganization={selectedOrganization}/>}
              {showGODview && 
              <MemberRoleButton updateOrganizationsList={updateOrganizationsList} />
              }
                <div className='hidden md:inline-flex'>
                  <Switch
                    size="small"
                    checked={isDark}
                    // onChange={(e) => setIsDark(e.target.checked)}
                    onChange={handleChangeDarkMode}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
                {selectedOrganization && 
                  <button className="navBtn " onClick={() => navigate(`/${selectedOrganization}/settings/organization`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </button>

                }
                {/* <MenuIcon className="navBtnMobile "/> */}
                <ProfileDropdown user={user} /> 

            </div>
          </header>
        </div>
      </>
    )

}

export default Header
