import {atom} from 'recoil'

export const addOrgModalState = atom({
    key:'addOrgModalState',
    default: false,
})
// export const selectedEditOrgId = atom({
//     key:'selectedEditOrgId',
//     default: '',
// })
// export const addAssetModalState = atom({
//     key:'addAssetModalState',
//     default: false,
// })
// export const addSensorModalState = atom({
//     key:'addSensorModalState',
//     default: false,
// })
// export const addGatewayModalState = atom({
//     key:'addGatewayModalState',
//     default: false,
// })
// export const sendUrlLinkModalState = atom({
//     key:'sendUrlLinkModalState',
//     default: false,
// })