import React, { useState } from 'react'
import TableFooter from "../components/TableFooter";
import useTable from "../components/hooks/useTable";
import ApiKeysTableRow from './ApiKeysTableRow';
import CreateApiKeyModal from '../components/modals/ApiKeysModals/CreateApiKeyModal';


function ApiKeysTable({data, rowsPerPage, refreshClientList}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <table className="table-auto w-full">
            {/* <colgroup >
                <col className='w-full'/>
                <col className='w-[200px]'/>
                
            </colgroup> */}
        <thead >
            <tr className='' >
                <th className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' colSpan={3}>
                        IoT API Keys
                </th>
                <th className='' colSpan={2}>
                    <div className='ml-auto'>
                        <CreateApiKeyModal btnType='standardBtn' refreshClientList={refreshClientList}/>
                    </div>
                </th>
            </tr>
            <tr className=''>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3' colSpan={5}>
                    <div className='flex justify-end'>
                        <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
                    </div>
                </th>
            </tr>
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Name`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Authorization`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Description`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Client username`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{``}</th>
            </tr>
        </thead>
          <tbody className=''>
          {slice.map((el,index) => (
            <ApiKeysTableRow el={el} index={index} key={index} refreshClientList={refreshClientList} />
          ))}
        </tbody>
      </table>
  )
}

export default ApiKeysTable