import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';


function LineAreaChart({chartName, data, tooltipTitle}) {
    const [chartOptions, setChartOptions] = useState({})
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        if(data){
            setChartOptions({
                credits: {enabled: false},
                title: {
                  text: '',
                },
                chart: {
                  height: '200px',
                  backgroundColor: 'transparent'
                },
                scrollbar: {
                  enabled: false
                },
                time: {
                  timezoneOffset: new Date().getTimezoneOffset()
                },
                xAxis: {
                    labels: {
                        style: {
                            color: isDark ? '#d1d5db':'#6b7280'
                        }
                    }
                },
                navigator: {
                  height: 15,
                  outlineWidth: 0,
                  outlineColor: '#ffffff',
                  xAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  yAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  series: {
                      lineWidth: 0,
                  }
                },
                rangeSelector:{
                  allButtonsEnabled: true,
                  buttons: [
                    {
                    type: 'day',
                    count: 1,
                    text: '1d'
                  }, {
                    type: 'day',
                    count: 7,
                    text: '7d'
                  }, {
                    type: 'day',
                    count: 14,
                    text: '14d'
                  },{
                    type: 'month',
                    count: 1,
                    text: '1m'
                  },{
                    type: 'all',
                    text: 'All'
                  }],
                  selected: 4,
                  buttonTheme: { // styles for the buttons
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 8,
                    style: {
                        color: isDark? '#fff': '#3b82f6',
                        fontWeight: 'bold'
                    },
                    states: {
                        hover: {
                        },
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    // disabled: { ... }
                    }
                    },
                },
                plotOptions: {
                  area: {
                      marker: {
                          radius: 2
                      },
                      lineWidth: 1,
                      states: {
                          hover: {
                              lineWidth: 1
                          }
                      },
                      threshold: null
                  }
                },
                series: [{
                  type: 'area',
                  color: '#60a5fa',
                  name: tooltipTitle,
                  data: data, 
                  color: {
                    linearGradient: {
                      x1: 0,
                      x2: 0,
                      y1: 0,
                      y2: 1
                    },
                    stops: [
                      [0, 'rgba(96, 165, 250,0.8)'],
                      [1, 'rgba(96, 165, 250,0.3)']
                      // [0, 'rgba(1,31,75,0.8)'],
                      // [1, 'rgba(3,57,108,0.4)']
                    ]
                  },
                  navigator: {
                    series: {
                        color: '#4caf50',
                    }
                  },
                }]
              })
        }
    }, [data, isDark])


  return (
    <div className='flex flex-col min-w-[225px]'>
        <div className='w-full flex justify-center text-xl text-gray-600 dark:text-gray-300 pt-1'>
            {chartName}
        </div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={chartOptions}
            />
        
    </div>
  )
}

export default LineAreaChart