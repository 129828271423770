import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';

function PieChart({chartName, data, centerValue, subCenterValue}) {
    const [chartOptions, setChartOptions] = useState({})

    useEffect(() => {
        if(data){
            setChartOptions({
                chart: {
                  // renderTo: 'container',
                  backgroundColor: null,
                  height: 140,
                  // width: 125,
                },
                colors: ['rgba(1,31,75,0.8)','rgba(3,57,108,0.8)','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
                // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
                credits: {enabled: false},
                title: {
                  text: "",
                  verticalAlign: 'middle',
                  style: { 
                    color:'#000',
                    fontSize: '22px',
                  } 
                },
                exporting: {
                  enabled: false
                },
                series: [{
                  type: 'pie',
                  name: 'Message storage',
                  innerSize: '80%',
                  data: data
                }],
                plotOptions: {
                  // series: {
                  //   animation: false
                  // },
                  pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      size: 115,
                      dataLabels: {
                          enabled: false
                      },
                      borderWidth: 0,
                      margin: 0,
                  }
                },
                legend: {
                  itemDistance:0,
                },
                tooltip: {
                  pointFormat: '{series.name}: {point.prettyY}'
                }
            })
        }
    }, [data])


  return (
    <div className='flex flex-col min-w-[225px]'>
        <div className='w-full flex justify-center text-xl text-gray-600 dark:text-gray-300 pt-1'>
            {chartName}
        </div>
        <div className='relative'>
            <div className='absolute inset-1/3 flex flex-col text-center'>
                <span className='text-xl text-gray-600 dark:text-gray-300'>{centerValue}</span>
                <span className='text-sm text-gray-500 dark:text-gray-400'>{subCenterValue}</span>
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'chart'}
                options={chartOptions}
            />
        </div>
        
    </div>
  )
}

export default PieChart