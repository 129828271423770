import React, { useEffect, useState } from 'react'
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import InstanceTable from './InstanceTable';
import FileManagementTable from './FileManagementTable';

function MainInstancesPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [cloudServersList, setCloudServersList] = useState([])
    const [selectedCloudServer, setSelectedCloudServer] = useState([]);
    const [openFileManager, setOpenFileManager] = useState(false);

    useEffect(() => {
        if(selectedOrganization){
            refreshInstanceList()
        }
    },[selectedOrganization])

    const refreshInstanceList = () => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/cloud-servers')
            .then(async response => {
            const data = await response.json();
              if(response.ok ){
                if(data && data.data){
                    // console.log(data.data)
                    setCloudServersList(data.data)
                }
              }
            });

        }
      }

  return (
    <div className={`overflow-scroll scrollbar-hide max-h-[calc(100vh-5rem)] `}> 
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
            <InstanceTable data={cloudServersList} rowsPerPage={3} refreshInstanceList={refreshInstanceList} setSelectedCloudServer={setSelectedCloudServer} setOpenFileManager={setOpenFileManager}  />
        </div>
        {openFileManager &&  Object.keys(selectedCloudServer).length > 0 &&
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
          <FileManagementTable currentPath={'/'} instrumentType_uuid={`cloud-servers/${selectedCloudServer.cloud_server_uuid}`} server_url_ssh={selectedCloudServer.cloud_server_dns_record_name} serverName={selectedCloudServer.cloud_server_name}/>
        </div>
         }
    </div>
  )
}

export default MainInstancesPage