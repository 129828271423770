import React, { useEffect, useState } from 'react'
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import { LinearProgress } from '@mui/material';
import { darkModeState } from '../atoms/darkModeState';
import DataStreamTable from './DataStreamTable';

function DeviceDataStreamsPage({selectedDevice}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isDark = useRecoilValue(darkModeState);
    const [fetchingData, setFetchingData] = useState(true)
    const [rows, setRows] = useState([])
    const [topics_list, setTopics_list] = useState([])
    const [organization_data_streams_dic, setOrganization_data_streams_dic] = useState([])
    const [organization_clients_dic, setOrganization_clients_dic] = useState([])
    const [searchedRows, setSearchedRows] = useState([])
    const [inputText, setInputText] = useState('')
    const [data_streams_list, setData_streams_list] = useState([])
    const [dataStreamMatchList, setDataStreamMatchList] = useState([])
    const [topicMatchList, setTopicMatchList] = useState([])
    const [showInputDropdown, setShowInputDropdown] = useState(false)
    const [isSearched, setIsSearched] = useState(false)
    const [filterItems, setFilterItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [isDataStreamSearch, setIsDataStreamSearch] = useState(false)
    const [isTopicSearch, setIsTopicSearch] = useState(false)
    const [isSubTopicSearch, setIsSubTopicSearch] = useState(false)
    const [isPayloadSearch, setIsPayloadSearch] = useState(false)
    const [isTopicPayloadSearch, setIsTopicPayloadSearch] = useState(false)
    const [currentSearchedValue, setCurrentSearchedValue] = useState(false)
    const [searchMoreData, setSearchMoreData] = useState(true)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    
    useEffect(() => {
        window.addEventListener('scroll',  (event) => {
            
                
                if(searchMoreData && (currentSearchedValue || isDataStreamSearch ||  isTopicPayloadSearch ||  isTopicSearch ||  isSubTopicSearch ||  isPayloadSearch ||  startDate)){
                const winScroll =
                document.body.scrollTop || document.documentElement.scrollTop
            
            
                const height =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight
            
                const scrolled = winScroll / height
            
                console.log('scrolled', scrolled)
                
                if(scrolled > 0.5 ){
                if(searchMoreData){
                    setSearchMoreData(false)
                    let lastRow = "";
                    let lastTime = "";
                    if(currentSearchedValue || isDataStreamSearch ||  isTopicPayloadSearch ||  isTopicSearch ||  isSubTopicSearch ||  isPayloadSearch ||  startDate){
                        if(Object.keys(searchedRows).length > 0){
                        lastRow = searchedRows[Object.keys(searchedRows).length -1];
                        lastTime = lastRow.received_at
                        lastTime = new Date(lastTime);
                        lastTime = lastTime.getTime();
                        lastTime = parseInt(lastTime);
                        }
                    }else {
                        if(Object.keys(rows).length > 0){
                        lastRow = rows[Object.keys(rows).length -1];
                        lastTime = lastRow.received_at
                        lastTime = new Date(lastTime);
                        lastTime = lastTime.getTime();
                        lastTime = parseInt(lastTime);
                        }
                    }

                    var endLink = 'search_date_to=' + lastTime;
                    if(isDataStreamSearch && currentSearchedValue){
                        if(startDate){
                        endLink = 'search_ds_uuid=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
                        } else {
                        endLink = 'search_ds_uuid=' + currentSearchedValue + '&search_date_to=' + lastTime;
                        }
                    }
                    if(isTopicPayloadSearch && currentSearchedValue){
                        if(startDate){
                        endLink = 'search_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
                        } else {
                        endLink = 'search_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
                        }
                    }
                    if(isTopicSearch && currentSearchedValue){
                        if(startDate){
                        endLink = 'search_topic_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
                        } else {
                        endLink = 'search_topic_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
                        }
                    }
                    if(isSubTopicSearch && currentSearchedValue){
                        if(startDate){
                        endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
                        } else {
                        endLink = 'search_topic_containing=' + currentSearchedValue.subtopic + '&search_ds_uuid=' + currentSearchedValue.data_stream_uuid + '&search_date_to=' + lastTime;
                        }
                    }
                    if(isPayloadSearch && currentSearchedValue){
                        if(startDate){
                        endLink = 'search_payload_containing=' + currentSearchedValue + '&search_date_from=' + startDate + '&search_date_to=' + lastTime;
                        } else {
                        endLink = 'search_payload_containing=' + currentSearchedValue + '&search_date_to=' + lastTime;
                        }
                        
                    }

                    getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
                    .then(async response => {
                        const data = await response.json();
                        if(response.ok ){
                        if(data){
                            let modifyDate = {};
                            let dataSize = {};
                            for(let i=0; i<Object.keys(data.data).length; i++){
                            // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                            modifyDate[i] = new Date(data.data[i].received_at);
                            modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                            data.data[i].received_at = modifyDate[i];
            
                            // get data size
                            dataSize[i] = Object.keys(data.data[i].data).length;
                            dataSize[i] = formatBytes(dataSize[i]);
                            data.data[i].data_stream_size = dataSize[i];
                            }
            
                            if(currentSearchedValue || isDataStreamSearch ||  isTopicPayloadSearch ||  isTopicSearch ||  isSubTopicSearch ||  isPayloadSearch ||  startDate){
                            // var searchedRows = searchedRows;
                            // searchedRows.push(...data.data);
                            setSearchedRows([...searchedRows, ...data.data])
                            setSearchMoreData(true)
                            }else {
                            // var rows = rows;
                            // rows.push(...data.data);
                            setRows([...rows, ...data.data])
                            setSearchMoreData(true)
                            }
                        }
                        }else{
                        try{
                            toast.error(data.message);
                            setSearchMoreData(true)
                            
                        }catch (e){
                            console.log('Something went wrong');
                            setSearchMoreData(true)
                        }
                        }
                    });
                }
                
                }
                }
          });

    },[])

    useEffect(() => {

        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/data_streams')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            if(data && data.data){
                setData_streams_list(data.data)
            }
        }
        });

        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data/topics')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            if(data.data){
                setTopics_list(data.data)
            }
        }
        });

        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            if(data){
            let modifyDate = {};
            let dataSize = {};
            for(let i=0; i<Object.keys(data.data).length; i++){
                // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                modifyDate[i] = new Date(data.data[i].received_at);
                modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                data.data[i].received_at = modifyDate[i];

                // get data size
                dataSize[i] = Object.keys(data.data[i].data).length;
                dataSize[i] = formatBytes(dataSize[i]);
                data.data[i].data_stream_size = dataSize[i];
            }

            // console.log("Finished pulling historical Data")
            setFetchingData(false)
            setRows(data.data)
            }
        } else{
            try{
            const data = await response.json();
            toast.error(data.message);
            setFetchingData(false)
            
            }catch (e){
            console.log('Something went wrong');
            // console.log("Failed pulling historical Data")
            setFetchingData(false)
            }
        }
        });

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/data_streams')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            var dic = {};
            if(data && data.data){
                for(var i=0; i<Object.keys(data.data).length; i++){
                dic[data.data[i].data_stream_uuid]= data.data[i]
                }
                setOrganization_data_streams_dic(dic)
            }
            }
        });

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/projects/devices/clients')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            var dic = {};
            if(data.data){
                for(var i=0; i<Object.keys(data.data).length; i++){
                dic[data.data[i].client_id]= data.data[i]
                }
                setOrganization_clients_dic(dic)
            }
            }
        });


            // if(MqttService.isConnected) {
            // MqttService.subscribe('v1/organizations/' + selectedOrganization + '/projects/' + props.selected_device.project_uuid + '/devices/' + selectedDevice.device_uuid + '/data-streams/#', onDataStreamDevice)
            // }
            // for (let i = 0; i < Object.keys(data_streams).length; i++) {
            //     rows.push(data_streams[i]);
            // }


    }, [])

    const formatBytes = (bytes, decimals = 1) => {
        if (bytes == 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
      }

      const handleChangeInputText = (e) => {
        var currentInputText = e.target.value;
        setInputText(currentInputText)
        setShowInputDropdown(true)
    
        let matchListDS = [];
          let matchListTopic = [];
          if(currentInputText){
            console.log(currentInputText)
            console.log(showInputDropdown)
            // Input text
            let lowerCaseInput = currentInputText.toLowerCase();
    
            // Looking for matches in Data stream names        
            data_streams_list.map((dataStream,index) => {
              let lowerCaseName = dataStream.data_stream_name.toLowerCase();          
              if(lowerCaseName.includes(lowerCaseInput)){
                matchListDS.push(dataStream)            
              }
            })
            setDataStreamMatchList(matchListDS)
    
            // Looking for matches in Topic names
            topics_list.map((topic,index) => {
              topic.subtopics.map((subTopic,index) => {
                let lowerCaseName = subTopic.toLowerCase();            
                if(lowerCaseName.includes(lowerCaseInput)){              
                  matchListTopic.push({
                    data_stream_name: topic.data_stream_name,
                    data_stream_uuid: topic.data_stream_uuid,
                    subtopic: subTopic
                  })              
                }
              })
            })
            setTopicMatchList(matchListTopic)
    
          }else {
            setDataStreamMatchList([])
            setTopicMatchList([])
          }
      }

      const handleDataStreamSearch = (dataStream) => {
        if(!loading){
          var endLink = "";
          if(startDate && endDate){
            endLink = 'search_ds_uuid=' + dataStream.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
          } else {
            endLink = 'search_ds_uuid=' + dataStream.data_stream_uuid;
          }

          setLoading(true)
          setFilterItems( [...filterItems, dataStream.data_stream_name + " Data Stream"])
          setInputText('')
          setShowInputDropdown(false)
          setIsSearched(true)
          setIsDataStreamSearch(true)

          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data){
                    let modifyDate = {};
                    let dataSize = {};
                    for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                    }

                    setLoading(false)
                    setSearchedRows(data.data)
                }
                } else{
                try{
                    const data = await response.json();
                    toast.error(data.message);
                    setLoading(false)
                    
                }catch (e){
                    toast.error('Something went wrong');
                    setLoading(false)
                }
                }
            });

        }
      }

      const handleTopicSearch = (inputValue) => {
        
        if(!loading){
          var endLink = "";
          if(startDate && endDate){
            endLink = 'search_topic_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
          } else {
            endLink = 'search_topic_containing=' + inputValue;
          }
        //   setCurrentSearchedValue(inputValue)
        //   setIsDataStreamSearch(false)
        //   setIsTopicPayloadSearch(false)
        //   setIsSubTopicSearch(false)
          
          setLoading(true)
          setFilterItems([...filterItems, inputValue + " in Topic"])
          setInputText('')
          setShowInputDropdown(false)
          setIsSearched(true)
          setIsTopicSearch(true)
          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    if(data){
                        let modifyDate = {};
                        let dataSize = {};
                        for(let i=0; i<Object.keys(data.data).length; i++){
                        // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                        modifyDate[i] = new Date(data.data[i].received_at);
                        modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                        data.data[i].received_at = modifyDate[i];

                        // get data size
                        dataSize[i] = Object.keys(data.data[i].data).length;
                        dataSize[i] = formatBytes(dataSize[i]);
                        data.data[i].data_stream_size = dataSize[i];
                        }

                        setLoading(false)
                        setSearchedRows(data.data)
                    }
                } else{
                    try{
                        toast.error(data.message);
                        setLoading(false)
                        
                    }catch (e){
                        toast.error('Something went wrong');
                        setLoading(false)
                    }
                }
            });

        //   this.setState({
        //     filterItems: this.state.filterItems.filter(i => i !== filterItems[0]),
        //     loading: true,
        //     currentSearchedValue: inputValue,
        //     isDataStreamSearch: false,
        //     isTopicPayloadSearch:false,
        //     isTopicSearch: false,
        //     isSubTopicSearch: false,
        //     isPayloadSearch: false,
        //   }, () => {
        //         this.setState({
        //           filterItems: [...this.state.filterItems, inputValue + " in Topic"],
        //           inputText: '',
        //           showInputDropdown: false,
        //           isSearched: true,
        //           isTopicSearch: true,
        //         })
                
        //   })
        }
      }
      const handleSubTopicSearch = (inputValue) => {
        if(!loading){
          var endLink = "";
          if(startDate && endDate){
            endLink = 'search_topic_containing=' + inputValue.subtopic + '&search_ds_uuid=' + inputValue.data_stream_uuid + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
          } else {
            endLink = 'search_topic_containing=' + inputValue.subtopic + '&search_ds_uuid=' + inputValue.data_stream_uuid;
          }

          setLoading(true)
          setFilterItems([...filterItems, inputValue.subtopic + " Subtopic of Data Stream " + inputValue.data_stream_name])
          setInputText('')
          setShowInputDropdown(false)
          setIsSearched(true)
          setIsTopicSearch(true)

          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data){
                    let modifyDate = {};
                    let dataSize = {};
                    for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                    }


                    setLoading(false)
                    setSearchedRows(data.data)
                }
                } else{
                try{
                    const data = await response.json();
                    toast.error(data.message);
                    setLoading(false)
                    
                }catch (e){
                    toast.error('Something went wrong');
                    setLoading(false)
                }
                }
            });
        }
      }
    
      const handlePayloadSearch = (inputValue) => {
        if(!loading){
          var endLink = "";
          if(startDate && endDate){
            endLink = 'search_payload_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
          } else {
            endLink = 'search_payload_containing=' + inputValue;
          }

          setLoading(true)
          setFilterItems([...filterItems, inputValue + " in Payload"])
          setInputText('')
          setShowInputDropdown(false)
          setIsSearched(true)
          setIsPayloadSearch(true)


          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data){
                    let modifyDate = {};
                    let dataSize = {};
                    for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];

                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                    }


                    setLoading(false)
                    setSearchedRows(data.data)
                }
                } else{
                try{
                    const data = await response.json();
                    toast.error(data.message);
                    setLoading(false)
                    
                }catch (e){
                    toast.error('Something went wrong');
                    setLoading(false)
                }
                }
            });
            
        }
      }
    
      const handleTopicPayloadSearch = (inputValue) => {
        if(!loading){
          var endLink = "";
          if(startDate && endDate){
            endLink = 'search_containing=' + inputValue + '&search_date_from=' + startDate + '&search_date_to=' + endDate;
          } else {
            endLink = 'search_containing=' + inputValue;
          }

          setLoading(true)
          setFilterItems([...filterItems, inputValue + " in Topic/Payload"])
          setInputText('')
          setShowInputDropdown(false)
          setIsSearched(true)
          setIsTopicPayloadSearch(true)

          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    if(data){
                      let modifyDate = {};
                      let dataSize = {};
                      for(let i=0; i<Object.keys(data.data).length; i++){
                        // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                        modifyDate[i] = new Date(data.data[i].received_at);
                        modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                        data.data[i].received_at = modifyDate[i];
    
                        // get data size
                        dataSize[i] = Object.keys(data.data[i].data).length;
                        dataSize[i] = formatBytes(dataSize[i]);
                        data.data[i].data_stream_size = dataSize[i];
                      }
    
    
                      setLoading(false)
                        setSearchedRows(data.data)
                    }
                  } else{
                    try{
                      const data = await response.json();
                      toast.error(data.message);
                      setLoading(false)
                      
                    }catch (e){
                      toast.error('Something went wrong');
                      setLoading(false)
                    }
                  }
                });

        }
      }

      const handleDelete = (item) => {
        setFilterItems(filterItems.filter(i => i !== item))
        setCurrentSearchedValue('')
        setIsDataStreamSearch(false)
        setIsTopicPayloadSearch(false)
        setIsTopicSearch(false)
        setIsSubTopicSearch(false)
        setIsPayloadSearch(false)


        if(startDate && endDate && !loading){
            setLoading(true)
          var endLink = 'search_date_from=' + startDate + '&search_date_to=' + endDate;
          getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/historical_data?' + endLink)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  let modifyDate = {};
                  let dataSize = {};
                  for(let i=0; i<Object.keys(data.data).length; i++){
                    // modifyDate[i]= (new Date(data.data[i].received_at)).toLocaleTimeString();
                    modifyDate[i] = new Date(data.data[i].received_at);
                    modifyDate[i] = modifyDate[i].toLocaleTimeString()+' '+modifyDate[i].toLocaleDateString();
                    data.data[i].received_at = modifyDate[i];
    
                    // get data size
                    dataSize[i] = Object.keys(data.data[i].data).length;
                    dataSize[i] = formatBytes(dataSize[i]);
                    data.data[i].data_stream_size = dataSize[i];
                  }
    
                  setSearchedRows(data.data)
                  setLoading(false)
                }
              } else{
                    try{
                    const data = await response.json();
                    toast.error(data.message);
                    setLoading(false)
                    
                    }catch (e){
                    toast.error('Something went wrong');
                    setLoading(false)
                    }
              }
            });
          
        }else {
            setIsSearched(false)
        }
      };


  return (
    <div>
        {!fetchingData && Object.keys(rows).length === 0 &&
            <div className='w-full flex border-[0.01em] items-center border-amber-500 rounded-lg py-2 dark:opacity-70 mb-2'>
                <div className='px-2'>
                    <svg style={{width: '30px',padding: '0 0px 0 0'}} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <path d="M4.87 18.23h12.26a1.87 1.87 0 0 0 1.62-2.8L12.62 4.71a1.86 1.86 0 0 0-3.24 0L3.25 15.43a1.87 1.87 0 0 0 1.62 2.8zM11 12.64V7.73m0 7.48v-.16" fill="none" stroke="#df7800" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path>
                    </svg>
                </div>
                <div >
                    <span className='text-amber-700 dark:text-amber-500  font-semibold'>We don't have any data! </span>
                </div>
            </div>
        }
        <div>
            <div className='flex '>
                {/* Search Input field */}
                <div className='flex sm:col-span-2 xl:col-span-1 grow '>
                    <div className='relative rounded-full mx-1 w-full'>
                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                    <svg className={`h-4 w-4  ${isDark? 'text-gray-300' : 'text-gray-500'}`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    </div>
                    <input className={` ${isDark? 'hover:bg-gray-700 focus:bg-gray-700 bg-gray-600 focus:ring-gray-500 text-gray-300 placeholder:text-gray-400' : 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300 focus:border-gray-300 placeholder:text-gray-500'}  
                            block w-full pl-10 sm:text-xs rounded-full text-ellipsis py-1 `}
                        value={inputText} 
                        onChange={handleChangeInputText} 
                        type="text" 
                        placeholder="Search..."
                        
                    />
                    {inputText && showInputDropdown &&
                        <div className={`absolute w-full top-8 min-w-[400px] z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide bg-slate-100 dark:bg-slate-700`}>
                            <div className='w-full flex p-2 pl-3 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-900' onClick={() => handleTopicSearch(inputText)} >
                                <div>
                                    <span className='text-blue-500 dark:text-blue-300 font-bold '>{inputText}</span> &nbsp;<span className='text-sm text-gray-500 dark:text-gray-400 italic font-medium'>in Topic</span>
                                </div>
                                </div>
                                <div className='w-full flex p-2 pl-3 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-900' onClick={() => handlePayloadSearch(inputText)} >
                                <div>
                                    <span className='text-blue-500 dark:text-blue-300 font-bold '>{inputText}</span> &nbsp;<span className='text-sm text-gray-500 dark:text-gray-400 italic font-medium'>in Payload</span>
                                </div>
                                </div>
                                <div className='w-full flex p-2 pl-3 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-900' onClick={() => handleTopicPayloadSearch(inputText)} >
                                <div>
                                    <span className='text-blue-500 dark:text-blue-300 font-bold '>{inputText}</span> &nbsp;<span className='text-sm text-gray-500 dark:text-gray-400 italic font-medium'>in Topic/Payload</span>
                                </div>
                                </div>
                                {dataStreamMatchList.map(dataStreamMatch => (
                                <div key={dataStreamMatch.data_stream_uuid} onClick={() => handleDataStreamSearch(dataStreamMatch)} className='w-full flex p-2 pl-3 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-900'>
                                    <div>
                                    <span className='text-blue-500 dark:text-blue-300 font-bold '>{dataStreamMatch.data_stream_name}</span> &nbsp;<span className='text-sm text-gray-500 dark:text-gray-400 italic font-medium'>Data Stream</span>
                                    </div>
                                </div>
                                ))}
                                {topicMatchList.map((topicMatch, index) => (
                                <div key={topicMatch.data_stream_uuid+topicMatch.subtopic} onClick={() => handleSubTopicSearch(topicMatch)} className='w-full flex p-2 pl-3 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-900'>
                                    <div>
                                    <span className='text-blue-500 dark:text-blue-300 font-bold '>{topicMatch.subtopic}</span> &nbsp;<span className='text-sm text-gray-500 dark:text-gray-400 italic font-medium'> Subtopic of Data Stream {topicMatch.data_stream_name}</span>
                                    </div>
                                </div>
                                ))}
                        </div>
                    }
                    </div>
                </div>

            </div>
            {Object.keys(filterItems).length > 0 &&
                <div className='w-full mt-1'>
                  {filterItems.map(item => (
                      <div className="bg-blue-500 text-white font-bold flex rounded-full h-8 px-1 pt-2 pb-0.5 items-center m-0.5 w-fit" key={item}>
                        {item}
                        <button
                          className="bg-white text-blue-500 w-5 h-5 rounded-full border-0 ml-2 font-bold p-0 flex items-center justify-center"
                          onClick={() => handleDelete(item)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
                }

        </div>
        {loading &&
            <div className='w-full '>
                <LinearProgress />
            </div>
        }
        <DataStreamTable rows={rows} searchedRows={searchedRows} isSearched={isSearched} searchMoreData={searchMoreData} fetchingData={fetchingData}  />
    </div>
  )
}

export default DeviceDataStreamsPage