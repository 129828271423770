export const auth = {            
    identityPoolId: 'us-east-1:5684b65a-8bf4-4697-b9a4-87eadbf03ef5',
    region: 'us-east-1',
    identityPoolRegion: 'us-east-1',      
    userPoolId: 'us-east-1_3EP1MRzAy',  
    userPoolWebClientId: '18230ih88kfb3ouj67mrid5er3',
    oauth: {
        domain: 'auth.iotflows.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        // redirectSignIn: 'https://www.console.iotflows.com/', 
        // redirectSignOut: 'https://www.console.iotflows.com/', 
        redirectSignIn: 'http://localhost:3000/', 
        redirectSignOut: 'http://localhost:3000/', 
        responseType: 'token', // DO NOT USE 'code'!!! it goes through a forever loop //or 'token', note that REFRESH token will only be generated when the responseType is code
        // responseType: 'code', 
        options: {
            // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
            AdvancedSecurityDataCollectionFlag : true
        },
    }
}