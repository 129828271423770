import React, { useEffect, useState } from 'react'
import MqttService from '../core/services/MqttService';
import { Switch } from '@mui/material';
import { userInfo } from '../atoms/userStates';
import { useRecoilValue } from 'recoil';
import { getData, putData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import FileManagementTable from '../MyInstancesPage/FileManagementTable';
import toast from 'react-hot-toast';
import SshConnectedInfoModal from '../components/modals/deviceModals/SshConnectedInfoModal';
import FileManagerCredentialsModal from '../components/modals/deviceModals/FileManagerCredentialsModal';
import DeleteRemoteConnectionModal from '../components/modals/deviceModals/DeleteRemoteConnectionModal';
import EditRemoteConnectionModal from '../components/modals/deviceModals/EditRemoteConnectionModal';
import EditDeviceCredentialsModal from '../components/modals/deviceModals/EditDeviceCredentialsModal';
import AddRemoteConnectionModal from '../components/modals/deviceModals/AddRemoteConnectionModal';
import ReconfigureRemoteAgentModal from '../components/modals/deviceModals/ReconfigureRemoteAgentModal';

function RemoteConnectionsPage({selectedDevice}) {
    const [nodeRedConnectionInfo, setNodeRedConnectionInfo] = useState([])
    const [hasNodeRedConnection, setHasNodeRedConnection] = useState(false)
    const [sshConnectionInfo, setSshConnectionInfo] = useState([])
    const [hasSshConnection, setHasSshConnection] = useState(false)
    const [server_url_ssh, setServer_url_ssh] = useState('')
    const [customConnections, setCustomConnections] = useState([])
    const [connectedServer, setConnectedServer] = useState(false)
    const [connectedDevice, setConnectedDevice] = useState(false)
    const [connectionFailed, setConnectionFailed] = useState(false)
    const [connectionEstablished, setConnectionEstablished] = useState(false)
    const [loadingServer, setLoadingServer] = useState(false)
    const [loadingDevice, setLoadingDevice] = useState(false)
    const [successServer, setSuccessServer] = useState(false)
    const [successDevice, setSuccessDevice] = useState(false)
    const [openSshConnectedInfoModal, setOpenSshConnectedInfoModal] = useState(false)
    const [openConfigurationModal, setOpenConfigurationModal] = useState(false)
    const [openFileManagerCredentialsModal, setOpenFileManagerCredentialsModal] = useState(false)
    const user = useRecoilValue(userInfo)

    useEffect(() => {
        if(selectedDevice && Object.keys(selectedDevice).length > 0){
            refreshRemoteConnectionList()

        }

    }, [selectedDevice])

    useEffect(() => {
        if(user && Object.keys(user).length > 0){
            setTimeout(() => {
                //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                if(MqttService.isConnected) {
                    // console.log('MqttService connected')
                    MqttService.subscribe('v1/api/users/' + user.user_username + '/notifications', onReceiveNotifications)
                }
              }, 3000)

        }

    }, [user])

    const refreshRemoteConnectionList = () => {
        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/connections')
        .then(async response => {
            const data = await response.json();
          if(response.ok ){
            if(data && data.data){
                var otherConnections = [];
                  data.data.map((connection, i) => {
                    if(connection.device_connection_type_name == 'NODERED'){
                        setHasNodeRedConnection(true)
                        setNodeRedConnectionInfo(connection)
                    }else if(connection.device_connection_type_name == 'SSH'){
                        setHasSshConnection(true)
                        setSshConnectionInfo(connection)
                        setServer_url_ssh(connection.device_connection_management_server_url)
                    } else {
                      otherConnections.unshift(connection);
                        
                    }
                  })
                  setCustomConnections(otherConnections)

            }
            
          }
        });
      }

    const onReceiveNotifications = (message) => {

        try{
          const notification_message = JSON.parse(message.payloadString);
        
          if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device Connection Management Server' && notification_message.code == 200)
          {
            setConnectedServer(true)
            // handleConnectedDevice()
            toast.success("Server Configured Successfully");
          }
          else if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device Connection Management Server' && notification_message.code == 500)
          {
            setConnectionFailed(true)
            toast.success("Server Configuration Error..")
          }
          else if(notification_message.service == 'remote-tunnel' && notification_message.source == 'Device' && notification_message.code == 200)
          {
            setConnectedDevice(true)
            // handleConnectedDevice()
            toast.success("Device is configured properly")
          } 
        }
        catch(e){
          console.log("Error in reading On receiving notification from MQTT");
          console.log(e.message);
        }
    }

    useEffect(() => {
        if(connectedServer && connectedDevice){
            setConnectionEstablished(true)
            setLoadingServer(false)
            setLoadingDevice(false)
            setSuccessServer(true)
            setSuccessDevice(true)
            handleCloseConfigurationModal()
        }
    }, [connectedServer, connectedDevice])

    // const handleConnectedDevice = () =>{
    //     if(connectedServer && connectedDevice){
    //         setConnectionEstablished(true)
    //         setLoadingServer(false)
    //         setLoadingDevice(false)
    //         setSuccessServer(true)
    //         setSuccessDevice(true)
    //         handleCloseConfigurationModal()
    //     }
    //   }

    const toggleChecked = (connectionInfo) => {
        
        if(connectionInfo.device_connection_is_enabled){
            putData(apiLink + '/v1/devices/'+ selectedDevice.device_uuid + '/connections', 
            {'from_port': connectionInfo.device_connection_from_port,
            'from_ip':connectionInfo.device_connection_from_ip,
            'enable_connection': false})
            .then(async response => {
              if(response.ok){ 
                // const data = await response.json();
                refreshRemoteConnectionList()
                toast.success("Connection has been disconnected!");
              }
              else{
                refreshRemoteConnectionList()
                toast.error("Connection failed to disconnected");
  
              }
            })
        }else if(connectionInfo.device_connection_is_enabled === false){
            putData(apiLink + '/v1/devices/'+ selectedDevice.device_uuid + '/connections', 
            {'from_port': connectionInfo.device_connection_from_port,
              'from_ip':connectionInfo.device_connection_from_ip,
              'enable_connection': true})
              .then(async response => {
                if(response.ok){
                  // const data = await response.json();
                  refreshRemoteConnectionList();
                  toast.success("Connection has been requested successfully!");
                  setTimeout(() => {
                    if(connectionEstablished){
                    }else{
                      setConnectionFailed(true)
                    }
                  }, 5000);
                }
                else{
                    toast.error("Connection request failed");
                }
              })
        } 
      }

      const handleCloseConfigurationModal = () => {
        setOpenConfigurationModal(false)
        setConnectionEstablished(true)
        setLoadingServer(false)
        setLoadingDevice(false)
        setConnectionFailed(false)
        setConnectedServer(false)
      }

      const handlerOpenSshModal = () => {
        setOpenSshConnectedInfoModal(true)
      }

      const handlerCloseSshInfoModal = () => {
        setOpenSshConnectedInfoModal(false)
      }

      const handlerFileManagerCredentialsModal = () => {
        setOpenFileManagerCredentialsModal(true)
      }
      const handlerCloseFileManagerCredentialsModal = () => {
        setOpenFileManagerCredentialsModal(false)
      }

  return (
    <div>
        <div className='flex w-full items-center'>
                <div className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' >
                        Device Remote Connections
                </div>
            <div className='ml-auto'>
                <div className='flex justify-end items-center py-3'>
                    <div className='flex items-center '>
                        <div className='mx-4'>
                            <ReconfigureRemoteAgentModal btnType='standardBtn' selectedDevice={selectedDevice} />
                        </div>
                        <div className='flex items-center'>
                            <AddRemoteConnectionModal btnType='standardBtn' selectedDevice={selectedDevice} refreshRemoteConnectionList={refreshRemoteConnectionList} />
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <table className="table-auto w-full">
            {/* <colgroup >
                <col className='w-full'/>
                <col className='w-[200px]'/>
                
            </colgroup> */}
        <thead >
            {/* <tr className='' >
                <th className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' colSpan={6}>
                        Device Remote Connections
                </th>
                <th className='flex justify-end items-center py-3' colSpan={2}>
                    <div className='flex items-center '>
                        <div className='mx-2'>
                            <ReconfigureRemoteAgentModal btnType='standardBtn' selectedDevice={selectedDevice} />
                        </div>
                        <div className='flex items-center'>
                            <AddRemoteConnectionModal btnType='standardBtn' selectedDevice={selectedDevice} refreshRemoteConnectionList={refreshRemoteConnectionList} />
                        </div>

                    </div>
                </th>
            </tr> */}
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Name`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`IP`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Port`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Slug`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Enable`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-center w-[180px]'>{``}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-center w-[200px]'>{``}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right w-[150px]'>{``}</th>
            </tr>
        </thead>
          <tbody className=''>
            {hasSshConnection &&
              <tr className='border-b-[0.01em] dark:border-slate-500'>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 pl-2 py-3 flex ' >
                        SSH
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {sshConnectionInfo.device_connection_from_ip}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {sshConnectionInfo.device_connection_from_port}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {sshConnectionInfo.device_connection_slug}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right' >
                        <Switch 
                            size="small"
                            value="activateSSH"
                            checked={sshConnectionInfo.device_connection_is_enabled}
                            // onChange={(e) => setIsDark(e.target.checked)}
                            onChange={()=> toggleChecked(sshConnectionInfo)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        <div>
                            <button className={`px-3 py-0.5 border-[0.01em] border-blue-500 dark:border-blue-300 text-blue-500 dark:text-blue-300 rounded-full disabled:opacity-50`} disabled={!sshConnectionInfo.device_connection_is_enabled || !selectedDevice.device_is_enabled} onClick={handlerOpenSshModal}  >
                            Connect        
                            </button>

                        </div>
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        <div>
                            <button className={`px-3 py-0.5 border-[0.01em] border-blue-500 dark:border-blue-300 text-blue-500 dark:text-blue-300 rounded-full disabled:opacity-50`} disabled={!sshConnectionInfo.device_connection_is_enabled || !selectedDevice.device_is_enabled} onClick={handlerFileManagerCredentialsModal}  >
                            Secure File Transfer     
                            </button>
                        </div>
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
                        <div className='flex items-center ml-auto justify-end'>
                            {/* <div className='w-10'>
                                <EditDeviceModal device={el} refreshDeviceList={refreshDeviceList} btnType='inlineIconBtn' />
                            </div> */}
                            <div className='w-10'>
                                <DeleteRemoteConnectionModal btnType='inlineIconBtn' remoteConnectionName={sshConnectionInfo.device_connection_name} selectedDevice={selectedDevice} portNumber={sshConnectionInfo.device_connection_from_port} from_ip={sshConnectionInfo.device_connection_from_ip} refreshRemoteConnectionList={refreshRemoteConnectionList}  />
                            </div>

                        </div>
                    </td>
                </tr>
            }
            {customConnections.map((connection, index) => (
                <tr key={`${connection.device_connection_slug}-${connection.device_connection_from_port}`} className='border-b-[0.01em] dark:border-slate-500' >
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 pl-2 py-3 flex' >
                        {connection.device_connection_name}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {connection.device_connection_from_ip}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {connection.device_connection_from_port}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {connection.device_connection_slug}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right' >
                        <Switch 
                            size="small"
                            value="activateConnection"
                            checked={connection.device_connection_is_enabled}
                            // onChange={(e) => setIsDark(e.target.checked)}
                            onChange={()=> toggleChecked(connection)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        <div>
                            <button 
                                className={`px-3 py-0.5 border-[0.01em] border-blue-500 dark:border-blue-300 text-blue-500 dark:text-blue-300 rounded-full disabled:opacity-50`} 
                                disabled={!connection.device_connection_is_enabled || !selectedDevice.device_is_enabled} 
                                onClick={() => window.open('https://'+ connection.device_connection_slug + '-' + connection.device_connection_from_port + '-'  + selectedDevice.device_uuid + '.' + connection.device_connection_management_server_url  , "_blank")}
                            >
                            Connect        
                            </button>

                        </div>
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        <div>
                            {/* Empty */}
                        </div>
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
                        <div className='flex items-center ml-auto justify-end'>
                            <div className='w-10'>
                                <EditRemoteConnectionModal btnType='inlineIconBtn' refreshRemoteConnectionList={refreshRemoteConnectionList} connectionInfo={connection}  />
                            </div>
                            <div className='w-10'>
                                <DeleteRemoteConnectionModal btnType='inlineIconBtn' remoteConnectionName={connection.device_connection_name} selectedDevice={selectedDevice} portNumber={connection.device_connection_from_port} from_ip={connection.device_connection_from_ip} refreshRemoteConnectionList={refreshRemoteConnectionList}  />
                            </div>

                        </div>
                    </td>
                </tr>
            ))}
            {hasNodeRedConnection &&
                <tr className='border-b-[0.01em] dark:border-slate-500' >
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 pl-2 py-3 flex ' >
                        Node-RED
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {nodeRedConnectionInfo.device_connection_from_ip}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {nodeRedConnectionInfo.device_connection_from_port}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' >
                        {nodeRedConnectionInfo.device_connection_slug}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right' >
                        <Switch 
                            size="small"
                            value="activateNodeRed"
                            checked={nodeRedConnectionInfo.device_connection_is_enabled}
                            // onChange={(e) => setIsDark(e.target.checked)}
                            onChange={()=> toggleChecked(nodeRedConnectionInfo)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        <div>
                            <button 
                                className={`px-3 py-0.5 border-[0.01em] border-blue-500 dark:border-blue-300 text-blue-500 dark:text-blue-300 rounded-full disabled:opacity-50`} 
                                disabled={!nodeRedConnectionInfo.device_connection_is_enabled || !selectedDevice.device_is_enabled} 
                                onClick={() => window.open('https://' + nodeRedConnectionInfo.device_connection_slug + '-' + nodeRedConnectionInfo.device_connection_from_port + '-' + selectedDevice.device_uuid + '.' + nodeRedConnectionInfo.device_connection_management_server_url + '/nodered/' , "_blank")}
                            >
                            Connect        
                            </button>

                        </div>
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center justify-center' >
                        {/* Empty */}
                    </td>
                    <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
                        <div className='flex items-center ml-auto justify-end'>
                            <div className='w-10'>
                                <EditDeviceCredentialsModal btnType='inlineIconBtn' selectedDevice={selectedDevice} />
                            </div>
                            <div className='w-10'>
                                <EditRemoteConnectionModal btnType='inlineIconBtn' refreshRemoteConnectionList={refreshRemoteConnectionList} connectionInfo={nodeRedConnectionInfo}  />
                            </div>
                            <div className='w-10'>
                                <DeleteRemoteConnectionModal btnType='inlineIconBtn' remoteConnectionName={nodeRedConnectionInfo.device_connection_name} selectedDevice={selectedDevice} portNumber={nodeRedConnectionInfo.device_connection_from_port} from_ip={nodeRedConnectionInfo.device_connection_from_ip} refreshRemoteConnectionList={refreshRemoteConnectionList}  />
                            </div>

                        </div>
                    </td>
                </tr>
            }
        </tbody>
      </table>

      {selectedDevice && selectedDevice.device_uuid && sshConnectionInfo && sshConnectionInfo.device_connection_is_enabled && localStorage.getItem('ssh_username') && localStorage.getItem('ssh_password') &&
        <div className='p-2 border-[0.01em] dark:border-slate-700 rounded-xl mt-4'>
            <FileManagementTable currentPath={'/home/'+localStorage.getItem('ssh_username')} instrumentType_uuid={`devices/${selectedDevice.device_uuid}`} server_url_ssh={server_url_ssh} />

        </div>
        }

        {openSshConnectedInfoModal &&
            <SshConnectedInfoModal 
            btnType={'hideBtn'}
            open={openSshConnectedInfoModal} 
            handlerCloseSshInfoModal={handlerCloseSshInfoModal} 
            server_url_ssh={server_url_ssh} 
            selectedDevice={selectedDevice} 
            />
        }
        {openFileManagerCredentialsModal &&
            <FileManagerCredentialsModal 
            btnType={'hideBtn'}
            open={openFileManagerCredentialsModal}
            handlerCloseFileManagerCredentialsModal={handlerCloseFileManagerCredentialsModal}
            selectedDevice={selectedDevice} 
            />
        }

    
    </div>
  )
}

export default RemoteConnectionsPage