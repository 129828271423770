import React from 'react'
import DeleteDeviceModal from '../components/modals/deviceModals/DeleteDeviceModal';
import EditDeviceModal from '../components/modals/deviceModals/EditDeviceModal';
import { useNavigate } from 'react-router-dom';

function DevicesTableRow({el, refreshDeviceList, selectedOrganization}) {
    const navigate = useNavigate()
    

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            // return shortcurrentDate  ;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }

  return (
    <tr className='border-b-[0.01em] dark:border-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600'>
        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 pl-2 py-3 flex w-[20px]' onClick={() => navigate(`/${selectedOrganization}/devices/${el.device_uuid}`)}>
            {el.device_is_online ? (
                <div className="relative">
                    <div className="w-4 h-4 bg-transparent"></div>
                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                </div>
            ):(
                <div className="relative">
                    <div className="w-4 h-4 bg-transparent"></div>
                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                </div>
            )}
        </td>
        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' onClick={() => navigate(`/${selectedOrganization}/devices/${el.device_uuid}`)}>
                {el.device_name}
        </td>
        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' onClick={() => navigate(`/${selectedOrganization}/devices/${el.device_uuid}`)}>
                {el.device_description}
        </td>
        <td className=' font-light text-gray-600 dark:text-gray-300 px-1 py-3 ' onClick={() => navigate(`/${selectedOrganization}/devices/${el.device_uuid}`)}>
            {el.device_is_virtual ? 
                <div  className='cursor-pointer text-sm rounded h-5 py-0.5 px-1.5 text-center w-[85px] bg-[#cbf4c9] ' >
                <span className='text-xs' style={{color:'rgb(14, 98, 69)'}} >
                    {el.subscription_plan_identifier == "vr-dev-basic" ? "V-Basic": null}
                    {el.subscription_plan_identifier == "vr-dev-standard" ? "V-Std": null}
                    {el.subscription_plan_identifier == "vr-dev-pro" ? "V-Pro": null}
                    {el.subscription_plan_identifier == "ent-vr-dev-standard" ? "Ent-V-Std": null}
                    {el.subscription_plan_identifier == "ent-vr-dev-pro" ? "Ent-V-Pro": null}
                </span>
                </div>
            : <div className='cursor-pointer text-sm rounded h-5 py-0.5 px-1.5 text-center w-[85px] bg-[#c9dcf4] ' >
                <span className='text-xs' style={{color:'rgb(14, 50, 97)'}} >
                    {el.subscription_plan_identifier == "dev-basic" ? "Dev-Basic": null}
                    {el.subscription_plan_identifier == "dev-standard" ? "Dev-Std": null}
                    {el.subscription_plan_identifier == "dev-pro" ? "Dev-Pro": null}
                    {el.subscription_plan_identifier == "ent-dev-standard" ? "Ent-Dev-Std": null}
                    {el.subscription_plan_identifier == "ent-dev-pro" ? "Ent-Dev-Pro": null}
                    {el.subscription_plan_identifier == "IOT_GATEWAY_E2" ? "Physical": null}
                    {el.subscription_plan_identifier == "IOT_GATEWAY_FREE" ? "Virtual": null}
                    {el.subscription_plan_identifier == "IOT_GATEWAY_PAY_AS_YOU_GO" ? "Virtual": null}
                </span>
                </div>
                }
        </td>
        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right' onClick={() => navigate(`/${selectedOrganization}/devices/${el.device_uuid}`)}>
            {handleDateFormat(el.device_last_heard)}
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            <div className='flex items-center ml-auto justify-end'>
                <div className='w-10'>
                    <EditDeviceModal device={el} refreshDeviceList={refreshDeviceList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <DeleteDeviceModal device={el} refreshDeviceList={refreshDeviceList} btnType='inlineIconBtn' />
                </div>

            </div>
        </td>
    </tr>
  )
}

export default DevicesTableRow