
const PaymentImagesJSON = {
  amex: { id: 1, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/amex.svg`, title: 'amex', description: 'American Express' },
  cartes_bancaires: { id: 2, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/cartes_bancaires.svg`, title: 'cartes_bancaires', description: 'Cartes Bancaires' },
  diners_club: { id: 3, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/diners_club.svg`, title: 'diners_club', description: 'Diners Club' },
  discover: { id: 4, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/discover.svg`, title: 'discover', description: 'Discover' },
  jcb: { id: 5, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/jcb.svg`, title: 'jcb', description: 'Japan Credit Bureau' },
  mastercard: { id: 6, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/mastercard.svg`, title: 'mastercard', description: 'Master Card' },
  unionpay: { id: 7, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/unionpay.svg`, title: 'unionpay', description: 'Union Pay' },
  visa: { id: 8, src: `${process.env.PUBLIC_URL}/images/dark_card_brand/visa.svg`, title: 'visa', description: 'Visa' },
};

export default PaymentImagesJSON;

