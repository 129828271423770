import { useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'
import {globalSelectedOrganization} from '../atoms/orgStates'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { LightTooltip } from "../styles/Tooltip";



function LeftSideNav() {
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    let location = useLocation();
    const [selectedTab, setSelectedTab] = useState('');
    const [searchParams, setSearchParams] = useSearchParams()

    const navigationLinks = [
        {
            title: 'Projects',
            url:`/${selectedOrganization}/projects`,
            icon:
            <svg className="w-7 h-7 " fill='currentColor' viewBox="0 0 24 24" ><path d="M17 11h3c1.11 0 2-.9 2-2V5c0-1.11-.9-2-2-2h-3c-1.11 0-2 .9-2 2v1H9.01V5c0-1.11-.9-2-2-2H4c-1.1 0-2 .9-2 2v4c0 1.11.9 2 2 2h3c1.11 0 2-.9 2-2V8h2v7.01c0 1.65 1.34 2.99 2.99 2.99H15v1c0 1.11.9 2 2 2h3c1.11 0 2-.9 2-2v-4c0-1.11-.9-2-2-2h-3c-1.11 0-2 .9-2 2v1h-1.01c-.54 0-.99-.45-.99-.99V8h2v1c0 1.1.9 2 2 2z"></path>
            </svg>
          
        },
        {
            title: 'Servers',
            url:`/${selectedOrganization}/instances`,
            icon: <svg className="w-7 h-7 " fill='currentColor' viewBox="0 0 24 24">
                <path d="M4 20h16c1.1 0 2-.9 2-2s-.9-2-2-2H4c-1.1 0-2 .9-2 2s.9 2 2 2zm0-3h2v2H4v-2zM2 6c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2s-.9-2-2-2H4c-1.1 0-2 .9-2 2zm4 1H4V5h2v2zm-2 7h16c1.1 0 2-.9 2-2s-.9-2-2-2H4c-1.1 0-2 .9-2 2s.9 2 2 2zm0-3h2v2H4v-2z"></path>
            </svg>
          
        },
        {
            title: 'APIs',
            url:`/${selectedOrganization}/apikeys`,
            icon: <svg className="w-7 h-7 " fill='currentColor'  aria-hidden="true" viewBox="0 0 24 24" ><path d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
            </svg>
          
        },
        {
            title: 'Logs',
            url:`/${selectedOrganization}/logs`,
            icon:<svg className="w-7 h-7 " fill='currentColor'  viewBox="0 0 24 24" >
                <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z"></path>
            </svg>
          
        },
    ]


    useEffect(()=>{
        if(selectedOrganization){
            let currentPath = location.pathname.split('/')[2]
            if(currentPath && currentPath.split('?') && currentPath.split('?')[0]){
                currentPath = currentPath.split('?')[0]
            }
            for (let i = 0; i < Object.keys(navigationLinks).length; i++) {
                let navLink = navigationLinks[i].url.split('/')[2]
                if(navLink && navLink.split('?') && navLink.split('?')[0]){
                    navLink = navLink.split('?')[0]
                }
                if(currentPath === navLink){
                    setSelectedTab(navigationLinks[i])
                    break;
                 }else {
                     setSelectedTab(
                         {
                             title: currentPath,
                             url:`/${selectedOrganization}/${currentPath}`
                         },
                     )
                 }
              }
        }
    },[location, selectedOrganization])

    

    let nonActiveStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
    }

    let activeStyle = {
        backgroundColor:'transparent',
        borderRadius:'50px',
        color: isDark ? '#F3F4F6':'#4b5563',
        // backgroundColor:'#2563eb',
        // color:'#fff',
        // borderRadius:'50px',
        // color:'#ffff',
      };
      
    return (
        <nav className={` relative flex flex-col bg-slate-100  border-r-[0.01em] dark:border-slate-700 dark:bg-slate-900 bg-white  h-full`}>
            <div className='flex flex-col bg-slate-100 dark:bg-slate-900 dark:bg-slate-900  px-2 py-1 overflow-x-scroll scrollbar-hide space-y-4 pt-4 h-full'>
                {navigationLinks.map(link => (
                    <LightTooltip title={link.title} key={`tooltip-${link.url}`} placement="right">
                        <NavLink
                            key={link.url}
                            to={link.url}
                            className='relative font-semibold outline-none text-sm cursor-pointer p-2 rounded-full '
                            onClick={()=> setSelectedTab(link)}
                            style={({ isActive }) =>
                            isActive ? activeStyle : nonActiveStyle
                            }
                        >
                            <h1 key={link.url} className='bg-transparent hover:text-gray-500 focus:rounded-full  '>
                            {link.icon}
                        </h1>
                        {link.title == selectedTab.title ? (
                            <motion.div transition={{ duration: 0.20 }} className="mainUnderline" layoutId="mainUnderline">{link.icon}</motion.div>
                        ) : null }
                        
                    </NavLink>

                    </LightTooltip>
                    
                ))}
                <LightTooltip title={'Documentation'}  placement="right">
                    <div
                        className='relative font-semibold outline-none text-sm cursor-pointer p-2 rounded-full'
                        style={nonActiveStyle}
                        onClick={() => window.open('https://docs.iotflows.com/', "_blank")}
                    >
                        <h1  className='bg-transparent hover:text-gray-500 focus:rounded-full  '>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                            </svg>
                        </h1>
                    </div>
                </LightTooltip>
            </div>
            
            
        </nav>
    )
}

export default LeftSideNav