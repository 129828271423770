import React, { useEffect, useState } from 'react'
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import ProjectsTable from './ProjectsTable';

function MainProjectsListPage() {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [projectsList, setProjectsList] = useState([])

    useEffect(() => {
        if(selectedOrganization){
            refreshProjectsList()
        }
    },[selectedOrganization])

    const refreshProjectsList = () => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/projects')
            .then(async response => {
            const data = await response.json();
              if(response.ok ){
                if(data && data.data){
                    // console.log(data.data)
                    setProjectsList(data.data)
                }
              }
            });

        }
      }

  return (
    <div className={`overflow-scroll scrollbar-hide max-h-[calc(100vh-5rem)] `}> 
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
            <ProjectsTable data={projectsList} rowsPerPage={20} refreshProjectsList={refreshProjectsList} />
        </div>
    </div>
  )
}

export default MainProjectsListPage