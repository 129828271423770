import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import toast from 'react-hot-toast';
import ReactJson from 'react-json-view';
import CsvTable from './CsvTable'


var convert = require('xml-js');

function DeviceDataModal({btnType, device, openDeviceDataModal, deviceDataRow, selectedDataRow, handleCloseDeviceDataModal}) {
    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(false)
    let [dataType, setDataType] = useState('xml1')
    const isDark = useRecoilValue(darkModeState);
    


  function closeModal() {
    setIsOpen(false)
    handleCloseDeviceDataModal()
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if(openDeviceDataModal){
        setIsOpen(true)
    }else{
        setIsOpen(false)
    }

  },[openDeviceDataModal])




  const cutTopic = (topic) => {
    try{
      if(topic){
        var topicSliced = topic.split('/');
        // var subTopicStart = topicSliced[topicSliced.indexOf('data-streams')+2];
        var subTopicStartIndex = topicSliced.indexOf('data-streams')+2;
        if(topicSliced[subTopicStartIndex]){
          var subTopic = topicSliced.slice(subTopicStartIndex).join('/') 
          return subTopic
        }else {
          // console.log('Not valid topic')
          return ""
        }
      }
    }
    catch(e){
      console.log('Catch')
      console.log(e)
      return ""
    }
  }

  const dataXMLFormatter1 = (deviceDataRow) => {
    try{

      let result2 = convert.xml2js(deviceDataRow, {compact: true});

      return <ReactJson src={result2} theme={isDark ? 'monokai':''}/> 
    }
    catch(e){
      setDataType('csv1')
    }
  }
  const dataXMLFormatter = (deviceDataRow) => {
    try{

      let result2 = convert.xml2js(deviceDataRow, {compact: true});

      return <ReactJson src={result2} theme={isDark ? 'monokai':''}/> 
    }
    catch(e){
      console.log('error converting to XML')
      setDataType('raw')
      toast.error('Data could not be converted to XML')
      return deviceDataRow
    }
  }

  const dataCSVFormatter1 = (deviceDataRow) => {
    try{
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }
      

      return  <CsvTable headers={headers} data={data} selectedDataRow={selectedDataRow}/> 
    }
    catch(e){
      setDataType('json1')
    }
  }

  const dataCSVFormatter = (deviceDataRow) => {
    try{
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }
      

      return  <CsvTable headers={headers} data={data} selectedDataRow={selectedDataRow}/> 
    }
    catch(e){
      console.log('error converting to CSV')
      setDataType('raw')
      toast.error('Data could not be converted to CSV')
      return deviceDataRow
    }
  }

  const dataJSONFormater1 = (deviceDataRow) => {
    try{
      var parsedDeviceData = JSON.parse(deviceDataRow)

      if(typeof parsedDeviceData == "object"){
        return <ReactJson src={parsedDeviceData} enableClipboard={true}  theme={isDark ? 'monokai':''}/> 
      }else {

        setDataType('raw')
      }
    }
    catch(e){
        setDataType('raw')
    }
  }

  const dataJSONFormater = (deviceDataRow) => {
    try{
      var parsedDeviceData = JSON.parse(deviceDataRow)

      if(typeof parsedDeviceData == "object"){
          return <ReactJson src={parsedDeviceData} enableClipboard={true} theme={isDark ? 'monokai':''} /> 
      }else {
        setDataType('raw')
        toast.error('Data could not be converted to JSON')
        }
    }
    catch(e){
        setDataType('raw')
        toast.error('Data could not be converted to JSON')
      return deviceDataRow
    }
  }

  const downloadDataButtonClick = () => {
    
    if(dataType == 'csv1' || dataType == 'csv'){

      // console.log("Inside download CSV file")
      
      let lines = deviceDataRow.split('\n');
      let headers = lines[0].split(',');
      let first_row = lines[1].split(',');
      

      var data = [];
      for(var i = 1; i < lines.length; i++){
          let row = lines[i].split(',');
          data.push(row)
      }


      var csv = headers+'\n';
      data.map(row => (
        csv += row+'\n'
      ))
  
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = selectedDataRow.data_stream_name+'_data.csv';
      hiddenElement.click();

    } else if (dataType == 'json1' || dataType == 'json'){
      let jsonData = JSON.parse(deviceDataRow);
      // console.log("Inside download JSON file")
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(jsonData, null, 4));
      hiddenElement.target = '_blank';
      hiddenElement.download = selectedDataRow.data_stream_name+'_data.json';
      hiddenElement.click();

    } else if (dataType == 'xml1' || dataType == 'xml'){
      let result2 = convert.xml2js(deviceDataRow, {compact: true});
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(result2, null, 4));
      hiddenElement.target = '_blank';
      hiddenElement.download = selectedDataRow.data_stream_name+'_xml_json_data.json';
      hiddenElement.click();

    } else {
      // console.log("Inside download txt file")
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(deviceDataRow);
      hiddenElement.target = '_blank';
      hiddenElement.download = selectedDataRow.data_stream_name+'_data.txt';
      hiddenElement.click();
    }
    
    
  };
  

  return (
    <>
      <div className=" justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>download</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className={`flex p-2 text-sm items-baseline ml-2 ${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} outline-0 focus:outline-0`} onClick={openModal} >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
        </svg>
      </motion.button>
        }
        {btnType ==='inlineIconBtn' &&
            <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
        </button>
      }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-[80%] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                    <motion.div
                    key='editNewClient'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                      Device Data
                      </div>
                      <div className={`px-4`}>
                            <div className={`w-full flex px-1 py-0.5`}>
                                <div>
                                    <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>Data Stream Name:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{selectedDataRow.data_stream_name}</span>
                                </div>
                            </div>
                            <div className={`w-full flex px-1 py-0.5`}>
                            <div>
                                <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>Client Name:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{selectedDataRow.data_stream_name}</span>
                            </div>
                            </div>
                            <div className={`w-full flex px-1 py-0.5`}>
                            <div>
                                <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>Received At:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{selectedDataRow.received_at}</span>
                            </div>
                            </div>
                            <div className={`w-full flex px-1 py-0.5`}>
                            <div>
                                <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>Topic:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{selectedDataRow.topic}</span>
                            </div>
                            </div>
                            <div className={`w-full flex px-1 py-0.5`}>
                            <div>
                                <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>SubTopic:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{cutTopic(selectedDataRow.topic)}</span>
                            </div>
                            </div>
                            <div className={`w-full flex px-1 py-0.5`}>
                            <div>
                                <span className={`${isDark?'text-gray-400':'text-gray-500'} text-sm`}>Size:</span> &nbsp;<span className={`${isDark?'text-gray-300':'text-gray-600'} text-sm`}>{selectedDataRow.data_stream_size}</span>
                            </div>
                            </div>
                        </div>
                        <div className='flex overflow-x-scroll my-4'>
                            <button  className={`mx-2 px-3 py-1 text-sm font-light rounded-full border-[0.01em] ${isDark?'text-blue-300 border-blue-300':'text-blue-600 border-blue-600'} `}  onClick={() => setDataType('raw')} >
                                Raw 
                            </button>
                            <button  className={`mx-2 px-3 py-1 text-sm font-light rounded-full border-[0.01em] ${isDark?'text-blue-300 border-blue-300':'text-blue-600 border-blue-600'} `}  onClick={() => setDataType('json')} >
                                JSON 
                            </button>
                            <button  className={`mx-2 px-3 py-1 text-sm font-light rounded-full border-[0.01em] ${isDark?'text-blue-300 border-blue-300':'text-blue-600 border-blue-600'} `}  onClick={() => setDataType('csv')} >
                                CSV 
                            </button>
                            <button  className={`mx-2 px-3 py-1 text-sm font-light rounded-full border-[0.01em] ${isDark?'text-blue-300 border-blue-300':'text-blue-600 border-blue-600'} `}  onClick={() => setDataType('xml')} >
                                XML 
                            </button>
                        </div>
                        <div className={`overflow-y-scroll max-h-[500px] px-4 ${isDark?'text-gray-300':'text-gray-600'}  `}>
                            {dataType == 'xml1'? dataXMLFormatter1(deviceDataRow) : null}
                            {dataType == 'csv1'? dataCSVFormatter1(deviceDataRow) : null}
                            {dataType == 'json1'? dataJSONFormater1(deviceDataRow) : null}
                            {dataType == 'raw'? deviceDataRow : null}
                            {dataType == 'json'? dataJSONFormater(deviceDataRow) : null}
                            {dataType == 'csv'? dataCSVFormatter(deviceDataRow) : null}
                            {dataType == 'xml'? dataXMLFormatter(deviceDataRow) : null}
                        </div>
                      {/* <div className='px-4 max-h-[400px] overflow-scroll scrollbar-hide'>
                        {dataJSONFormater(device.device_system_information)}
                      </div> */}
                    </motion.div>
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                    <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    <button
                          type="submit"
                          className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={downloadDataButtonClick}
                          disabled={loading}
                        >
                          Download
                          {dataType == 'xml1'? ' xml2json.json file': null}
                            {dataType == 'csv1'? '.csv file': null}
                            {dataType == 'json1'? '.json file': null}
                            {dataType == 'raw'? '.txt file' : null}
                            {dataType == 'json'? '.json file' : null}
                            {dataType == 'csv'? '.csv file': null}
                            {dataType == 'xml'? 'xml2json.json file': null}
                          {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                        </button>
                    </div>

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default DeviceDataModal