import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';

const CODE_LENGTH = new Array(6).fill(0);

function QRcodeMFA({remoteOpenModal, handleOpenModal, userInfo}) {
  const isDark = useRecoilValue(darkModeState);
  const [isOpen, setIsOpen] = useState(false)
  const [strQR, setStrQR] = useState('')
  const [enterAuthCode, setEnterAuthCode] = useState(false)
  const [issueMFAMessage, setIssueMFAMessage] = useState('')
  const inputRef = useRef(null)
  const [authenticationValue, setAuthenticationValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [focused, setFocused] = useState(false)
  const [inputtingMFA, setInputtingMFA] = useState(false)

  const values = authenticationValue.split("");
  const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
  const hideInput = !(values.length < CODE_LENGTH.length);

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    handleOpenModal(false)
  }

  useEffect(() => {
    if(remoteOpenModal){
      setIsOpen(true)
    }
    
  },[remoteOpenModal])

  useEffect(() => {
    Auth.setupTOTP(userInfo)
    .then((code) => {
        
      let issuer = 'IoTFlows';
      // let str = "otpauth://totp/AWSCognito:"+ this.props.currentUserInfo[0].user_username + "?secret=" + code + "&issuer=" + issuer;
      let str = "otpauth://totp/AWSCognito:"+ issuer + "?secret=" + code + "&issuer=" + issuer;
      setStrQR(str)
    }).catch( e => {
      console.log('error', e)
  })
  }, [])




  // MFA Functions
  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleFocus = () => {
      setFocused(true)
  };

  const handleBlur = () => {
      setFocused(false)
  };

  const handleChange = (e) => {
      const value = e.target.value.replace(/[^0-9]/g, "");
      
      if (authenticationValue.length >= CODE_LENGTH.length) return null;
      let tempval = (authenticationValue + value).slice(0, CODE_LENGTH.length)
      return (
          setAuthenticationValue(tempval)
      )
    };

  const handleKeyUp = (e) => {
      if (e.key === "Backspace") {
          let val = authenticationValue.slice(0, authenticationValue.length - 1)
          return(
              setAuthenticationValue(val)
          )
      }
  }

  const handleBackToQR = () => {
    setEnterAuthCode(false)
    setAuthenticationValue('')
  }

  const submitMFA = () => {
    if(!loading){
      setLoading(true)
      setInputtingMFA(true)
      Auth.verifyTotpToken(userInfo, authenticationValue)
        .then(() => {
  
            // don't forget to set TOTP as the preferred MFA method
            Auth.setPreferredMFA(userInfo, 'TOTP');
            setInputtingMFA(false)
            setAuthenticationValue('')
            setIssueMFAMessage('')
            setLoading(false)
            handleOpenModal(true)
            
            // ...
        }).catch( err => {
          setInputtingMFA(false)
          setLoading(false)
          setAuthenticationValue('')
          setIssueMFAMessage(err.message)
        });
    }
    
  }

  
  if(Object.keys(authenticationValue).length >= 6 && !inputtingMFA){
    submitMFA()
  }

  return (
    <>
      {/* <div className="flex items-center justify-center">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Add Sensor</span>
          </motion.button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    {!enterAuthCode ? 'Set up authentication app' :'Confirm Authentication App'}
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                      
                      {!enterAuthCode ? (
                        <motion.divQRCId
                          key='authenticationQR'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className='flex flex-col text-center'
                        > 
                          <div className={`text-base mt-4 ${isDark?'text-gray-400':'text-gray-600'} `}>Scan the QR code below into a third party authentication app.</div>
                          <div className='mx-auto mt-4 mb-8'>
                            <QRCode value={strQR}/>
                          </div>

                          <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                              type="button"
                              className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className={`cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                              onClick={() => setEnterAuthCode(true)}
                              // disabled={Object.keys(selectedGateway).length === 0}
                            >
                              Next
                            </button>
                          </div>

                          
                            
                        </motion.divQRCId>

                      ): (
                        <motion.div
                          key='enterQRcode'
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className='flex flex-col text-center'
                        > 
                          <div className={`text-base mt-4 ${isDark?'text-gray-400':'text-gray-600'} `}>Enter the 6-digit code you see in the app.</div>
                          {/* 6 code digits  */}
                          <div className={`border-[1px] border-solid border-black/20 ${isDark ? 'border-gray-400/30' : 'border-black/20'} inline-block relative flex mx-auto mt-6 rounded-xl bg-transparent`} onClick={handleClick}>
                          <input
                                  value=""
                                  ref={inputRef}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  onKeyUp={handleKeyUp}
                                  className={`absolute ${isDark ? 'caret-gray-400' : ''} border-0 text-3xl text-center outline-none border-transparent focus:border-transparent focus:ring-0 focus:outline-none m-0 appearance-none`}
                                  type="text"
                                  style={{
                                      width: "40px",
                                      top: "0px",
                                      bottom: "0px",
                                      left: "0px",
                                      borderRadius:'999px',
                                      backgroundColor:'transparent',
                                      left: `${selectedIndex * 40}px`,
                                      opacity: hideInput ? 0 : 1,
                                  }}
                              />
                          {
                              CODE_LENGTH.map((v, index) => {
                                  const selected = values.length === index;
                                  const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                  return (
                                  <div key={index} className={`border-r-[1px] bg-transparent ${isDark ? 'text-gray-400 border-gray-400/30' : 'text-gray-600 border-black/20'} border-solid w-10 h-12 flex items-center justify-center text-3xl relative m-0 last:border-0`}>
                                      {values[index]}
                                      {(selected || filled) && focused && (index == 0) && <div className={`absolute inset-0 shadow-authentication-box rounded-l-xl `} />}
                                      {(selected || filled) && focused && (index > 0 && index < 5) && <div className='absolute inset-0 shadow-authentication-box '/>}
                                      {(selected || filled) && focused && (index == 5) && <div className='absolute inset-0 shadow-authentication-box rounded-r-xl' />}
                                  </div>
                                  );
                              })
                          }
                          </div>
                          {issueMFAMessage &&
                          <div className='p-1 ml-3 mt-3 flex items-center'>
                              <svg fill="currentColor" className='text-rose-600 w-3 h-3' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                              </svg>
                              <span className='text-sm font-normal text-rose-600' >&nbsp;&nbsp;{issueMFAMessage}</span>
                          </div>
                          }

                          <form >
                            <div className={`flex p-4 px-5 items-center mt-6 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                              <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={handleBackToQR}
                                // disabled={Object.keys(selectedGateway).length === 0}
                              >
                                Back
                              </button>
                              <button
                                type="button"
                                className={`inline-flex ml-auto rounded-md bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={`relative cursor-pointer ml-2 inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                onClick={submitMFA}
                                disabled={Object.keys(authenticationValue).length < 6 || loading}
                              >
                                {'Submit'}
                                { !loading && 
                                  <div >
                                      <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark?' fill-white text-gray-500 ':' fill-blue-600 text-gray-300 '}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                      </svg>
                                  </div>
                                }
                              </button>
                            </div>
                          </form>
                            
                        </motion.div>

                      )}
                  </AnimatePresence>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default QRcodeMFA