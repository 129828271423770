import { useEffect, useState } from 'react';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import DevicesTable from './DevicesTable';
import Map from '../components/Map';
import PieChart from '../components/charts/PieChart';
import LineAreaChart from '../components/charts/LineAreaChart';

function SelectedProjectList() {
    const navigate = useNavigate()
    const [selectedProject, setSelectedProject] = useState([])
    const [deviceList, setDeviceList] = useState([])
    const [devicesDic, setDevicesDic] = useState([])
    const [storagePieChartData, setStoragePieChartData] = useState([])
    const [totalSizeSummary, setTotalSizeSummary] = useState('')
    const {projectUuid } = useParams()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [todayPieChartData, setTodayPieChartData] = useState([])
    const [todaysTotalSumSummary, setTodaysTotalSumSummary] = useState('')
    const [todaysTotalSumInteger, setTodaysTotalSumInteger] = useState('')
    const [monthsPieChartData, setMonthsPieChartData] = useState([])
    const [monthsTotalSumSummary, setMonthsTotalSumSummary] = useState('')
    const [monthsTotalSumInteger, setMonthsTotalSumInteger] = useState('')
    const [interactionsData, setInteractionsData] = useState([])
    
    useEffect(() =>{
        try {
            if(selectedOrganization && projectUuid){
                getCurrentProjectInfo()
                refreshDeviceList()
                getStorageData()
                getTodaysData()
                getMonthsData()
                getHistoricalInteractions()
            }
            
        } catch (error) {
            
        }
    },[selectedOrganization, projectUuid])

    const getHistoricalInteractions = () => {
        getData(apiLink + '/v1/projects/' + projectUuid + '/interaction_count/historical/highcharts')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data && data.data && Object.keys(JSON.parse(data.data)).length > 0 ){

            let historicalData =  JSON.parse(data.data)
            setInteractionsData(historicalData)
            
          }
        }
      });
    }
    const getStorageData = () => {
        // Get data messages sizes
        getData(apiLink + '/v1/projects/' + projectUuid +'/devices_interaction_size')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            
            if(data && data.data){

            var dataSizes = data.data.map(result =>({
                name: result.device_name,
                y: parseInt(result.size),
                prettyY: formatBytes(result.size),
                device_uuid: result.device_uuid
            }))

            let sizeSum = 0
            for(var i = 0; i < Object.keys(dataSizes).length; i++) {
                sizeSum = sizeSum + dataSizes[i].y
            }

            var formattedSize = formatBytes(sizeSum)

            setTotalSizeSummary(formattedSize)
            setStoragePieChartData(dataSizes)

            }
        }
        });
    }

    const getTodaysData = () => {// Get today project interactions
        getData(apiLink + '/v1/projects/' + projectUuid +'/devices_interaction_count/today')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data && Object.keys(data.data).length > 0){
              
    
              var todaysData = data.data.map(result =>({
                  name: result.device_name,
                  y: parseInt(result.count),
                  device_uuid: result.device_uuid
              }))
              var todaysSum = 0
              for(var i = 0; i < Object.keys(todaysData).length; i++) {
                todaysSum = todaysSum + todaysData[i].y
              }
              var todaysSumInteger = todaysSum;
              if(todaysSumInteger >= 1000000){
                todaysSum = (Number.parseFloat(todaysSumInteger)/1000000.00).toFixed(1)
                todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
                todaysSum = todaysSum+"M"
              }
              else if( todaysSumInteger >= 100000  ){
                todaysSum = (Number.parseFloat(todaysSumInteger)/1000.00).toFixed(1)
                todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
                todaysSum = todaysSum+"k"
              }
              else{
                todaysSum = todaysSum.toLocaleString('en-US', {maximumFractionDigits:2})
              }
    
              setTodaysTotalSumInteger(todaysSumInteger)
              setTodaysTotalSumSummary(todaysSum)
              setTodayPieChartData(todaysData)
    
              
            }
          }
        });
    }

    const getMonthsData = () => {
    
        // Get months project interactions
        getData(apiLink + '/v1/projects/' + projectUuid +'/devices_interaction_count/this_month')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data && Object.keys(data.data).length > 0){
              
              var monthsData = data.data.map(result =>({
                  name: result.device_name,
                  y: parseInt(result.count),
                  device_uuid: result.device_uuid
              }))
    
    
              var monthsSum = 0
              for(var i = 0; i < Object.keys(monthsData).length; i++) {
                monthsSum = monthsSum + monthsData[i].y
              }
    
              var monthsSumInteger = monthsSum;
              if(monthsSum > 1000000){
                monthsSum = (Number.parseFloat(monthsSum)/1000000.00).toFixed(2)
                monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
                monthsSum = monthsSum+"M"
              }
              else if( monthsSum > 100000 ){
                monthsSum = (Number.parseFloat(monthsSum)/1000.00).toFixed(1)
                monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
                monthsSum = monthsSum+"k"
              }
              else {
                monthsSum = monthsSum.toLocaleString('en-US', {maximumFractionDigits:2})
              }
              
              setMonthsTotalSumInteger(monthsSumInteger)
              setMonthsTotalSumSummary(monthsSum)
              setMonthsPieChartData(monthsData)
            }
          }
        });
    }

    const getCurrentProjectInfo = () => {
        if(selectedOrganization && projectUuid){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/projects')
            .then(async response => {
            const data = await response.json();
            if(response.ok ){
                if(data && data.data){
                    // console.log('orga data', data.data)
                    data.data.map(project =>{
                        project.project_uuid == projectUuid && setSelectedProject(project)
                    })
                }
            }
            });
        }

    }

    const refreshDeviceList = () => {
        if( projectUuid){
            getData(apiLink + '/v1/projects/' + projectUuid + '/devices')
            .then(async response => {
            const data = await response.json();
            if(response.ok ){
                if(data && data.data){
                    var dic = {};
                    for(var i=0; i<Object.keys(data.data).length; i++){
                        if (data.data[i].device_last_heard){
                        let localDateTime = new Date(data.data[i].device_last_heard);
                        localDateTime = localDateTime.toLocaleTimeString()+' '+localDateTime.toLocaleDateString();

                        data.data[i].device_last_heard = localDateTime;
                        dic[data.data[i].device_uuid]= data.data[i];
                        } else {
                        data.data[i].device_last_heard = '';
                        dic[data.data[i].device_uuid]= data.data[i];
                        }
                    }
                    
                    setDeviceList(data.data)
                    setDevicesDic(dic)
                }
            }
            });
        }

    }


    const formatBytes = (bytes, decimals = 1) => {
        if (bytes == 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
      }

  return (
    <div className={`mt-2 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}>
        {/* Breadcrumvbs */}
        <nav className='mb-2 flex items-center'>
            <ol className='hidden sm:flex'>
                <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/projects`)}>Projects</li>
                <li className='breadcrumbsSpacer'>/</li>
                <li className='breadcrumbsCurrent'>{selectedProject.project_name  ? selectedProject.project_name :''}</li>
            </ol>
            <ol className='flex sm:hidden'>
                <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/projects`)}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                    <span>Projects</span>
                </li>
            </ol>
        </nav>
        <div className='w-full mt-2 overflow-scroll '>
            <div className='flex flex-col xl:flex-row w-full'>
                {/* Map */}
                <div className='w-full h-[300px] lg:h-[411px]'>
                    <div className='w-full h-full flex rounded-xl overflow-hidden'>
                        <Map devicesDic={devicesDic} deviceList={deviceList} /> 
                    </div>
                </div>
                {/* Cards */}
                <div className='flex flex-col mt-2 xl:ml-2 xl:mt-0'>
                    <div className='flex w-full space-x-2'>
                        <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                            <PieChart chartName='Storage' data={storagePieChartData} centerValue={totalSizeSummary} subCenterValue='' />
                        </div>
                        <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                            <PieChart chartName="Today's Messages" data={todayPieChartData} centerValue={todaysTotalSumSummary} subCenterValue={todaysTotalSumInteger} />
                        </div>
                        <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full'>
                            <PieChart chartName="Month's Messages" data={monthsPieChartData} centerValue={monthsTotalSumSummary} subCenterValue={monthsTotalSumInteger} />
                        </div>
                    </div>
                    <div className='bg-slate-50 dark:bg-slate-800 rounded-xl w-full lg:mt-2'>
                        <LineAreaChart data={interactionsData} chartName='Published Messages' tooltipTitle='Interactions' />
                    </div>
                </div>

            </div>

        </div>
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
            <DevicesTable data={deviceList} rowsPerPage={50} refreshDeviceList={refreshDeviceList} projectUuid={projectUuid} />
        </div>
    </div>
  )
}

export default SelectedProjectList