import React from 'react'
import DeleteProjectModal from '../components/modals/projectModals/DeleteProjectModal'
import EditProjectModal from '../components/modals/projectModals/EditProjectModal'
import LeaveProjectModal from '../components/modals/projectModals/LeaveProjectModal'
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useNavigate } from 'react-router-dom';

function ProjectListTableRow({el, refreshProjectsList}) {
    const navigate = useNavigate()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);

  return (
    <tr key={el.project_uuid} className='border-b-[0.01em] dark:border-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600'>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 cursor-pointer' onClick={() => navigate(`/${selectedOrganization}/projects/${el.project_uuid}`)}>
                {el.project_name}
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 cursor-pointer' onClick={() => navigate(`/${selectedOrganization}/projects/${el.project_uuid}`)}>
                {el.project_is_private ? 'Private' : 'Public'}
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 cursor-pointer' onClick={() => navigate(`/${selectedOrganization}/projects/${el.project_uuid}`)}>
                {el.project_description}
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            <div className='flex items-center ml-auto justify-end'>
                <div className='w-10'>
                    <LeaveProjectModal project={el} refreshProjectsList={refreshProjectsList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <EditProjectModal project={el} refreshProjectsList={refreshProjectsList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <DeleteProjectModal project={el} refreshProjectsList={refreshProjectsList} btnType='inlineIconBtn' />
                </div>

            </div>
        </td>
    </tr>
  )
}

export default ProjectListTableRow