import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import GeneralFormInput from '../../forms/GeneralFormInput';
import { postData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import Alert from '@mui/material/Alert';




function CreateDeviceApiKey({btnType,selectedDevice,  getDeviceApiKeys}) {
    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const [form, setForm] = useState({
      deviceApiKeyName: '',
      deviceApiKeyDescription: '',
    })
    const [clientName, setClientName] = useState('');
    const [clientUsername, setClientUsername] = useState('');
    const [clientPassword, setClientPassword] = useState('');
    const [passwordInfoModalOpen, setPasswordInfoModalOpen] = useState(false);


  function closeModal() {
    setIsOpen(false)
    setForm({deviceApiKeyName: '',deviceApiKeyDescription:''})
  }

  function openModal() {
    setIsOpen(true)
  }



  // **** Functions for General Input forms **** 
  const handleGeneralFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setForm(updatedForm);

  }
  // **** Functions for Input Department ****
  
  
  const submitCreateClient = () => {
    try {
      if(!loading){
        setLoading(true)
        
        postData(apiLink + '/v1/devices/' + selectedDevice.device_uuid + '/clients', 
        {'client_name': form.deviceApiKeyName,
          'client_description': form.deviceApiKeyDescription})
        .then(async response => {
          const data = await response.json();
          if(response.ok){
            setLoading(false)
            setClientName(form.deviceApiKeyName)
            setClientUsername(data.data[0].client_id)
            setClientPassword(data.data[0].password)
            setPasswordInfoModalOpen(true)
            toast.success('Device API Key has been successfully added!')
            getDeviceApiKeys()
            
          }
          else{
            try{
              const data = await response.json();
              toast.error(data.message)
              setLoading(false)
            }catch (e){
              setLoading(false)
              console.log('error')
            }
          }
        }) 
      }
    } catch (error) {
      console.log(error.message)
    }

  }

  const downloadCsv = () => {
    var csv = 'API Key Username,Password\n';
    csv += clientUsername + ',' + clientPassword

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Org API Key -'+ clientName + '.csv';
    hiddenElement.click();
  }

  return (
    <>
      <div className="flex items-center justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>Create Device Api Key</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <svg className='text-blue-600 w-4 h-4 mt-auto mb-0.5' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
          <span className=' text-blue-600'>Add</span>
      </motion.button>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                  {!passwordInfoModalOpen ? (
                    <motion.div
                    key='addNewClient'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                        Create IoT Api Key
                      </div>
                      <div className='px-4'>
                          <div >
                            <div className='mb-4'>
                              <div className="pt-4">
                                <GeneralFormInput label='Name' type='text' placeholder='' name='deviceApiKeyName' onChange={handleGeneralFormChange} />
                              </div>
                              <div className="pt-4">
                                <GeneralFormInput label='Description' type='text' placeholder='' name='deviceApiKeyDescription' onChange={handleGeneralFormChange} />
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitCreateClient}
                          disabled={form.deviceApiKeyName == '' || loading}
                        >
                          Create
                          {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                        </button>
                      </div>
                    </motion.div>

                  ):(
                    <motion.div
                    key='clientInfo'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                        API Key Password
                      </div>
                        <div className='px-4 py-2'>
                          <Alert severity="success" sx={{opacity:isDark && '0.8'}}>
                            You have successfully created the API key below. You can view the API key credentials below.<i><b>This is the last time 
                            these credentials will be available to download.</b></i> However, you can create new API keys at any time.
                            <br/>
                            You could use these credentials in an application such as NodeRED or Python to publish and subscribe to data streams and alerts
                          </Alert> 
                          <div className='flex flex-col mt-4 space-y-2'>
                            <div className={`flex ${isDark?'text-gray-300 ':'text-gray-600'}`}>
                              API Key Username:<span className='italic'>{clientUsername}</span>
                            </div>
                            <div className={`flex ${isDark?'text-gray-300 ':'text-gray-600'}`}>
                              Password:<span className='italic'>{clientPassword}</span>
                            </div>
                            <div className='flex'>
                              <button className={`text-sm rounded-full px-2 py-0.5 border ${isDark?'border-green-600 text-green-600 ':'border-green-500 text-green-500'}`} onClick={downloadCsv}>Download CSV</button>
                            </div>
                          </div>

                        </div>
                        <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                          <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={closeModal}
                          >
                            Close
                          </button>
                        </div>

                    </motion.div>
                  )}

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CreateDeviceApiKey