import { motion } from 'framer-motion'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization } from './atoms/orgStates';

function ErrorPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const navigation = useNavigate()
    
  return (
    <div className='h-screen w-full flex'>
        <div className='my-auto mx-auto'>
            <div className='flex flex-col'>
                <div className='flex'>
                    <div className='text-lg sm:text-5xl text-blue-600 font-bold pr-4'> 404</div>
                    <div className='flex flex-col '> 
                        <div className='flex flex-col pl-4  border-l-[0.01em] dark:border-slate-700'>
                            <span className='text-lg sm:text-5xl text-gray-800 dark:text-gray-200 font-bold'>Page not found</span>
                            <span className='text-xs sm:text-lg text-gray-400 '>Please check the URL in the address bar and try again.</span>
                            
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}
                            className='flex text-sm sm:text-base text-white items-center bg-blue-600 hover:bg-blue-700 rounded py-1 px-3 w-max ml-4 mt-4 sm:mt-8 '
                            onClick={() => navigation(`/${selectedOrganization}`)}
                        >
                            Go back home
                        </motion.button>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default ErrorPage