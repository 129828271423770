import React, { useEffect, useState } from 'react'
import { LightTooltip } from '../styles/Tooltip'
import { darkModeState } from '../atoms/darkModeState';
import { useRecoilValue } from "recoil";
import AddFolderToFileManagementModal from '../components/modals/InstanceModals/AddFolderToFileManagementModal';
import { apiLink } from '../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { postData } from '../ApiCalls/DataApis';
import RenameFileModal from '../components/modals/InstanceModals/RenameFileModal';
import DeleteFileModal from '../components/modals/InstanceModals/DeleteFileModal';

var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!


function FileManagementTable({currentPath, instrumentType_uuid, server_url_ssh, serverName}) {
    const isDark = useRecoilValue(darkModeState);
    const [loading, setLoading] = useState(false)
    const [directoryfiles, setDirectoryfiles] = useState([])
    const [inputPath, setInputPath] = useState('')
    const [selectedFile, setSelectedFile] = useState([])
    const [isFileSelected, setIsFileSelected] = useState(false)
    const [disableDownload, setDisableDownload] = useState(true)
    const [path, setPath] = useState(currentPath)
    
    

    useEffect(() => {
        if(instrumentType_uuid) {
            setPath(currentPath)
            postData(apiLink + '/v1/' + instrumentType_uuid + '/sftp/list', 
            {'username': localStorage.getItem('ssh_username'),
                'password': localStorage.getItem('ssh_password'),
                "host": server_url_ssh,
                "path": path,
            })
            .then(async response => {
            const data = await response.json();
              if(response.ok){
                if(data && data.data){
                    setDirectoryfiles(data.data)
                    setInputPath(currentPath)
                }
              }
              else{
                try{
                  toast.error(data.message)
                }catch (e){
                    toast.error('Something went wrong')
                }
              }
            })
        }

    },[instrumentType_uuid])

    useEffect(() => {
        refreshDeviceFileList()
      }, [path])

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }


    const refreshDeviceFileList = () => {
      
            if(!loading){
              setLoading(true)
              postData(apiLink + '/v1/' + instrumentType_uuid + '/sftp/list', 
                  {'username': localStorage.getItem('ssh_username'),
                    'password': localStorage.getItem('ssh_password'),
                    "host": server_url_ssh,
                    "path": path,
                  })
                    .then(async response => {
                        const data = await response.json();
                      if(response.ok){
                        setDirectoryfiles(data.data)
                        setSelectedFile([])
                        setIsFileSelected(false)
                        setDisableDownload(true)
                        setInputPath(path)
                        setLoading(false)
                        
                      
                      }
                      else{
                        try{
                          toast.error(data.message)
                          setLoading(false)
                        }catch (e){
                          toast.error('Something went wrong')
                          setLoading(false)
                        }
                      }
                    })
            }
        
      }
    
    function changeHandler (event) {
        var newPath = currentPath + '/' + event.target.files[0].name;
    
        var reader = new FileReader();
        reader.onload = function() {
          let arrayBuffer = this.result
          console.log('this.result', this.result)
          let input = new Buffer(arrayBuffer)
          console.log('input', input)
          postData(apiLink + '/v1/' + instrumentType_uuid + '/sftp/put', 
            {'username': localStorage.getItem('ssh_username'),
                'password': localStorage.getItem('ssh_password'),
                "host": server_url_ssh,
                "path": newPath,
                "input": input
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                toast.success('File successfully uploaded!')
                refreshDeviceFileList()
                }
                else{
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error('Something went wrong')
                    }
                }
            })
        }
        reader.readAsArrayBuffer(event.target.files[0]);
      };

      const handleRowSelected = (file) => {
        setSelectedFile(file)
        setIsFileSelected(true)
        if(file.type == "d"){
            setDisableDownload(true)
        }else {
            setDisableDownload(false)
        }
      }

      const handleNavigateToPath = (file) => {
        if(file.type == "d"){
          let folderPath = ""
          if(path == "/"){
            folderPath = path + selectedFile.name;
          }else {
            folderPath = path + '/' + selectedFile.name;
          }
          setPath(folderPath)
          
        }
      }

      const handlerDownloadFile = () => {
        var filePath = path + '/' + selectedFile.name
        postData(apiLink + '/v1/' + instrumentType_uuid + '/sftp/get', 
          {'username': localStorage.getItem('ssh_username'),
            'password': localStorage.getItem('ssh_password'),
            "host": server_url_ssh,
            "path": filePath,
          })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              const results = await response.json()
              let buffer = results.data.data;
               let buffer_binary = new Uint8Array(buffer);
              let blob = new Blob([buffer_binary]);
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = this.state.selectedFile.name;
              a.click();
              window.URL.revokeObjectURL(url);
             
            }
            else{
              try{
                
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
              }
            }
          })
          
      }

    const handlerBackButton = () => {
        let str = path;
        let paths = str.split('/')
        paths.pop()
        paths = paths.join('/')
        if(!paths) paths = '/'
        setPath(paths)
        refreshDeviceFileList()
    } 

    const handleInputPathNameChange = (event) => {
        let pathFiltered = event.target.value.replace(/\/{2,}/g, "/")
        setInputPath(pathFiltered.replace(/[^A-Za-z0-9-._/]/g, ""))
    }

    const keyPress = (e) => {
        console.log('e.keyCode', e.keyCode)
        if(e.keyCode == 13){
          if(!loading){
            setLoading(true)
            let filteredPath = inputPath;
            console.log('inputPath', inputPath)
              
              if (filteredPath != null && Object.keys(filteredPath).length > 1 && filteredPath[Object.keys(filteredPath).length - 1] == '/') {
                filteredPath = filteredPath.substring(0, Object.keys(filteredPath).length - 1);
              }
              console.log('filteredPath', filteredPath)
              postData(apiLink + '/v1/' + instrumentType_uuid + '/sftp/list', 
              {'username': localStorage.getItem('ssh_username'),
                'password': localStorage.getItem('ssh_password'),
                "host": server_url_ssh,
                "path": filteredPath,
              })
                .then(async response => {
                const data = await response.json();
                console.log('Response data', data)
                  if(response.ok){
                    if(data && data.data){
                        setPath(filteredPath)
                        setDirectoryfiles(data.data)
                        setIsFileSelected(false)
                        setDisableDownload(true)
                        setInputPath(filteredPath)
                        setLoading(false)
                    }
                  }
                  else{
                    try{
                      toast.error(data.message)
                      setLoading(false)
                    }catch (e){
                        setLoading(false)
                      toast.error('Something went wrong')
                    }
                  }
                })
            }
        }
    }

  return (
    <div>
        <table className="table-auto w-full">
                {/* <colgroup >
                    <col className='w-full'/>
                    <col className='w-[200px]'/>
                    
                </colgroup> */}
            <thead >
                <tr className='' >
                    <th className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' colSpan={3}>
                        {serverName? serverName : ''} Device File Manager
                    </th>
                    <th className='' colSpan={2}>
                        <div className='flex justify-end'>
                            {/* Icons */}
                            
                                {/* <button aria-label="Add folder"  onClick={handlerAddFolder} className={`text-gray-600 dark:text-gray-300`} >
                                    <svg  className='w-6 h-6 ' viewBox="0 0 16 16" fill="currentColor" strokeWidth={1.5} >
                                        <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
                                        <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                                    </svg>
                                </button> */}
                                <div className='mr-4'>
                                    <AddFolderToFileManagementModal 
                                        btnType='inlineIconBtn' 
                                        instrumentType_uuid={instrumentType_uuid} 
                                        refreshDeviceFileList={refreshDeviceFileList}
                                        host={server_url_ssh}
                                        path={path} 
                                    />
                                </div>
                                
                                <input id="icon-button-file" type="file" className='hidden'  onChange={changeHandler} />
                                <label htmlFor="icon-button-file" >
                                    <LightTooltip  title="Upload File"  >
                                        <div  className={`dark:text-gray-300 dark:hover:text-gray-100 text-gray-600 hover:text-gray-900 cursor-pointer flex`} >
                                            <svg className='w-6 h-6 ' viewBox="0 0 16 16" fill="currentColor" strokeWidth={1.5}>
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                            </svg>
                                            
                                        </div>
                                    </LightTooltip>
                                </label>
                                <div className='mx-4'>
                                    <RenameFileModal 
                                        btnType='inlineIconBtn' 
                                        isFileSelected={isFileSelected}
                                        selectedFile={selectedFile}
                                        instrumentType_uuid={instrumentType_uuid} 
                                        refreshDeviceFileList={refreshDeviceFileList}
                                        host={server_url_ssh}
                                        path={path} 
                                    />
                                </div>
                                <div>
                                    <button className={`dark:text-gray-300 dark:hover:text-gray-100 text-gray-600 hover:text-gray-900 flex ml-auto disabled:opacity-50`} onClick={handlerDownloadFile} disabled={disableDownload}>
                                    <LightTooltip  title="Download" >
                                        <svg  className='w-6 h-6 ' viewBox="0 0 16 16"  fill="currentColor" strokeWidth={1.5}>
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                        </svg>
                                    </LightTooltip>
                                    </button>
                                </div>
                                <div className='mx-4'>
                                    <DeleteFileModal 
                                        btnType='inlineIconBtn' 
                                        isFileSelected={isFileSelected}
                                        selectedFile={selectedFile}
                                        instrumentType_uuid={instrumentType_uuid} 
                                        refreshDeviceFileList={refreshDeviceFileList}
                                        host={server_url_ssh}
                                        path={path} 
                                    />
                                </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 w-[50px]' >
                        <div>
                            <button className={`dark:text-gray-300 dark:hover:text-gray-100 text-gray-600 hover:text-gray-900 flex disabled:opacity-50`} onClick={handlerBackButton}>
                            <LightTooltip  title="Back" >
                                <svg className='w-6 h-6 ' viewBox="0 0 16 16"  fill="currentColor" strokeWidth={1.5}>
                                    <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                                </svg>
                            </LightTooltip>
                            </button>
                        </div>
                    </th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 w-[50px]' >
                        <div>
                            <button className={`dark:text-gray-300 dark:hover:text-gray-100 text-gray-600 hover:text-gray-900 flex disabled:opacity-50`} onClick={refreshDeviceFileList}>
                            <LightTooltip  title="Refresh" >
                                <svg className='w-6 h-6 ' viewBox="0 0 16 16"  fill="currentColor" strokeWidth={1.5}>
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                    <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                </svg>
                            </LightTooltip>
                            </button>
                        </div>
                    </th>
                    <th className='relative text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-center' colSpan={3}>
                        <input 
                            className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} 
                            value={inputPath} 
                            onChange={handleInputPathNameChange}
                            onKeyDown={keyPress}
                        />
                        {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/3 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                    </th>
                </tr>
                <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 w-[50px]' ></th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-left' colSpan={2} >Filename</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>{`Filesize`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Last Modified`}</th>
                </tr>
            </thead>
            <tbody className='overflow-scroll'>
            {directoryfiles.map((file, index) => (
                <tr key={index} className={`cursor-pointer border-b-[0.01em] dark:border-slate-500 hover:bg-slate-300 dark:hover:bg-slate-700 ${file.name == selectedFile.name ? 'bg-slate-200 dark:bg-slate-600':''}`} 
                    onClick={() => handleRowSelected(file)} onDoubleClick={()=> handleNavigateToPath(file)} 
                >
                    <td className='w-[50px] p-4'>
                        {file.type == "d" ? (
                            <svg className='w-5 h-5 text-blue-400 ' xmlns="http://www.w3.org/2000/svg" stroke='currentColor' fill="currentColor" viewBox="0 0 20 20">
                                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                            </svg>
                        ) : (
                            <svg className='w-5 h-5 text-gray-600 dark:text-gray-300' xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                            </svg>
                          )}
                    </td>
                    <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-left'colSpan={2} >
                        {file.name}
                    </td>
                    <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-center' >
                        {file.size}
                    </td>
                    <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right' >
                    {handleDateFormat(file.modifyTime)}
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
  )
}

export default FileManagementTable