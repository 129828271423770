import React, { useState } from "react";
import TableFooter from "../components/TableFooter";
import LogTableRow from './LogTableRow';
import useTable from "../components/hooks/useTable";

function LogsTable({data, rowsPerPage}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
  return (
      <table className="table-auto w-full">
            {/* <colgroup >
                <col className='w-full'/>
                <col className='w-[200px]'/>
                
            </colgroup> */}
        <thead >
            <tr className=''>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3' colSpan={2}>
                    <div className='flex justify-end'>
                        <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
                    </div>
                </th>
            </tr>
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-base font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Organization Logs`}</th>
                <th className='text-base font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>Received At</th>
            </tr>
        </thead>
          <tbody className=''>
          {slice.map((el,index) => (
            <LogTableRow el={el} index={index} key={index} />
          ))}
        </tbody>
        <tfoot>
            <tr className=''>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3' colSpan={2}>
                    <div className='flex justify-end'>
                        <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
                    </div>
                </th>
            </tr>
        </tfoot>
      </table>
  )
}

export default LogsTable