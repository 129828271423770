import { useState } from "react";
import { useEffect } from "react"
import { apiLink } from "../ApiCalls/ApisVariables";
import { getData } from "../ApiCalls/DataApis";


function CurrentInvoiceTab({selectedOrganization}) {
    const [upcomingInvoice, setUpcomingInvoice] = useState([])
    const [lineItems, setLineItems] = useState([])
    const [lineItemsThisMonth, setLineItemsThisMonth] = useState([])
    const [lineItemsNextMonth, setLineItemsNextMonth] = useState([])
    const [nextMonthStartDate, setNextMonthStartDate] = useState([])
    const [nextMonthEndDate, setNextMonthEndDate] = useState([])
    const [isDataReady, setIsDataReady] = useState(false)
    const [upcomingInvoices, setUpcomingInvoices] = useState([])
    const [lineItemsArray, setLineItemsArray] = useState([])
    const [lineItemsThisMonthArray, setLineItemsThisMonthArray] = useState([])
    const [lineItemsNextMonthArray, setLineItemsNextMonthArray] = useState([])
    const [nextMonthStartDateArray, setNextMonthStartDateArray] = useState([])
    const [nextMonthEndDateArray, setNextMonthEndDateArray] = useState([])

    useEffect(() => {
      if(upcomingInvoices && Object.keys(upcomingInvoices).length > 0 && lineItemsArray && Object.keys(lineItemsArray).length > 0 ){
        setIsDataReady(true)
      }
    },[upcomingInvoice, upcomingInvoices, lineItemsArray])

    // useEffect(() => {
    //   if(upcomingInvoice && Object.keys(upcomingInvoice).length > 0){
    //     setIsDataReady(true)
    //   }
    // },[upcomingInvoice])

    useEffect(() => {

      // getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/upcoming_invoice_line_items')
      //   .then(async response => {
      //   if(response.ok ){
      //       const data = await response.json();
      //       if(data.data){
      //           setLineItems(data.data)
      //       }
      //   }
      //   });


      //   getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/upcoming_invoice')
      //   .then(async response => {
      //       if(response.ok ){
      //       const data = await response.json();
      //       if(data){
      //           setUpcomingInvoice(data)
      //           // console.log('invoice',data)
      //       }
      //       }
      //   });

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/upcoming_invoices')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data){
                // console.log('Upcoming INVOICES',data)
                setUpcomingInvoices(data)
            }
            }
        });

        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/upcoming_invoices_line_items')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            if(data){
              // console.log('Upcoming LINE Items',data)
              let tempArray = []
              data.map((lineItem,index)=>{
                tempArray[index] = lineItem.data
              })
              setLineItemsArray(tempArray)
            }
        }
        });

        

    },[selectedOrganization])

    useEffect(() => {
        if(lineItemsArray && upcomingInvoices && Object.keys(lineItemsArray).length > 0 && Object.keys(upcomingInvoices).length > 0){

          var tempLineItemsThisMonthArray = []
          var tempLineItemsNextMonthArray = []
          var tempNextMonthStartDateArray = []
          var tempNextMonthEndDateArray = []
          lineItemsArray.map((lineItems, index) => {
            var current_month_items = [];
            var next_month_items = [];
            var next_month_items_no_zeros = [];
            var current_month_items_no_zeros = [];
            for(var i=0; i<Object.keys(lineItems).length; i++){
              
                if( lineItems[i].period.end <= upcomingInvoices[index].period_end){
                    current_month_items[i] = lineItems[i];
                    if(current_month_items[i].amount){
                    current_month_items_no_zeros[i] = current_month_items[i];
                    }
                }else {
                    next_month_items[i] = lineItems[i];
                    if(next_month_items[i].amount){
                    next_month_items_no_zeros[i] = next_month_items[i];
                    }
                }
            }

            // added these two filters because I was getting empty arrays which were messing up my if statements later on and categorizing values in the wrong dates
            let current_no_zeros_month = current_month_items_no_zeros.filter(n => n)
            let next_no_zeros_month = next_month_items_no_zeros.filter(n => n)

            tempLineItemsThisMonthArray[index] = current_no_zeros_month.reverse()
            tempLineItemsNextMonthArray[index] = next_no_zeros_month.reverse()

            if(next_no_zeros_month.reverse()[0] && next_no_zeros_month.reverse()[0].period.start && next_no_zeros_month.reverse()[0].period.end){
              tempNextMonthStartDateArray[index] = next_no_zeros_month.reverse()[0].period.start
              tempNextMonthEndDateArray[index] = next_no_zeros_month.reverse()[0].period.end
            }


          })
            

            setLineItemsThisMonthArray(tempLineItemsThisMonthArray)
            setLineItemsNextMonthArray(tempLineItemsNextMonthArray)
            setNextMonthStartDateArray(tempNextMonthStartDateArray)
            setNextMonthEndDateArray(tempNextMonthEndDateArray)
    
        }

    }, [upcomingInvoices, lineItemsArray])
    
    // useEffect(() => {
    //     if(lineItems && upcomingInvoice && upcomingInvoice.period_end){
    //         var current_month_items = [];
    //         var next_month_items = [];
    //         var next_month_items_no_zeros = [];
    //         var current_month_items_no_zeros = [];
    //         for(var i=0; i<Object.keys(lineItems).length; i++){
    //             if( lineItems[i].period.end <= upcomingInvoice.period_end){
    //                 current_month_items[i] = lineItems[i];
    //                 if(current_month_items[i].amount){
    //                 current_month_items_no_zeros[i] = current_month_items[i];
    //                 }
    //             }else {
    //                 next_month_items[i] = lineItems[i];
    //                 if(next_month_items[i].amount){
    //                 next_month_items_no_zeros[i] = next_month_items[i];
    //                 }
    //             }
    //         }

    //         setLineItemsThisMonth(current_month_items_no_zeros.reverse())
    //         setLineItemsNextMonth(next_month_items_no_zeros.reverse())
    //         if(next_month_items_no_zeros.reverse()[0]){
    //             setNextMonthStartDate(next_month_items_no_zeros.reverse()[0].period.start)
    //             setNextMonthEndDate(next_month_items_no_zeros.reverse()[0].period.end)
    //         }
    
    //     }

    // }, [upcomingInvoice, lineItems])


  const convertCost = (price) => {
    if(price){
      var stringvalue = '';
      if(price < 0) {
        stringvalue =  Math.abs(price/100)
        stringvalue = stringvalue.toLocaleString()
        stringvalue = '-$' + stringvalue
      }  else{
        stringvalue = price /100
        stringvalue = '$' + stringvalue.toLocaleString()
      }  
      return stringvalue;
    }else {
      return '$0';
    }
  }

  const convertStringCost = (price) => {
    if(price){
      var priceToInt = parseInt(price)
      if(priceToInt == 0){
        priceToInt = parseFloat(price)
      }
      
      priceToInt = priceToInt /100
      priceToInt = priceToInt.toLocaleString()

      return '$'+priceToInt;
    }
  }

  const convertStringCostTiered = (row) => {
    if(row){
      var price;
      var description = row.description
      if(description.includes('$0.00006')){
        price = 0.00006;
      }
      else if(description.includes('$0.00005')){
        price = 0.00005;
      }
      else if(description.includes('$0.00004')){
        price = 0.00004;
      }
      else if(description.includes('$0.00003')){
        price = 0.00003;
      }else {
        return ''
      }

      return '$'+ price;
    }
  }

  const convertFinalCosts = (amount) => {
    if(amount){
      var price = convertCost(amount)
      return price
    } else {
      return "$0.00"
    }
  }

  const dateConverter = (date) => {
    // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
    if(date != undefined){
      var modifyDate = date*1000;
      modifyDate = new Date(modifyDate);
      modifyDate = modifyDate.toLocaleDateString([], {  month: 'long', day: 'numeric' })
      return modifyDate
    } else {
      return " "
    }
  }

  const billingCycleDateConverter = (start_date, end_date) => {
    if(start_date != undefined){
      var modifyStartDate = start_date*1000;
      var modifyEndDate = end_date*1000;
      modifyStartDate = new Date(modifyStartDate);
      modifyEndDate = new Date(modifyEndDate);
      modifyStartDate = modifyStartDate.toLocaleDateString([], {  month: 'long', day: 'numeric' });
      modifyEndDate = modifyEndDate.toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' });
      return (modifyStartDate + ' - ' + modifyEndDate)
    } else {
      return " "
    }
  }


  return (
    <div className='m-4' >
        {!isDataReady &&
          <div className="border-0 border-blue-300 shadow-iotflows-lg dark:shadow-lg-invert rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* {isDataReady && upcomingInvoice && 
        <div className='shadow-iotflows-lg dark:shadow-lg-invert rounded-lg p-4'>
            <table className="table-auto w-full ">
                <thead className=''>
                    <tr className="" >
                        <th className='font-bold tracking-wider px-2 py-0.5 text-2xl text-left text-gray-600 dark:text-gray-300' colSpan={4}>Upcoming invoice</th>
                    </tr>
                    <tr className="invoiceTableRow" >
                        <th className='text-sm px-2 py-3 text-left text-gray-500 dark:text-gray-400' colSpan={4}>
                        This is a preview of the invoice that will be billed on {dateConverter(upcomingInvoice.period_end)}. It may change if the subscription is updated.
                        </th>
                    </tr>
                    <tr className='invoiceTableRow' >
                        <td className='invoiceTableCellHeader'>DESCRIPTION</td>
                        <td className='invoiceTableCellHeader text-right'>QTY</td>
                        <td className='invoiceTableCellHeader text-right'>UNIT PRICE</td>
                        <td className='invoiceTableCellHeader text-right'>AMOUNT </td>
                    </tr>
                </thead>
                <tbody className='tableBodyContainer'>
                {upcomingInvoice && upcomingInvoice.period_start && upcomingInvoice.period_end &&
                <tr className="invoiceTableRow">
                    <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                    {billingCycleDateConverter(upcomingInvoice.period_start, upcomingInvoice.period_end)}
                    </td>
                </tr>
                }
                {lineItemsThisMonth.map((row) => (
                    <tr key={row.id} className="invoiceTableRow">
                        <td className='invoiceTableCellBody'>{row.description}</td>
                        <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                        <td className='invoiceTableCellBody text-right'>
                            {row.price.product == 'prod_HrbX8g4tbaDC9f' ?
                                convertStringCostTiered(row)
                                :
                                convertStringCost(row.price.unit_amount_decimal? row.price.unit_amount_decimal : null)
                            }    
                        </td>
                        <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                    </tr>
                ))}
                {nextMonthStartDate && nextMonthEndDate &&
                    <tr className="invoiceTableRow">
                        <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                        {billingCycleDateConverter(nextMonthStartDate, nextMonthEndDate)}
                        </td>
                    </tr>
                    }
                    {lineItemsNextMonth.map((row) => (
                        <tr key={row.id} className="invoiceTableRow">
                            <td className='invoiceTableCellBody'>{row.description}</td>
                            <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                            <td className='invoiceTableCellBody text-right'>{convertStringCost(row.price.unit_amount_decimal)}</td>
                            <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                        </tr>
                    ))} 
                    <tr className="invoiceTableRow">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Subtotal</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.subtotal? upcomingInvoice.subtotal : null)}</td>
                    </tr>
                    {upcomingInvoice.discount &&
                        <tr className="invoiceTableRow">
                            <td colSpan={1}/>
                            {upcomingInvoice.discount.coupon.percent_off != null ?
                            <td colSpan={2} className='invoiceTableCellBody text-right'>{`${upcomingInvoice.discount.coupon.name} (%${upcomingInvoice.discount.coupon.percent_off? upcomingInvoice.discount.coupon.percent_off : null})`}</td>
                            :
                            <td colSpan={2} className='invoiceTableCellBody text-right' >{`${upcomingInvoice.discount.coupon.name} (${convertCost(upcomingInvoice.discount.coupon.amount_off? upcomingInvoice.discount.coupon.amount_off: null)})`}</td>
                            }
                            <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.total_discount_amounts[0].amount)}</td>
                        </tr>
                    }
                    <tr className="invoiceTableRow">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Total</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.total? upcomingInvoice.total: null)}</td>
                    </tr>
                    <tr className="invoiceTableRow border-0">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Amount due</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.amount_due? upcomingInvoice.amount_due: null)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        } */}
        {isDataReady && upcomingInvoices && Object.keys(upcomingInvoices).length > 0 && 
        upcomingInvoices.map((upcomingInvoice, index) => (
          <div key={index} className='shadow-iotflows-lg dark:shadow-lg-invert rounded-lg p-4 mb-4'>
              <table className="table-auto w-full ">
                  <thead className=''>
                      <tr className="" >
                          <th className='font-bold tracking-wider px-2 py-0.5 text-2xl text-left text-gray-600 dark:text-gray-300' colSpan={4}>Upcoming invoice</th>
                      </tr>
                      <tr className="invoiceTableRow" >
                          <th className='text-sm px-2 py-3 text-left text-gray-500 dark:text-gray-400' colSpan={4}>
                          This is a preview of the invoice that will be billed on {dateConverter(upcomingInvoice.period_end)}. It may change if the subscription is updated.
                          </th>
                      </tr>
                      <tr className='invoiceTableRow' >
                          <td className='invoiceTableCellHeader'>DESCRIPTION</td>
                          <td className='invoiceTableCellHeader text-right'>QTY</td>
                          <td className='invoiceTableCellHeader text-right'>UNIT PRICE</td>
                          <td className='invoiceTableCellHeader text-right'>AMOUNT </td>
                      </tr>
                  </thead>
                  <tbody className='tableBodyContainer'>
                  {upcomingInvoice && upcomingInvoice.period_start && upcomingInvoice.period_end &&
                  <tr className="invoiceTableRow">
                      <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                      {billingCycleDateConverter(upcomingInvoice.period_start, upcomingInvoice.period_end)}
                      </td>
                  </tr>
                  }
                  {lineItemsThisMonthArray && lineItemsThisMonthArray[index] && Object.keys(lineItemsThisMonthArray[index]).length > 0 &&
                  lineItemsThisMonthArray[index].map((row) => (
                      <tr key={row.id} className="invoiceTableRow">
                          <td className='invoiceTableCellBody'>{row.description}</td>
                          <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                          <td className='invoiceTableCellBody text-right'>
                              {row.price.product == 'prod_HrbX8g4tbaDC9f' ?
                                  convertStringCostTiered(row)
                                  :
                                  convertStringCost(row.price.unit_amount_decimal? row.price.unit_amount_decimal : null)
                              }    
                          </td>
                          <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                      </tr>
                  ))}
                  {nextMonthStartDateArray[index] && nextMonthEndDateArray[index] &&
                      <tr className="invoiceTableRow">
                          <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                          {billingCycleDateConverter(nextMonthStartDateArray[index], nextMonthEndDateArray[index])}
                          </td>
                      </tr>
                      }
                      {lineItemsNextMonthArray && lineItemsNextMonthArray[index] && Object.keys(lineItemsNextMonthArray[index]).length > 0 &&
                      lineItemsNextMonthArray[index].map((row) => (
                          <tr key={row.id} className="invoiceTableRow">
                              <td className='invoiceTableCellBody'>{row.description}</td>
                              <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                              <td className='invoiceTableCellBody text-right'>{convertStringCost(row.price.unit_amount_decimal)}</td>
                              <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                          </tr>
                      ))} 
                      <tr className="invoiceTableRow">
                          <td colSpan={2}/>
                          <td className='invoiceTableCellBody text-right'>Subtotal</td>
                          <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.subtotal? upcomingInvoice.subtotal : null)}</td>
                      </tr>
                      {upcomingInvoice.discount &&
                          <tr className="invoiceTableRow">
                              <td colSpan={1}/>
                              {upcomingInvoice.discount.coupon.percent_off != null ?
                              <td colSpan={2} className='invoiceTableCellBody text-right'>{`${upcomingInvoice.discount.coupon.name} (%${upcomingInvoice.discount.coupon.percent_off? upcomingInvoice.discount.coupon.percent_off : null})`}</td>
                              :
                              <td colSpan={2} className='invoiceTableCellBody text-right' >{`${upcomingInvoice.discount.coupon.name} (${convertCost(upcomingInvoice.discount.coupon.amount_off? upcomingInvoice.discount.coupon.amount_off: null)})`}</td>
                              }
                              <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.total_discount_amounts[0].amount)}</td>
                          </tr>
                      }
                      <tr className="invoiceTableRow">
                          <td colSpan={2}/>
                          <td className='invoiceTableCellBody text-right'>Total</td>
                          <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.total? upcomingInvoice.total: null)}</td>
                      </tr>
                      <tr className="invoiceTableRow border-0">
                          <td colSpan={2}/>
                          <td className='invoiceTableCellBody text-right'>Amount due</td>
                          <td className='invoiceTableCellBody text-right'>{convertFinalCosts(upcomingInvoice.amount_due? upcomingInvoice.amount_due: null)}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
        ))
        }
    </div>
  )
}

export default CurrentInvoiceTab