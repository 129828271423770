// import EditApiKeyModal from '../components/modals/ApiKeysModals/EditApiKeyModal';
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import DeleteInstanceModal from '../components/modals/InstanceModals/DeleteInstanceModal';
import EditInstanceModal from '../components/modals/InstanceModals/EditInstanceModal';
import RebootInstanceModal from '../components/modals/InstanceModals/RebootInstanceModal';
import SshInstanceModal from '../components/modals/InstanceModals/SshInstanceModal';
import { Popover } from '@headlessui/react'
import { Auth } from 'aws-amplify';
import toast from 'react-hot-toast';




function InstanceTableRow({el, refreshInstanceList, setOpenFileManager, setSelectedCloudServer}) {
    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return shortcurrentDate  ;
            // return currentStrTime + "  " + shortcurrentDate  ;
        }
    }

    const handleOpenSecureFileTransfer = () => {
        setSelectedCloudServer(el)
        setOpenFileManager(true)
    }
    const handleRestartNodeRed = () => {
        getData(apiLink + '/v1/cloud-servers/'+ el.cloud_server_uuid +'/nodered_restart')
            .then(async response => {
            const data = await response.json();
              if(response.ok ){
                if(data && data.data){
                    // console.log(data.data)
                    toast.success('Node-RED has been restarted')
                }
              }
            });
    }

    const handleConnectNodeRed = () => {
        Auth.currentSession()
        .then(data => {
            window.open(el.cloud_server_url +'/?access_token=' + data.getAccessToken().getJwtToken()  , "_blank");
            
        })
        .catch(err => {
            console.log('err', err)
            toast.error(err.message)
        });
    }

  return (
    <tr key={el.organization_event_id} className='border-b-[0.01em] dark:border-slate-500 '>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
                {el.cloud_server_name}
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
                {el.cloud_server_description}
        </td>
        <td className=' font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.subscription_plan_identifier == "cns-small" ? 
            <div className='text-sm w-[80px] rounded bg-[#dbdbdb] py-0.5 px-1.5 text-center ' >
                <span className='text-xs text-[#636363]' >Small</span>
            </div>
            : null}
            {el.subscription_plan_identifier == "cns-medium" ? 
            <div className='text-sm w-[80px] rounded bg-[#e8ebc0] py-0.5 px-1.5 text-center ' >
                <span className='text-xs text-[#6a7016]' >Medium</span>
            </div>
            : null}
            {el.subscription_plan_identifier == "cns-large" ? 
            <div className='text-sm w-[80px] rounded bg-[#c9dcf4] py-0.5 px-1.5 text-center'>
                <span className='text-xs text=[#0e3261]'>Large</span>
            </div>
            : null}
            {el.subscription_plan_identifier == "ent-cns-medium" ? 
            <div className='text-sm w-[80px] rounded bg-[#e8ebc0] py-0.5 px-1.5 text-center' >
                <span className='text-xs text=[#6a7016]'>Ent-Medium</span>
            </div>
            : null}
            {el.subscription_plan_identifier == "ent-cns-large" ? 
            <div className='text-sm w-[80px] rounded bg-[#c9dcf4] py-0.5 px-1.5 text-center' >
                <span className='text-xs text=[#0e3261]'>Ent-Large</span>
            </div>
            : null}
            {el.subscription_plan_identifier == "ent-cns-cluster" ? 
            <div className='text-sm w-[80px] rounded bg-[#cbf4c9] py-0.5 px-1.5 text-center' >
                <span className='text-xs text=[#0e6245]' >Ent-Clusters</span>
            </div>
            : null}
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
            <Popover className="relative w-full">
                <Popover.Button
                    className={`text-sm w-full px-4 py-1 border-[0.01em] rounded-xl dark:border-blue-400 dark:text-blue-300 text-blue-500 border-blue-500 mx-2 hover:bg-blue-500/10 max-w-[130px]`} 
                >
                    Node-RED
                    {!el.cloud_server_is_ready && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                </Popover.Button>

                <Popover.Panel className="absolute z-10 rounded-lg bg-slate-100 dark:bg-slate-600 flex w-[180px]">
                    <div className="flex flex-col w-full">
                        <button 
                        onClick={handleConnectNodeRed}
                            className='py-2 hover:bg-slate-200 hover:dark:bg-slate-500 rounded-lg'
                        >
                            Node-RED Connect 
                        </button>
                        <button 
                            onClick={handleRestartNodeRed}
                            className='py-2 hover:bg-slate-200 hover:dark:bg-slate-500 rounded-lg'
                        >
                            Restart Node-RED
                        </button>
                    </div>
                </Popover.Panel>
            </Popover>
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
             <SshInstanceModal instance={el} btnType='standardBtn' />
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3  '>
            <button
                disabled={!el.cloud_server_is_ready} 
                className={`relative px-4 w-full py-0.5 border-[0.01em] rounded-xl dark:border-blue-400 border-blue-500 mx-2 hover:bg-blue-500/10 max-w-[190px]`} 
                onClick={handleOpenSecureFileTransfer}
            >
                <span className={`dark:text-blue-300 text-blue-500 text-sm`}>Secure File Transfer</span>
                {!el.cloud_server_is_ready && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
            </button>
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3  '>
        {el.cloud_server_dns_record_name}
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3  '>
        {el.cloud_server_public_ip_address}
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3  '>
        {handleDateFormat(el.cloud_server_created_at)}
        </td>
        <td className='text-base font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            <div className='flex items-center ml-auto justify-end'>
                <div className='w-10'>
                    <EditInstanceModal instance={el} refreshInstanceList={refreshInstanceList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <RebootInstanceModal instance={el} refreshInstanceList={refreshInstanceList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <DeleteInstanceModal instance={el} refreshInstanceList={refreshInstanceList} btnType='inlineIconBtn' />
                </div>

            </div>
        </td>
    </tr>
  )
}

export default InstanceTableRow