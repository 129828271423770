import React, { useState } from 'react'
import useTable from '../components/hooks/useTable';
import DevicesTableRow from './DevicesTableRow';
import TableFooter from "../components/TableFooter";
import AddDeviceModal from '../components/modals/deviceModals/AddDeviceModal';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';

function DevicesTable({data, rowsPerPage, refreshDeviceList, projectUuid}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);

  return (
    <table className="table-auto w-full">
        <thead >
            <tr className='' >
                <th className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' colSpan={4}>
                        Devices
                </th>
                <th className='' colSpan={2}>
                    <div className='ml-auto'>
                        <AddDeviceModal btnType='standardBtn' refreshDeviceList={refreshDeviceList} projectUuid={projectUuid}/>
                    </div>
                </th>
            </tr>
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>{``}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Device Name`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Device Description`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Device Type`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Last Connection Established`}</th>
                <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{``}</th>
            </tr>
        </thead>
          <tbody className=''>
          {selectedOrganization && slice.map((el,index) => (
            <DevicesTableRow el={el} index={index} key={el.device_uuid} refreshDeviceList={refreshDeviceList} selectedOrganization={selectedOrganization} />
          ))}
        </tbody>
        <tfoot>
            <tr className=''>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3' colSpan={6}>
                    <div className='flex justify-end'>
                        <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
                    </div>
                </th>
            </tr>
        </tfoot>
      </table>
  )
}

export default DevicesTable