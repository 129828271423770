import React, { useEffect, useState } from 'react'
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import ApiKeysTable from './ApiKeysTable';

function MainApiKeysPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [apiKeysList, setApiKeysList] = useState([])

    useEffect(() => {
        if(selectedOrganization){
            refreshClientList()
        }
    },[selectedOrganization])

    const refreshClientList = () => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/clients')
            .then(async response => {
            const data = await response.json();
              if(response.ok ){
                if(data && data.data){
                    console.log(data.data)
                    setApiKeysList(data.data)
                }
              }
            });

        }
      }

  return (
    <div className={`overflow-scroll scrollbar-hide max-h-[calc(100vh-5rem)] `}> 
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
            <ApiKeysTable data={apiKeysList} rowsPerPage={50} refreshClientList={refreshClientList} />
        </div>
    </div>
  )
}

export default MainApiKeysPage