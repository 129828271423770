import React from 'react'

function LogTableRow({el}) {

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }


  return (
    <tr key={el.organization_event_id} className='border-b-[0.01em] dark:border-slate-500'>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
                {el.organization_event_description}
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            {handleDateFormat(el.organization_event_timestamp)}
        </td>
    </tr>
  )
}

export default LogTableRow