import PaymentImagesJSON from './PaymentImagesJSON';
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { deleteData, postData } from '../ApiCalls/DataApis';
import toast from 'react-hot-toast';
import { apiLink } from '../ApiCalls/ApisVariables';

function CardInfoRow({card,index, defaultPayment, getCustomerBilling, selectedOrganization, getPaymentCards}) {
    const [showDeleteCard, setShowDeleteCard] = useState(false)
    const [loadingChangeCardDefault, setLoadingChangeCardDefault] = useState(false)
    const [loadingDeleteCard, setLoadingDeleteCard] = useState(false)


    const handleSetCardAsDefault = () => {
        try {
            if(!loadingChangeCardDefault){
                setLoadingChangeCardDefault(true)
                postData(apiLink + '/v1/organizations/' + selectedOrganization + '/payment_methods/card/default', 
                {'payment_method': card.id})
                .then(async response => {
                    if(response.ok){
                    setLoadingChangeCardDefault(false)
                    getCustomerBilling()
                    toast.success('Card has been set to default')
                    }
                    else{
                    try{
                        const data = await response.json();
                        toast.error(data.message)
                    }catch (e){
                        toast.error('Something went wrong')
                    }
                    }
                })

            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleSubmitDeleteCard = () => {
        try {
            if(!loadingDeleteCard){
                setLoadingDeleteCard(true)
                deleteData(apiLink + '/v1/organizations/' + selectedOrganization + '/payment_methods/card',
                {payment_method: card.id})
                .then(async response => {
                    if(response.ok){ 
                    // const data = await response.json();
                    setLoadingDeleteCard(false)
                    toast.success('Card has been removed')
                    getPaymentCards()
                    }
                    else{
                    try{
                        const data = await response.json();
                        toast.error(data.message)
                        setLoadingDeleteCard(false)
                    }catch (e){
                        toast.error('Something went wrong')
                    }
                    }
                })
            } 
        } catch (error) {
            console.log(error.message)
        }

    }

    // This is to fix the stupid overlapping issues when clicking the top right edit button
    const style = {
        zIndex: 50-index
    }


  return (
        <div className='relative shadow-iotflows-lg rounded-lg dark:bg-slate-800 w-full px-4 py-2'>
            {/* Dropdown button for delete and set as default */}
            <div className='absolute top-1 right-0 ' style={style}>
                <Menu as="div" className="relative text-left">
                    <div>
                    <Menu.Button className="inline-flex w-full justify-center text-center rounded-full bg-transparent p-0.5 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 " aria-hidden="true" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </Menu.Button>
                    </div>
                    <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    >
                    <Menu.Items className="absolute right-0 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } relative group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={handleSetCardAsDefault}
                                    disabled={loadingChangeCardDefault}
                                >
                                  Set as default
                                  {loadingChangeCardDefault && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                    }
                                </button>
                                )}
                            </Menu.Item>
                            {/* <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={()=> setShowDeleteCard(true)}
                                >
                                    {active ? (
                                    <DeleteActiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <DeleteInactiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Delete
                                </button>
                                )}
                            </Menu.Item> */}
                        </div>
                    </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            {!showDeleteCard ? (

                <div className='flex items-center'>
                <div className='mr-4'>
                    <img className='h-5 max-w-[45px]' src={PaymentImagesJSON[card.card.brand]?.src} alt={PaymentImagesJSON[card.card.brand]?.description}/> 
                </div>
                <div className='flex flex-col'>
                    <div className='flex text-gray-600 dark:text-gray-300 items-center '>
                    {`Card ending in ${card?.card?.last4}`}&nbsp;&nbsp;
                        {card.id == defaultPayment ? 
                            <div className='mb-0.5'>
                            <span className='pl-2 text-xs px-2 py-0.5 rounded bg-blue-200 text-blue-800 dark:bg-blue-800 dark:text-blue-200'>Default</span>
                            </div>
                        : null
                        }
                    </div>
                    <div className='text-sm text-gray-400 dark:text-gray-400'>
                    {`Expires ${card.card?.exp_month}/${card.card?.exp_year}`}
                    </div>
                </div>
                </div>
            ):(
                <div className='flex ml-2 items-center my-1'>
                    <span className={`text-base text-left font-medium dark:text-gray-300 text-gray-600`}>Are you sure you want to delete card?</span>
                    <button className={`shadow rounded-md py-1 px-2 border-[1px] text-sm dark:text-white dark:hover:text-gray-200 dark:border-gray-400 text-gray-600 hover:text-gray-900 border-gray-300 cursor-pointer flex ml-2`} onClick={() => setShowDeleteCard(false)}>
                        Cancel
                    </button>
                    <button 
                        disabled={loadingDeleteCard}
                        className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-sm bg-red-600 hover:bg-red-700 hover:border-red-700 text-white cursor-pointer`} 
                        onClick={handleSubmitDeleteCard}
                    >
                        Delete
                        {loadingDeleteCard && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                        }
                    </button>
                </div>

            )}
              
            
          </div>
  )
}

export default CardInfoRow



function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#94a3b8"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#94a3b8" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#94a3b8" strokeWidth="2" />
      </svg>
    )
  }
  
  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#a5b4fc"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#a5b4fc" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#a5b4fc" strokeWidth="2" />
      </svg>
    )
  }