import React, { useEffect, useState } from 'react'
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import LogsTable from './LogsTable';

function MainLogsPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [logsList, setLogsList] = useState([])

    useEffect(() => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/events')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                // console.log('data logs', data)
                if(data && data.data){
                    setLogsList(data.data)
                }
              }
              
            });
        }
    },[selectedOrganization])

  return (
    <div className={`overflow-scroll scrollbar-hide max-h-[calc(100vh-5rem)] mx-auto max-w-[1600px]`}> 
        <div className='w-full mt-2 p-4 overflow-scroll bg-slate-50 dark:bg-slate-800 rounded-xl '>
            {logsList && Object.keys(logsList).length > 0 &&
            <LogsTable data={logsList} rowsPerPage={50} />
            }
        </div>
    </div>
  )
}

export default MainLogsPage