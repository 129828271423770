import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import GeneralFormInput from '../../forms/GeneralFormInput';
import { getData, postData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';





function CreateApiKeyModal({btnType, refreshInstanceList}) {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const isDark = useRecoilValue(darkModeState);
    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(false)
    let [loadingKey, setLoadingKey] = useState(false)
    const [form, setForm] = useState({
      newKeyName: '',
      newKeyDescription: '',
      instanceName: '',
      instanceDescription: '',
      cloudServerSubdomain: '',
    })
    const [cloudServerType, setCloudServerType] = useState('');
    const [organizationKeys, setOrganizationKeys] = useState([]);
    const [selectedOrganizationKey, setSelectedOrganizationKey] = useState([]);
    const [pemFileAcknowledged, setPemFileAcknowledged] = useState(false);


  function closeModal() {
    setIsOpen(false)
    setForm({newKeyName: '',newKeyDescription:''})
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if(selectedOrganization){
      refreshOrgKeys()
    }
  }, [selectedOrganization])

  const refreshOrgKeys = () => {
    if(selectedOrganization){
      getData(apiLink + '/v1/organizations/' + selectedOrganization + '/keys')
      .then(async response => {
      const data = await response.json();
        if(response.ok ){
          if(data && data.data){
              // console.log('org Keys', data.data)
              setOrganizationKeys(data.data)
          }
        }
      });
    }

  }



  // **** Functions for General Input forms **** 
  const handleGeneralFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setForm(updatedForm);

  }
  // **** Functions for Input Department ****
  
  
  const submitLaunchServer = () => {
    try {
      if(!loading){
        setLoading(true)
        
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/cloud-servers', 
        {'cloud_server_name': form.instanceName,
          'cloud_server_description': form.instanceDescription,
          'cloud_server_subscription_identifier': cloudServerType,
          'cloud_server_subdomain': form.cloudServerSubdomain,
          'key_uuid': selectedOrganizationKey.key_uuid})
        .then(async response => {
          const data = await response.json();
          if(response.ok){
            setLoading(false)
            toast.success('Cloud Node-RED Server created!')
            refreshInstanceList()
            closeModal()
            setTimeout(() => {
              toast("It may take up to 5 minutes to spin up and configure server", { duration: 4000, })
            },5000);
            
          }
          else{
            try{
              const data = await response.json();
              toast.error(data.message)
              setLoading(false)
            }catch (e){
              setLoading(false)
              console.log('error')
            }
          }
        }) 
      }
    } catch (error) {
      console.log(error.message)
    }

  }

  const downloadKey = () => {
    if (!loadingKey) {
      setLoadingKey(true)
      postData(apiLink + '/v1/organizations/' + selectedOrganization + '/keys' , 
        {'key_name': form.newKeyName,
          'key_description': form.newKeyDescription,
        })
        .then(async response => {
          if(response.ok){
            const data = await response.json();

            var keyPrivate = data.data.key_private;
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text;charset=utf-8,' + encodeURI(keyPrivate);
            hiddenElement.target = '_blank';
            hiddenElement.download = data.data.key_name +'.pem';
            hiddenElement.click();
            refreshOrgKeys();
            
            let newOrganizationKey = 
            [{'key_name': data.data.key_name,
            'key_description': data.data.key_description,
            'key_uuid': data.data.key_uuid
            }]

            toast.success("SSH Key Downloaded");
            setLoadingKey(false)
            setPemFileAcknowledged(true)
            setSelectedOrganizationKey(newOrganizationKey[0])
          }
          else{
            try{
              const data = await response.json();
              toast.error(data.message);
              setLoadingKey(false)
            }catch (e){
              toast.error('Something went wrong');
              setLoadingKey(false)
            }
          }
        })
    }
  };

  const handleBackServerType = () => {
    setCloudServerType('')
    setSelectedOrganizationKey([])
    setPemFileAcknowledged(false)
  }

  const handleBackToCreateDiv = () => {
    setSelectedOrganizationKey([])
    setPemFileAcknowledged(false)
    setForm({
      newKeyName: '',
      newKeyDescription: '',
      instanceName: '',
      instanceDescription: '',
      cloudServerSubdomain: '',
    })
  }

  const handleChangeOrgKey = (e) => {
    console.log('e.target.value', e.target.value)
    if(e.target.value == 'createNewKey'){
      setSelectedOrganizationKey(e.target.value)  
    }else if(e.target.value && Object.keys(e.target.value).length > 0){
      setSelectedOrganizationKey(organizationKeys[e.target.value])
    }else {
      setSelectedOrganizationKey([])  
    }
  }
  const handleRadioChange = (event) => {
    setPemFileAcknowledged(event.target.checked)
  }


  return (
    <>
      <div className="flex items-center justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>Create Server</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <svg className='text-blue-600 w-4 h-4 mt-auto mb-0.5' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
          <span className=' text-blue-600'>Add</span>
      </motion.button>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-3xl transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                        Create Cloud Node-RED Server
                      </div>
                  <AnimatePresence mode='wait'>
                    {cloudServerType == '' &&
                      <motion.div
                        key='selectCloudServerTypeDiv'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        >
                          <div className='flex flex-col px-4'>
                            <div className={`mb-1 p-4 text-lg font-medium leading-6 text-center  ${isDark?'text-gray-400 ':'text-gray-600'}`}>
                              Select Cloud Server Type
                            </div>
                            <div className='flex space-x-4 px-4 pb-2' >
                                  <motion.button  
                                    whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}
                                    className={`w-full py-5 px-2 rounded-xl text-2xl ${isDark?'bg-slate-600':'bg-slate-100'}`} 
                                    onClick={()=> setCloudServerType('cns-small')} 
                                  >
                                    <h4 className='text-center text-blue-500' >Small</h4>
                                  </motion.button>
                                  <motion.button  
                                    whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}
                                    className={`w-full py-5 px-2 rounded-xl text-2xl ${isDark?'bg-slate-600':'bg-slate-100'}`} 
                                    onClick={()=> setCloudServerType('cns-medium')}
                                  >
                                    <h4 className='text-center text-blue-500'>Medium</h4>
                                  </motion.button>
                                  <motion.button  
                                    whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}
                                    className={`w-full py-5 px-2 rounded-xl text-2xl ${isDark?'bg-slate-600':'bg-slate-100'}`} 
                                    onClick={()=> setCloudServerType('cns-large')}
                                  >
                                    <h4 className='text-center text-blue-500'>Large</h4>
                                  </motion.button>
                              </div>

                          </div>
                          <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                              <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                              >
                                Close
                              </button>
                            </div>
                          
                        </motion.div>
                    }
                    {cloudServerType && cloudServerType.length > 0 && (Object.keys(selectedOrganizationKey).length == 0 || !pemFileAcknowledged )&&
                      <motion.div
                        key='selectCreateKeyDiv'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className='flex flex-col px-4 pt-2'>
                          <div className={`text-base cursor-pointer ${isDark?'text-blue-400 ':'text-blue-500'}`} onClick={handleBackServerType} >
                            <h5 className='flex uppercase'>
                              <b>Cloud Node-RED Server:&nbsp;</b>
                              {cloudServerType === "cns-small" ? 'Small' : null}
                              {cloudServerType === "cns-medium" ? 'Medium':null}
                              {cloudServerType === "cns-large" ? 'Large' : null}
                              {cloudServerType === "ent-cns-medium" ? 'Enterprise Medium':null}
                              {cloudServerType === "ent-cns-large" ? 'Enterprise Large' : null}
                            </h5>
                          </div>
                          <div className={`mb-1 mt-2 px-4 pb-4 pt-2 text-lg font-medium leading-6 text-center  ${isDark?'text-gray-400 ':'text-gray-600'}`}>
                            Select an existing key or create a new key
                          </div>
                          <div className={`text-left text-sm ${isDark?'text-gray-400 ':'text-gray-600'}`}>
                            <p> A pair key consists of a public key that will be stored in the server and a private key that you store on your computer. This private key allows you to connect to your instance securely. Please keep it in a safe location</p>
                          </div>
                          <div className="flex justify-center mt-4">
                              <div className="my-3 w-full outline-none">
                                  <select  onChange={handleChangeOrgKey}  className={`form-select form-select-sm shadow-iotflows-lg appearance-none block w-full ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'}
                                  px-2 py-1 text-sm font-normal bg-clip-padding bg-no-repeat border-1 rounded
                                  transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm`}>
                                      <option  value={``}  >{``}</option>
                                      <option  value={`createNewKey`}  >{`+ CREATE NEW KEY`}</option>
                                      {organizationKeys.map((currentKey, index) => (
                                          <option key={currentKey.key_uuid} value={index}  >{currentKey.key_name}</option>
                                      ))}
                                  </select>
                              </div>
                          </div>  
                          {selectedOrganizationKey === 'createNewKey' ? (
                            <motion.div
                              key='createNewKeyDiv'
                              initial={{ y: 10, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              exit={{ y: -10, opacity: 0 }}
                              transition={{ duration: 0.2 }}
                            > 
                                <div className='mb-4'>
                                  <div className="pt-2">
                                    <GeneralFormInput label='Key Name' type='text' placeholder='' name='newKeyName' onChange={handleGeneralFormChange} />
                                  </div>
                                  <div className="pt-4">
                                    <GeneralFormInput label='Key Description' type='text' placeholder='' name='newKeyDescription' onChange={handleGeneralFormChange} />
                                  </div>
                                  <div className='text-right mt-2'>
                                    <button className={`pt-2 pb-4 px-2 disabled:opacity-50 ${isDark? 'text-blue-600':'text-blue-500'}`} disabled={loadingKey || (form.newKeyName == '') } onClick={downloadKey}>
                                      {'Download Key'} 
                                      {loadingKey && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                      }
                                    </button>
                                  </div>
                                  <div className='pt-4'>
                                    <Alert severity="info" sx={{opacity:isDark && '0.8'}} >
                                      <div >
                                        You have to download the <strong>private key file</strong> (*.pem file) before you can continue.<strong> Store it in a secure and accessible location.</strong> You will not be able to download the file again after it's created.
                                      </div>
                                    </Alert> 
                                  </div>
                                </div>
                            </motion.div>
                          ):(
                            <div className='pt-2'>
                              <FormGroup row>
                                <FormControlLabel 
                                  control={
                                    <Checkbox
                                      checked={pemFileAcknowledged}
                                      onChange={handleRadioChange}
                                      name="acknowledged"
                                      color="primary"
                                      sx={{color:isDark? '#9ca3af': '#4b5563'}}
                                    />
                                  }
                                  sx={{color:isDark? '#9ca3af': '#4b5563'}}
                                  label="I acknowledge that I have access to the selected private key file (*.pem), and that without this file, I won't be able to log into my server " 
                                  />
                              </FormGroup>
                            </div>
                          )
                          }
                          
                        </div>
                        <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                              type="button"
                              className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                              onClick={closeModal}
                            >
                              Close
                            </button>
                          </div>
                        
                      </motion.div>
                    }
                    {cloudServerType && cloudServerType.length > 0 && pemFileAcknowledged && selectedOrganizationKey && Object.keys(selectedOrganizationKey).length > 0 &&
                      <motion.div
                        key='serverNameDescDomainDiv'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className='flex flex-col px-4 pt-2'>
                          <div className={`text-base cursor-pointer ${isDark?'text-blue-400 ':'text-blue-500'}`} onClick={handleBackToCreateDiv} >
                            <h5 className='flex uppercase'>
                              <b>Cloud Node-RED Server:&nbsp;</b>
                              {cloudServerType === "cns-small" ? 'Small' : null}
                              {cloudServerType === "cns-medium" ? 'Medium':null}
                              {cloudServerType === "cns-large" ? 'Large' : null}
                              {cloudServerType === "ent-cns-medium" ? 'Enterprise Medium':null}
                              {cloudServerType === "ent-cns-large" ? 'Enterprise Large' : null}
                            </h5>
                            <h5 className='flex uppercase mt-1'>
                              <b>Organization Key:&nbsp;</b>{selectedOrganizationKey.key_name}
                            </h5>
                          </div> 
                          <div className='mb-4'>
                            <div className="pt-2">
                              <GeneralFormInput label='Server Name' type='text' placeholder='' name='instanceName' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                              <GeneralFormInput label='Server Description' type='text' placeholder='' name='instanceDescription' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                              <GeneralFormInput label='Server Sudomain' type='text' placeholder='' name='cloudServerSubdomain' onChange={handleGeneralFormChange} />
                            </div>
                          </div>
                          
                        </div>
                        <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                            <button
                              type="button"
                              className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                            <button
                            type="submit"
                            className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                            onClick={submitLaunchServer}
                            disabled={loading || (form.instanceName == '') || (form.cloudServerSubdomain == '') || selectedOrganizationKey.key_uuid == '' || !pemFileAcknowledged }
                          >
                            Launch Server
                            {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                          </button>
                          </div>
                        
                      </motion.div>
                    }

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CreateApiKeyModal