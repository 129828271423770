import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import { BrowserRouter } from "react-router-dom";
import {RecoilRoot} from 'recoil';
import CredentialsRouting from './CredentialsRouting';


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        {/* <App /> */}
        <CredentialsRouting />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);



