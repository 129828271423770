import { useState } from 'react'
import { useEffect } from 'react'
import ImageZoomCrop from '../components/modals/ImageZoomCrop'
import { deleteData, putData } from '../ApiCalls/DataApis'
import toast from 'react-hot-toast';
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from '../ApiCalls/ApisVariables'


function OrgPage({orgList,user,  updateOrganizationsList}) {
    const [showGODview, setShowGODview] = useState(false)
    const [loadingOrgInfo, setLoadingOrgInfo] = useState(false)
    const [editOrg, setEditOrg] = useState(false)
    const [orgName, setOrgName] = useState('')
    const [orgDescription, setOrgDescription] = useState('')
    const [orgImage, setOrgImage] = useState(null)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [loadingDeleteOrg, setLoadingDeleteOrg] = useState(false)
    const [loadingLeaveOrg, setLoadingLeaveOrg] = useState(false)
    const [deleteOrg, setDeleteOrg] = useState(false)
    const [leaveOrg, setLeaveOrg] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        // Determining if user had GOD view access
        getData(apiLink + '/v1/users/iotflows')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
              setShowGODview(true)
            } 
          }
        });
  
      }, [])




    useEffect(() => {
        if(selectedOrganization && orgList && Object.keys(orgList).length > 0){
          orgList.map(org => {
            if(org.organization_uuid == selectedOrganization){
                setOrgName(org.organization_name)
                setOrgDescription(org.organization_description)
                setOrgImage(org.organization_logo_url)
            }
          })
        }
        
      },[selectedOrganization, orgList])



  const updateOrgInfo = () => {
    try {
        if(!loadingOrgInfo){
            setLoadingOrgInfo(true)
            putData(apiLink + '/v1/organizations/' + selectedOrganization, 
            {'organization_name': orgName,
            'organization_description': orgDescription,
            'organization_is_private': true})
            .then(async response => {
                if(response.ok){
                toast.success('Organization modified')
                setLoadingOrgInfo(false)
                updateOrganizationsList()
                setEditOrg(false)
                }
                else{
                try{
                    const data = await response.json();
                    toast.error(data.message); 
                    setEditOrg(false) 
                }catch (e){
                    toast.error('Something went wrong');
                    setEditOrg(false)
                }
                }
            })
        }
    } catch (error) {
        console.log(error.message)
        setLoadingOrgInfo(false)
    }
    
  }

    const submitDeleteOrg = () =>{
        // Delete organization API
        try {
            if(!loadingDeleteOrg){
                setLoadingDeleteOrg(true)
                deleteData(apiLink + '/v1/organizations/' + selectedOrganization)
                .then(async response => {
                    if(response.ok){
                    updateOrganizationsList()
                    setLoadingDeleteOrg(false)
                    toast.success(`${orgName} organization deleted`)
                    navigate('/')
                    }
                    else{
                    try{
                        const data = await response.json();
                        toast.error(data.message)
                        setLoadingDeleteOrg(false)
                    }catch (e){
                        toast.error('Something went wrong')
                        setLoadingDeleteOrg(false)
                    }
                    }
                })
                
            }
        } catch (error) {
            // console.log(error.message)
            toast.error(error.message)
            setLoadingDeleteOrg(false)
        }
    }
    const submitLeaveOrg = () =>{
        // Leave organization API
        try {
        if(!loadingLeaveOrg && user && user.user_username){
            setLoadingLeaveOrg(true)
            deleteData(apiLink + '/v1/organizations/' + selectedOrganization + '/members', 
            {'member_username': user.user_username,
            'delete_mode': 'delete_safe'})
            .then(async response => {
                if(response.ok){
                updateOrganizationsList()
                setLoadingLeaveOrg(false)
                toast.success(`You left ${orgName} organization`)
                navigate('/')
                }
                else{
                try{
                    const data = await response.json();
                    toast.error(data.message)
                    setLoadingLeaveOrg(false)
                }catch (e){
                    toast.error('Something went wrong')
                    setLoadingLeaveOrg(false)
                }
                }
            })
            
        }
        } catch (error) {
        // console.log(error.message)
        toast.error(error.message)
        setLoadingLeaveOrg(false)
        }
    }
    

  return (
    <div className={` py-1 max-h-[calc(100vh-5rem)] mx-auto overflow-scroll scrollbar-hide`}>
        <AnimatePresence mode='wait'>
        <motion.div 
            key='orgPage'
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4"
        >
            <div className='flex justify-between border-b-[1px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <span className='text-xl font-bold text-gray-800 dark:text-gray-300'>Organization</span>
                {editOrg ? (
                    <div className='flex space-x-2'>
                        <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditOrg(false)}>
                            <span >Cancel</span>
                        </button>
                        <button 
                            className='relative flex text-sm text-white bg-blue-600 dark:text-gray-100 items-center border-[1px] border-blue-600 dark:border-blue-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' 
                            onClick={updateOrgInfo}
                            disabled={loadingOrgInfo}
                            >
                            <span >Save</span>
                            {loadingOrgInfo && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                              }
                        </button>
                    </div>

                ):(
                <button className='flex text-sm text-gray-600 dark:text-gray-400 items-center border-[1px] border-gray-300 dark:border-gray-600 rounded-lg shadown-iotflows-lg px-2 py-0.5' onClick={() => setEditOrg(true)}>
                    <span className='mr-1'>Edit</span>
                    <svg className='w-4 h-4' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>

                </button>
                )}
            </div>

            <div className='flex flex-col space-y-4 mt-4 text-base ml-2'>
                <div className='flex grow justify-center sm:justify-start'>
                    <ImageZoomCrop 
                        title="Organization logo"
                        isDisabled={editOrg} 
                        isRounded={false} 
                        currentPicture={orgImage} 
                        submitType={'organizationLogo'}
                        updateInfo={updateOrganizationsList}
                        uuid={selectedOrganization}
                    />
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Organization name</div>
                    <div className='flex-1'>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm
                                        focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                                        dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                                        placeholder:text-gray-400 text-gray-600 border-gray-200 
                                        disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent `} 
                            id={'orgName'} 
                            type={'text'}
                            name={'orgName'}
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            disabled={!editOrg}
                            />
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Organization description</div>
                    <div className='flex-1 '>
                        <input 
                            className={`bg-transparent w-full max-w-[300px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm
                            focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                            dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                            placeholder:text-gray-400 text-gray-600 border-gray-200 
                            disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                            id={'orgDescription'} 
                            type={'text'}
                            name={'orgDescription'}
                            value={orgDescription}
                            onChange={(e) => setOrgDescription(e.target.value)}
                            disabled={!editOrg}
                            />
                    </div>
                </div>
                {showGODview && 
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Delete entire organization</div>
                    <div className='mx-auto mt-2 sm:flex-1 sm:mt-0'>
                    {!deleteOrg ? (
                        <motion.div 
                            key='deleteOrgSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='flex items-center'
                            >
                        {/* <LightTooltip TransitionComponent={Zoom} title="Delete entire organization" arrow > */}
                            <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border dark:border-red-900 dark:text-red-600 dark:hover:text-red-400 dark:hover:bg-transparent  border-red-100 text-red-600 hover:bg-red-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={() => setDeleteOrg(true)}
                            >
                            Delete
                            </button>
                        {/* </LightTooltip> */}
                        </motion.div>
                    ):(
                        <motion.div
                            key='deleteOrgConfirm'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className='flex items-center'
                            >
                                <div className='px-4 my-auto'>
                                    <div className={`text-gray-600 dark:text-gray-400 text-sm font-bold`}>
                                    Are you sure you want to delete entire {orgName} organization?
                                    </div>
                                </div> 
                                <button
                                    type="button"
                                    className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-gray-300 border-gray-100 text-gray-600 hover:bg-gray-50 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                    onClick={() => setDeleteOrg(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={`relative inline-flex rounded-md ml-2 bg-transparent border dark:border-slate-600 dark:text-red-400 dark:hover:bg-red-400 dark:hover:bg-opacity-10 border-gray-100 text-red-600 hover:bg-red-100 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                    onClick={submitDeleteOrg}
                                    disabled={loadingDeleteOrg}
                                >
                                    Confirm Delete
                                    {loadingDeleteOrg && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </button>
                            </motion.div>
                    )}
                    </div>
                </div>
                }
                <div className='flex flex-col sm:flex-row'>
                    <div className='grow max-w-[320px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium'>Leave organization</div>
                    <div className='mx-auto mt-2 sm:flex-1 sm:mt-0'>
                        {!leaveOrg ? (
                            <motion.div 
                                key='leaveOrgSection'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                >
                            {/* <LightTooltip TransitionComponent={Zoom} title="Delete entire organization" arrow > */}
                                <button
                                type="button"
                                className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-600 border-gray-100 text-gray-600 hover:bg-gray-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={() => setLeaveOrg(true)}
                                >
                                Leave&nbsp;
                                </button>
                            {/* </LightTooltip> */}
                            </motion.div>
                        ):(
                            <motion.div
                                key='leaveOrgConfirm'
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className='flex items-center'
                                >
                                    <div className='px-4 my-auto'>
                                        <div className={`text-gray-600 dark:text-gray-400 text-sm font-bold`}>
                                        Are you sure you want to leave {orgName} organization?
                                        </div>
                                    </div> 
                                    <button
                                        type="button"
                                        className={`inline-flex rounded-md bg-transparent border dark:border-slate-600 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-gray-300 border-gray-100 text-gray-600 hover:bg-gray-50 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                        onClick={() => setLeaveOrg(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className={`relative inline-flex rounded-md ml-2 bg-transparent border  dark:border-slate-600  dark:text-gray-400  dark:bg-gray-700  dark:hover:bg-gray-600 border-slate-100 text-gray-800 bg-gray-100 hover:bg-gray-200 px-4 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                        onClick={submitLeaveOrg}
                                        disabled={loadingLeaveOrg}
                                    >
                                        Confirm Leave
                                        {loadingLeaveOrg && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                        }
                                    </button>
                                </motion.div>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
        </AnimatePresence>
    </div>
  )
}

export default OrgPage