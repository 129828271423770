import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import { putData, updateUserPhoneNumber, verifyUserPhoneNumber } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';
import { apiLink } from '../../ApiCalls/ApisVariables';
import GeneralFormInput from '../forms/GeneralFormInput';

function EditPhoneNumberModal({remoteOpenModal, handleEditPhoneNumberModal, updateInfo}) {
    const [isOpen, setIsOpen] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [unEditedPhoneNumber, setUnEditedPhoneNumber] = useState('')
    const [loadingNewPhone, setLoadingNewPhone] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const [verifyNumber, setVerifyNumber ] = useState(false)
    const [form, setForm] = useState({
      verificationCode: '',
    })
    const [loadingVerificationCode, setLoadingVerificationCode] = useState(false)

    function openModal() {
      setIsOpen(true)
    }
  
    function closeModal() {
      setIsOpen(false)
    }

    useEffect(() => {
      if(remoteOpenModal){
        setIsOpen(true)
      }
      
    },[remoteOpenModal])


    const submitNewPhoneNumber = () => {
      try {
        if(!loadingNewPhone){
          setLoadingNewPhone(true)
          let unedited_phone_number = '+' + phoneNumber
          setUnEditedPhoneNumber(unedited_phone_number)
          updateUserPhoneNumber(unedited_phone_number)
            .then(async response => {
            if(response == "SUCCESS"){
              setLoadingNewPhone(false)
              setVerifyNumber(true)
              // this.setState({
              //   loadingPhoneNumber: false,
              //   successPhoneNumber: true,
              //   verificationCodeModalOpen: true,
              // })
              // this.props.showSuccessSnackbar("Phone number update request sent successfully.");
            }        
            else
            {
              toast.error('Phone number update request failed.')
  
            }
          })
        }
        
      } catch (error) {
        toast.error(error.message)
      }

    }


  // **** Functions for General Input forms **** 
  const handleGeneralFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setForm(updatedForm);

  }
  // **** Functions for Input Department **** 

  const handleBackButton = () => {
    setVerifyNumber(false)
    setPhoneNumber('')
    setUnEditedPhoneNumber('')
  }

  const submitVerificationCode = () => {
    try {
      if(!loadingVerificationCode){
        setLoadingVerificationCode(true)

        // Verifing code functions
          // Verify the code      
        verifyUserPhoneNumber(form.verificationCode).then(async response => {     
          if(response == "SUCCESS"){
            // Update the phone number in IoTFlows DB if phone verified
            putData(apiLink + '/v1/users/phone_number', 
            {'phone_number': unEditedPhoneNumber})
              .then(async response => {
                if(response.ok){   
                  toast.success('Phone number updated successfully') 
                  setLoadingVerificationCode(false) 
                  setVerifyNumber(false)
                  setPhoneNumber('')
                  setUnEditedPhoneNumber('')   
                  updateInfo()
                  handleEditPhoneNumberModal()   
                }
                else{
                  try{
                    const data = await response.json();
                    toast.error(data.message) 
                    setLoadingVerificationCode(false) 
                  }catch (e){
                    toast.error('Something went wrong') 
                  }
                }
              })
            }
            else
            {
              toast.error('Phone number verification failed') 
              setLoadingVerificationCode(false) 
            }          
          }
        ).catch( e => {
          toast.error('Phone number verification failed') 
          setLoadingVerificationCode(false) 
        })

      }
      
    } catch (error) {
      console.log(error.message)
    }

  }

  return (
    <div>
        {/* <div className="flex items-center justify-center">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Add Sensor</span>
          </motion.button>
      </div> */}

        <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-[350px] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Update phone number
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                  {!verifyNumber ? (
                    <motion.div
                        key='updatePhoneNumber'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='w-full'
                      > 
                        <div className='px-4 py-4'>
                          <PhoneInput
                            country={'us'}
                            value={phoneNumber}
                            onChange={phone => setPhoneNumber(phone)}
                            inputProps={{
                              required: true,
                              autoFocus: true
                            }}
                            />
                        </div>
                        <form >
                          <div className={`flex py-4 px-4 items-center border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                <button
                                  type="button"
                                  className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button
                                  type='submit'
                                  className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                  onClick={submitNewPhoneNumber}
                                  disabled={phoneNumber == '' || loadingNewPhone}
                                >
                                  {'Submit'}
                                  {loadingNewPhone && 
                                      <div >
                                          <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark?' fill-white text-gray-500 ':' fill-blue-600 text-gray-300 '}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                          </svg>
                                      </div>
                                    }
                                </button>
                          </div>
                      </form>
                        
                          
                      </motion.div>
                  ):(
                    <motion.div
                        key='verifyPhoneNumber'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='w-full'
                      > 

                        <div className='px-4 pb-4 pt-2'>
                          <GeneralFormInput label='Verification code' type='text' placeholder='' name='verificationCode' onChange={handleGeneralFormChange} />
                        </div>

                        <form>
                          <div className={`flex py-4 px-4 items-center border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                <button
                                  type="button"
                                  className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                  onClick={handleBackButton}
                                >
                                  Back
                                </button>
                                <button
                                  type='submit'
                                  className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                  onClick={submitVerificationCode}
                                  disabled={form.verificationCode == '' || loadingVerificationCode}
                                >
                                  {'Verify'}
                                  {loadingVerificationCode && 
                                      <div >
                                          <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark?' fill-white text-gray-500 ':' fill-blue-600 text-gray-300 '}`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                          </svg>
                                      </div>
                                    }
                                </button>
                          </div>
                        </form>
                      </motion.div>

                  )}

                      


                  </AnimatePresence>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default EditPhoneNumberModal