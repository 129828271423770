import Header from "./components/Header";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {darkModeState} from './atoms/darkModeState'
import {addOrgModalState} from './atoms/modalStates'
import { useEffect } from "react";
import { getData } from "./ApiCalls/DataApis";
import { apiLink } from "./ApiCalls/ApisVariables";
import {userInfo} from './atoms/userStates'
import { Toaster } from 'react-hot-toast';
import ErrorPage from "./ErrorPage";
import { globalSelectedOrganization } from "./atoms/orgStates";
// import MqttService from './core/services/MqttService';
import IoTFlowsLogoLightTransparent from "./images/IoTFlowsLogoLightTransparent.png";
import MainProjectsListPage from "./MyProjectsPage/MainProjectsListPage";
import LeftSideNav from "./components/LeftSideNav";
import MainLogsPage from "./MyLogsPage/MainLogsPage";
import MainApiKeysPage from "./MyApiKeys/MainApiKeysPage";
import MainInstancesPage from "./MyInstancesPage/MainInstancesPage";
import MainSettingPage from "./MySettingsPage/MainSettingPage";
import ProfilePage from "./MySettingsPage/ProfilePage";
import BillingPage from "./MySettingsPage/BillingPage";
import SelectedProjectList from "./MyProjectsPage/SelectedProjectList";
import MainSelectedDevicePage from "./MyDevicesPage/MainSelectedDevicePage";
import AddOrgModal from "./components/modals/organizationModals/AddOrgModal";



const MINUTE_MS = 60000;

function App() {
  const [isDark, setIsDark] = useRecoilState(darkModeState)
  const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useRecoilState(addOrgModalState);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [orgList, setOrgList] = useState([])
  const [user, setUser] = useRecoilState(userInfo)
  const [mqttMessage, setMqttMessage] = useState([])
  const [justRefreshed, setJustRefreshed] = useState(false)
  const [refreshAfterTimer, setRefreshAfterTimer] = useState(false)
  const [orgCalled, setOrgCalled] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let location = useLocation();
  

  useEffect(() => {
    
    updateUserInformation()
    updateOrganizationsList()

  }, [])


  useEffect(() => {
    
    if(orgCalled && user && Object.keys(user).length > 0){
      if(Object.keys(orgList).length === 0 ){
          // New user with a free tier and no organizations 
          setIsAddOrgModalOpen(true)
        }
    }
    

  }, [user, orgCalled])

  

  

  const updateUserInformation = () => {
    
    let currentSearch = location.search

    getData(apiLink + '/v1/users' + currentSearch)
    .then(async response => {
      if(response && response.ok ){
        const data = await response.json();
        if(data && data.data.length > 0 && data.data[0]){
          setUser(data.data[0])
          if(data.data[0].user_prefers_dark_mode){
            setIsDark(true)
          }else{
            setIsDark(false)
          }
        } 
      }
    });
  }

  const updateOrganizationsList = () => {
    let currentSearch = location.search
    try {
      getData(apiLink + '/v1/organizations' + currentSearch)
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            setOrgCalled(true)
            if(data && data.data){
              setOrgList(data.data)
            }
          }else{
            setOrgCalled(true)
          }
          
        });
    }catch(e){
      console.log(e.message)
      setOrgCalled(true)
    }
  }


  
  
  
  return (
      <div className={`${isDark? 'dark': ''} relative scrollbar-hide`}>
        <Toaster />
        {orgList && Object.keys(orgList).length > 0 &&
        <div className={`dark:bg-slate-900  max-h-screen h-screen overflow-hidden scrollbar-hide`}>
          <Header orgList={orgList}  user={user} updateOrganizationsList={updateOrganizationsList} />
          <div className='absolute z-50 bottom-2 right-4 hidden sm:flex '>
            <img src={IoTFlowsLogoLightTransparent} alt='IoTFlows Logo Transparent' className='w-24 md:w-28 lg:w-32' />
          </div>
          <div className='flex h-full'>
            <LeftSideNav />
            <main className=' w-full px-4 pt-4 grow'>
                <Routes>
                  <Route path="/" element={<MainProjectsListPage />} />
                  <Route path="/:org_uuid" element={<MainProjectsListPage />} />
                  <Route path="/:org_uuid/projects" element={<MainProjectsListPage />} />
                  <Route path="/:org_uuid/projects/:projectUuid" element={<SelectedProjectList />} />
                  <Route path="/:org_uuid/devices/:deviceUuid" element={<MainSelectedDevicePage />} />
                  <Route path="/:org_uuid/instances" element={<MainInstancesPage />} />
                  <Route path="/:org_uuid/logs" element={<MainLogsPage />} />
                  <Route path="/:org_uuid/apikeys" element={<MainApiKeysPage />} />
                  <Route path="/:org_uuid/settings/organization" element={<MainSettingPage user={user} orgList={orgList} updateOrganizationsList={updateOrganizationsList} />} />
                  <Route path="/:org_uuid/settings/user" element={<ProfilePage user={user} updateUserInformation={updateUserInformation} />} />
                  <Route path="/:org_uuid/settings/billing" element={<BillingPage />} />
                  <Route path="*" element={<ErrorPage />} />

                </Routes>
            </main>
          </div>
        </div>
        }
        {isAddOrgModalOpen && <AddOrgModal btnType={'hideBtn'} remoteOpenAssetModal={isAddOrgModalOpen} updateOrganizationsList={updateOrganizationsList} />}
        
      </div>
    
  );
}

export default App;
