import { useState, useRef, useEffect } from 'react';
import GeneralFormInput from '../components/forms/GeneralFormInput';
import IoTFlowsLogo from '../images/IoTFlowsLogoBlackFont.png';
import { motion, AnimatePresence } from "framer-motion";
import GoogleLogo from '../images/logos/googleLogo.svg'
import MicrosoftLogo from '../images/logos/MicrosoftLogo.png'
import { useNavigate } from "react-router-dom";
import {Amplify,  Auth, Hub } from 'aws-amplify';
import { useRecoilState } from 'recoil';
import {userInfo} from '../atoms/userStates'
import {auth} from '../data/AwsConfigData'

Amplify.configure({
    Auth: auth
});



const CODE_LENGTH = new Array(6).fill(0);

function LoginPage() {
    const [form, setForm] = useState({
        username: '',
        password: '',
      });
    const [displayMFA, setDisplayMFA] = useState(false)
    const [microsoftLink, setMicrosoftLink] = useState('')
    const [googleLink, setGoogleLink] = useState('')
    const [sbdLink, setSbdLink] = useState('')
    const [focused, setFocused] = useState(false)
    const [authenticationValue, setAuthenticationValue] = useState('')
    const inputRef = useRef(null)
    const [errorMFA, setErrorMFA] = useState(false)
    const [isMFAlogin, setIsMFAlogin] = useState(false)
    const [issueMessage, setIssueMessage] = useState('')
    const [issueMFAMessage, setIssueMFAMessage] = useState('')
    const [verifyingUser, setVerifyingUser] = useState({})
    const [loggedInUser, setLoggedInUser] = useState({})
    const navigate = useNavigate();
    

    const values = authenticationValue.split("");
    const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
    const hideInput = !(values.length < CODE_LENGTH.length);

    useEffect(() => {
        if(loggedInUser && Object.keys(loggedInUser).length > 0){
            // console.log('Logging in', loggedInUser)
            window.location.reload();
            navigate('/', {state:{userLogin: loggedInUser}})
        }
    }, [loggedInUser])


    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
              case "signIn":
                  if(data){
                    setLoggedInUser(data)
                  }
                break;
            }
          });


        const isLocalhost = Boolean(
            window.location.hostname === "localhost" ||
              // [::1] is the IPv6 localhost address.
              window.location.hostname === "[::1]" ||
              // 127.0.0.1/8 is considered localhost for IPv4.
              window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
              )
          );
          if(isLocalhost) {
            setMicrosoftLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=http://localhost:3000/&identity_provider=Microsoft')
            setGoogleLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&redirect_uri=http://localhost:3000/&identity_provider=Google')
            setSbdLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=http://localhost:3000/&identity_provider=SBDInc')
          }else {
            setMicrosoftLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://dashboard.iotflows.com/&identity_provider=Microsoft')
            setGoogleLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&redirect_uri=https://dashboard.iotflows.com/&identity_provider=Google')
            setSbdLink('https://auth.iotflows.com/oauth2/authorize?client_id=18230ih88kfb3ouj67mrid5er3&response_type=token&scope=email+openid+phone+profile&redirect_uri=https://dashboard.iotflows.com/&identity_provider=SBDInc')
          }
    },[])


    const handledLogin = (e) => {
        e.preventDefault();
        try {
            Auth.signIn(form.username, form.password)
            .then(verifyingUser => {
                if (verifyingUser.challengeName === 'SMS_MFA' || verifyingUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    setVerifyingUser(verifyingUser)
                    setDisplayMFA(true)
                    
                } else {
                    // The user directly signs in
                    setLoggedInUser(verifyingUser)
                    setIssueMessage('')
                }
            })
            .catch(err => {
                console.log('Error ', err.message)
                setIssueMessage(err.message)
            });
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    const handleGeneralFormChange = (event) => {
        // Get the name of the field that caused this change event
        // Get the new value of this field
        const { name, value } = event.target;
    
        // Assign new value to the appropriate form field
        const updatedForm = {
          ...form,
          [name]: value
        };
        // Update state
        setForm(updatedForm);
      }

    // MFA Functions
    const handleClick = () => {
        inputRef.current.focus();
    };

    const handleFocus = () => {
        setFocused(true)
    };

    const handleBlur = () => {
        setFocused(false)
    };

    const handleChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        
        if (authenticationValue.length >= CODE_LENGTH.length) return null;
        let tempval = (authenticationValue + value).slice(0, CODE_LENGTH.length)
        return (
            setAuthenticationValue(tempval)
        )
      };

    const handleKeyUp = (e) => {
        if (e.key === "Backspace") {
            let val = authenticationValue.slice(0, authenticationValue.length - 1)
            return(
                setAuthenticationValue(val)
            )
        }
    }

    const handleMFALogin = () => {
        // set state to true so it doesn't continously try to log in 
        setIsMFAlogin(true)

        
        // If MFA is enabled, sign-in should be confirmed with the confirmation code
        Auth.confirmSignIn(
            verifyingUser,   // Return object from Auth.signIn()
            authenticationValue,   // Confirmation code  
            verifyingUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        ).then(loggedUser =>{
            setLoggedInUser(loggedUser)
            setErrorMFA(false)
            setIssueMFAMessage('')
            setIsMFAlogin(false)
            setAuthenticationValue('')
        })
        .catch(err => {
            setErrorMFA(true)
            setIssueMFAMessage(err.message)
            setIsMFAlogin(false)
            setAuthenticationValue('')
        });
    }

    if(Object.keys(authenticationValue).length >= 6 && !isMFAlogin){
        handleMFALogin()
    }


  return (
    <div className='overflow-scroll h-screen'>
        <div className='fixed inset-x-0 top-[-250px] bottom-0 z-[-1] -skew-y-12'>
            <div className='absolute top-[-1000px] left-0 right-0 origin-[0 50%] h-[1630px] bg-slate-100 '>
            </div>
            <div className='top-[630px] left-[-10px] right-[calc(50%+135px)] h-12 absolute bg-blue-300 md:right-[calc(50%+505px)] xl:right-[calc(50%+605px)]'>
            </div>
            <div className='top-[580px] right-[-10px] left-[calc(100%-110px)] h-12 absolute bg-blue-300 md:left-[calc(100%-230px)]'>
            </div>
        </div>
        <div className='flex flex-col mx-auto pt-7 max-w-[382px] sm:pt-14 sm:max-w-[540px] h-full'>
            <div className='text-left ml-3 pl-5 pb-6 sm:pl-4 sm:pb-10 '>
                <img src={IoTFlowsLogo} className='h-10' alt="IoTFlows Inc" loading="lazy" />
            </div>
            <div className='shadow-iotflows-xl w-full bg-white rounded-2xl py-8 px-5 my-0 mx-4 sm:py-14 sm:px-12'>
                <AnimatePresence >
                    {!displayMFA ? (
                        <div key={'signInSection'} >
                        <form onSubmit={handledLogin}>
                            <h1 className={`text-2xl font-medium text-gray-600`}>Sign in to your account</h1>
                            <div className="pt-4">
                                <GeneralFormInput label='Username' type='text' placeholder='' name='username' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Password' type='password' placeholder='' name='password' onChange={handleGeneralFormChange} />
                                {issueMessage &&
                                    <div className='p-1 ml-1 mt-3 flex items-center'>
                                        <svg fill="currentColor" className='text-rose-600 w-3 h-3' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                                        </svg>
                                        <span className='text-sm font-normal text-rose-600' >&nbsp;&nbsp;{issueMessage}</span>
                                    </div>
                                }
                            </div>
                            <div className={`mt-2 text-blue-600 hover:text-blue-800 ml-auto text-sm font-bold cursor-pointer `} onClick={() => navigate('/reset')}>
                                Forgot your password?
                            </div>
                            <button  
                                // onClick={handledLogin}
                                type="submit"
                                className='w-full text-center p-1.5 font-medium text-base bg-blue-600 text-white hover:bg-blue-700 rounded-lg mt-4 cursor-pointer disabled:bg-blue-400'
                                disabled={ form.username === '' || form.password === '' } 
                            >
                                Sign in
                            </button>
                            <div className={`w-full text-center mt-4 text-gray-500 text-sm font-medium`} >
                                Don't have an account? <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={() => navigate('/signup')}>Sign up</span>
                            </div>
                            {/* Sign In with Google  */}
                            {/* <div className='mt-4'>
                                <h6 className={`text-sm font-medium mb-2 text-gray-600`}>
                                    Sign in with Google
                                </h6>
                                <motion.button  
                                    whileHover={{ scale: 1.05 }} 
                                    whileTap={{ scale: 0.95 }} 
                                    className='w-full p-1.5 font-medium text-base flex shadow-lg w-full justify-center text-gray-600 items-center rounded-lg'
                                onClick={() => window.location.href = googleLink}
                                >
                                    <img src={GoogleLogo} atl='Google Logo' className='w-4 h-4 mr-2' />
                                    <span >
                                        Sign in with Google
                                    </span>
                                </motion.button >   
                            </div> */}
                            {/* Sign In with Microsoft  */}
                            {/* <div className='mt-4'>
                                <h6 className={`text-sm font-medium mb-2 text-gray-600`}>
                                    Sign in with Microsoft
                                </h6>
                                <motion.button  
                                    whileHover={{ scale: 1.05 }} 
                                    whileTap={{ scale: 0.95 }} 
                                    className='w-full p-1.5 font-medium text-base flex shadow-lg w-full justify-center text-gray-600 items-center rounded-lg'
                                onClick={() => window.location.href = microsoftLink}
                                >
                                    <img src={MicrosoftLogo} atl='Microsoft' className='w-4 h-4 mr-2' />
                                    <span >
                                        Sign in with Microsoft
                                    </span>
                                </motion.button >   
                            </div> */}
                        </form>
                        </div>
                    ):(
                        <div key={'MFASection'} >
                            <div className='flex flex-col' >
                                <h1 className={`text-2xl font-medium text-gray-600`}>Two-step authentication</h1>

                                <div className={`text-sm font-normal mb-2 mt-2 text-gray-600`}  >
                                    To continue, please enter the 6-digit verification code sent to your authentication application
                                </div>
                                {/* 6 code digits  */}
                                <div className='border-[1px] border-solid border-black/20 inline-block relative flex mx-auto mt-6 rounded-xl' onClick={handleClick}>

                                    <input
                                            value=""
                                            ref={inputRef}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onKeyUp={handleKeyUp}
                                            className='absolute border-0 text-3xl text-center outline-none border-transparent focus:border-transparent focus:ring-0 focus:outline-none m-0 appearance-none'
                                            type="number"
                                            autoFocus
                                            style={{
                                                width: "40px",
                                                top: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                borderRadius:'999px',
                                                left: `${selectedIndex * 40}px`,
                                                opacity: hideInput ? 0 : 1,
                                            }}
                                        />
                                    {
                                        CODE_LENGTH.map((v, index) => {
                                            const selected = values.length === index;
                                            const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                                            return (
                                            <div key={index} className='border-r-[1px] bg-transparent text-gray-600 border-solid border-black/20 w-10 h-12 flex items-center justify-center text-3xl relative m-0 last:border-0'>
                                                {values[index]}
                                                {(selected || filled) && focused && (index == 0) && <div className='absolute inset-0 shadow-authentication-box rounded-l-xl' />}
                                                {(selected || filled) && focused && (index > 0 && index < 5) && <div className='absolute inset-0 shadow-authentication-box '/>}
                                                {(selected || filled) && focused && (index == 5) && <div className='absolute inset-0 shadow-authentication-box rounded-r-xl' />}
                                            </div>
                                            );
                                        })
                                    }
                                </div>
                                {errorMFA &&
                                    <div className='p-1 ml-3 mt-3 flex items-center'>
                                        <svg fill="currentColor" className='text-rose-600 w-3 h-3' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z" fillRule="evenodd"></path>
                                        </svg>
                                        <span className='text-sm font-normal text-rose-600' >&nbsp;&nbsp;{issueMFAMessage}</span>
                                    </div>
                                }
                            </div>
                            <div >
                                <button
                                    className='w-full p-1.5 font-medium text-base bg-blue-600 text-white hover:bg-blue-700 rounded-lg mt-6 disabled:bg-blue-500'
                                    onClick={handleMFALogin} 
                                    disabled={Object.keys(authenticationValue).length < 6}
                                >
                                    Continue
                                </button>
                                {/* <div className={`mt-4 text-blue-600 hover:text-blue-800 ml-auto text-sm font-bold cursor-pointer text-center`} onClick={() => navigate('/')}>
                                    Login another way
                                </div> */}
                            </div>
                        </div>
                    )}
                </AnimatePresence>
            </div>

            <div className='grow shrink'/>
            <section className='mx-auto'>
                <div className='p-3'>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/" >© IoTFlows Inc 2024</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                </div>
            </section>

        </div>
    </div>
  )
}

export default LoginPage