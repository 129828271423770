import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import DeviceDataModal from '../components/modals/deviceModals/DeviceDataModal'

function DataStreamTable({rows, searchedRows,isSearched,searchMoreData,fetchingData}) {
    const [selectedDataRow, setSelectedDataRow] = useState([])
    const [deviceDataRow, setDeviceDataRow] = useState([])
    const [openDeviceDataModal, setOpenDeviceDataModal] = useState(false)

    const handleRowSelected = (row) => {
        setSelectedDataRow(row)
        setDeviceDataRow(row.data)
        setOpenDeviceDataModal(true)
    }

    const handleCloseDeviceDataModal = () => {
        setSelectedDataRow([])
        setDeviceDataRow([])
        setOpenDeviceDataModal(false)
    }
  return (
    <div>

        <table className="table-fixed w-full overflow-scroll scrollbar-hide" >
            <colgroup>
                <col style={{width:'10%'}}/>
                <col style={{width:'10%'}}/>
                <col style={{width:'30%'}}/>
                <col style={{width:'5%'}}/>
                <col style={{width:'10%'}}/>
                <col style={{width:'10%'}}/>
                <col style={{width:'25%'}}/>
            </colgroup>
        <thead >
            <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Publisher`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Data Stream`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Data`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Unit`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Size`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Received At`}</th>
                <th className='text-xs font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>{`Topic`}</th>
            </tr>
        </thead>
            <tbody className=''>
                {!fetchingData && Object.keys(rows).length === 0 &&
                    <tr className='justify-center w-full '>
                    <td colSpan={7} className=' text-center'>
                        <div className='relative w-fit m-4 p-4 rounded-xl border-blue-700 dark:border-blue-400 border-[0.01em] mx-auto' >
                            <div className='flex flex-col'>
                                <span className='text-gray-600 dark:text-gray-300'>WAITING ON DATA</span>
                                <span className='text-gray-600 dark:text-gray-300'>Device messages will be published here in real-time</span>

                            </div>
                        </div> 
                    </td>
                    </tr>
                }
                {!isSearched &&
                    rows.map((dataRow) => (
                    <tr key={`${dataRow.id}-notSearched`} 
                        className='border-b-[0.01em] dark:border-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600'
                        onClick={() => handleRowSelected(dataRow)}
                        >
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.client_name}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_name}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate text-right'>
                            {dataRow.data}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_unit}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_size}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate text-right'>
                            {dataRow.received_at}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate pl-2 '>
                            {dataRow.topic}
                        </td>
                    </tr>
                    ))
                }
                {isSearched &&
                    searchedRows.map((dataRow) => (
                    <tr key={`${dataRow.id}-Searched`}  
                        className='border-b-[0.01em] dark:border-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600'
                        onClick={() => handleRowSelected(dataRow)}
                        >
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.client_name}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_name}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate text-right'>
                            {dataRow.data}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_unit}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate'>
                            {dataRow.data_stream_size}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate text-right'>
                            {dataRow.received_at}
                        </td>
                        <td className='cursor-pointer text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 truncate pl-2 '>
                            {dataRow.topic}
                        </td>
                    </tr>
                    ))
                }
                {!searchMoreData &&
                    <tr >
                        <td colSpan={7} className='text-center py-4'>
                        <CircularProgress size={42} />
                        </td>
                    </tr>
                }
            </tbody>
        </table>
        {openDeviceDataModal &&
            <DeviceDataModal btnType={'hideBtn'} openDeviceDataModal={openDeviceDataModal} deviceDataRow={deviceDataRow} selectedDataRow={selectedDataRow} handleCloseDeviceDataModal={handleCloseDeviceDataModal} />
        }
    </div>
  )
}

export default DataStreamTable