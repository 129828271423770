import SubNav from "../components/SubNav"
import OrgPage from "./OrgPage";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'




function MainSettingPage({orgList,user,  updateOrganizationsList}) {
    const [selectedSubLink, setSelectedSubLink] = useState(null)
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization); 
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])
    

    const subNav = {
        settings: {
            name:'Settings',
            pathName:`/${selectedOrganization}/settings/organization`,
            search:'?select=settings',
            url:'settings',
        },
        // webhooks : {
        //     name:'Webhooks',
        //     pathName:`/${selectedOrganization}/settings/organization`,
        //     search:'?select=webhooks',
        //     url:'webhooks',
        // },
    }
    
    useEffect(() => {
        if(selectedOrganization) {
            setSelectedSubLink(subNav['settings'])
        }

    }, [selectedOrganization])

    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch &&  selectedOrganization){
            // Pulling to see how cards should be grouped
            let selected = searchParams.get('select')
            
            if(selected){
                setSelectedSubLink(subNav[selected])
            }
        }

    }, [location, selectedOrganization])


    return (
        <div className={`px-1 sm:pl-3 sm:pr-3 py-1 ${isMobile ? 'max-h-[calc(100vh-4rem)]' : 'max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide`}> 
            {/* nagivations links within assets page */}
            {selectedOrganization && selectedSubLink && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}

            {selectedOrganization && selectedSubLink && subLinkPage(selectedSubLink, user, orgList, updateOrganizationsList) }
            
        </div>
    )
}

export default MainSettingPage


const subLinkPage = (link, user, orgList, updateOrganizationsList) => {
    let linkUrl = link.url
    const allLinks = {
        settings: { link: "settings", content: <OrgPage user={user} orgList={orgList} updateOrganizationsList={updateOrganizationsList} /> },
        // webhooks: { link: "webhooks", content:<WebhooksPage   />  },
        // gateways: { link: "gateways", content: <div className='text-[128px]'>🧀</div>  },
        // datastreams: { link: "datastreams", content: <div className='text-[128px]'>🧀</div> },
    };

    

      return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={allLinks[linkUrl].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
            {allLinks[linkUrl].content} 
        </motion.div>
      </AnimatePresence>
    )  
            
}