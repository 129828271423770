import { useState, useRef, useEffect } from 'react';
import GeneralFormInput from '../components/forms/GeneralFormInput';
import IoTFlowsLogo from '../images/IoTFlowsLogoBlackFont.png';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {Amplify,  Auth, Hub } from 'aws-amplify';
import {useLocation} from 'react-router-dom';
import {auth} from '../data/AwsConfigData'


Amplify.configure({
    Auth: auth
});


const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
const lowerCase = new RegExp('(?=.*[a-z])')
const upperCase = new RegExp('(?=.*[A-Z])')
const specialChar = new RegExp('(?=.*[^A-Za-z0-9])')
const num = new RegExp('(?=.*[0-9])')
const eightChar = new RegExp('(?=.{8,})')
const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  


function ConfirmForgotPasswordPage() {
    const [form, setForm] = useState({
        code: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    const [strongPasswordCreated, setStrongPasswordCreated] = useState(false)
    const [passwordContainsLowerCase, setPasswordContainsLowerCase] = useState(false)
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false)
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false)
    const [passwordContainsNum, setPasswordContainsNum] = useState(false)
    const [passwordContainsEightChar, setPasswordContainsEightChar] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [issueMessage, setIssueMessage] = useState('')
    const navigate = useNavigate();
    const location = useLocation();

    var deliveryDetails=''
    var username = ''

    if(location && location.state && location.state.deliveryDetails && location.state.username){
        deliveryDetails = location.state.deliveryDetails
        username = location.state.username
    }

    useEffect(() => {
        if(form.newPassword !== form.confirmNewPassword){
            setPasswordMatch(false)
        }else{
            setPasswordMatch(true)
        }

        if(strongPassword.test(form.newPassword)){
            setStrongPasswordCreated(true)
        }else{
            setStrongPasswordCreated(false)
        }
        if(lowerCase.test(form.newPassword)){
            setPasswordContainsLowerCase(true)
        }else{
            setPasswordContainsLowerCase(false)
        }
        if(upperCase.test(form.newPassword)){
            setPasswordContainsUpperCase(true)
        }else{
            setPasswordContainsUpperCase(false)
        }
        if(specialChar.test(form.newPassword)){
            setPasswordContainsSpecialChar(true)
        }else{
            setPasswordContainsSpecialChar(false)
        }
        if(num.test(form.newPassword)){
            setPasswordContainsNum(true)
        }else{
            setPasswordContainsNum(false)
        }
        if(eightChar.test(form.newPassword)){
            setPasswordContainsEightChar(true)
        }else{
            setPasswordContainsEightChar(false)
        }

    },[form.newPassword, form.confirmNewPassword])
   

    const handleChangePassword = () => {
        Auth.forgotPasswordSubmit(username, form.code, form.newPassword)
        .then(data => {
            // this.props.showSuccessSnackbar('Password has been successfully changed');
            navigate('/login')
            setIssueMessage('')
        })
        .catch(err => {
            // console.log(err);
            setIssueMessage(err.message)
        })
    }

    const handleGeneralFormChange = (event) => {
        // Get the name of the field that caused this change event
        // Get the new value of this field
        const { name, value } = event.target;
    
        // Assign new value to the appropriate form field
        const updatedForm = {
          ...form,
          [name]: value
        };
        // Update state
        setForm(updatedForm);
      }

  return (
    <div className='overflow-scroll h-screen'>
        <div className='fixed inset-x-0 top-[-250px] bottom-0 z-[-1] -skew-y-12'>
            <div className='absolute top-[-1000px] left-0 right-0 origin-[0 50%] h-[1630px] bg-slate-100 '>
            </div>
            <div className='top-[630px] left-[-10px] right-[calc(50%+135px)] h-12 absolute bg-blue-300 md:right-[calc(50%+505px)] xl:right-[calc(50%+605px)]'>
            </div>
            <div className='top-[580px] right-[-10px] left-[calc(100%-110px)] h-12 absolute bg-blue-300 md:left-[calc(100%-230px)]'>
            </div>
        </div>
        <div className='flex flex-col mx-auto pt-7 max-w-[382px] sm:pt-14 sm:max-w-[540px] h-full'>
            <div className='text-left ml-3 pl-5 pb-6 sm:pl-4 sm:pb-10 '>
                <img src={IoTFlowsLogo} className='h-10' alt="IoTFlows Inc" loading="lazy" />
            </div>
            <div className='shadow-iotflows-xl w-full bg-white rounded-2xl py-8 px-5 my-0 mx-4 sm:py-14 sm:px-12'>
                <AnimatePresence >
                        <div key={'resetSection'} >
                            <h1 className={`text-2xl font-medium text-gray-600`}>Confirm forgot password</h1>

                            <div className={`text-sm font-normal mb-2 mt-2 text-gray-600`}  >
                            We have sent a password reset code by {deliveryDetails && Object.keys(deliveryDetails).length > 0 && deliveryDetails.AttributeName == 'phone_number'? 'text message': deliveryDetails.AttributeName} to {deliveryDetails?.Destination}. Enter it below to reset your password.
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Code' type='text' placeholder='' name='code' onChange={handleGeneralFormChange} />
                            </div>
                            {issueMessage.length > 0 &&
                            <div className='py-1 flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span className={`text-sm font-light text-rose-600 `} >&nbsp;{issueMessage}</span>
                            </div>
                            }
                            <div className="pt-4">
                                <GeneralFormInput label='New password' type='password' placeholder='' name='newPassword' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Confirm new password' type='password' placeholder='' name='confirmNewPassword' onChange={handleGeneralFormChange} />
                            </div>

                            {!strongPasswordCreated && form.newPassword &&
                            <>
                                <div className='py-1 mt-1 flex items-center'>
                                    {passwordContainsLowerCase ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsLowerCase? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a lower case letter</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsUpperCase ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsUpperCase? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a upper case letter</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsSpecialChar ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsSpecialChar? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a special character</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsNum ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsNum? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a number</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsEightChar ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsEightChar? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain at least 8 characters</span>
                                </div>
                            </>
                            }
                            {strongPasswordCreated &&
                                <div className='py-1 flex items-center'>
                                    <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                    </svg>
                                    <span className={`text-sm font-light text-gray-600`} >&nbsp;Nice work! This is an excellent password.</span>
                                </div>
                            }
                            {passwordMatch && form.newPassword.length > 0 && form.confirmNewPassword.length > 0 && 
                                <div className='py-1 flex items-center'>
                                    <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                    </svg>
                                    <span className={`text-sm font-light text-gray-600`} >&nbsp;Great job! Passwords match.</span>
                                </div>
                            }

                            {!passwordMatch && form.newPassword.length > 0 && form.confirmNewPassword.length > 0 && 
                                <div className='py-1 flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                    <span className={`text-sm font-light text-rose-600`} >&nbsp;Passwords must match.</span>
                                </div>

                            }

                            <button
                                onClick={handleChangePassword}
                                className='w-full p-1.5 font-medium text-base bg-blue-600 text-white hover:bg-blue-700 rounded-lg mt-4 disabled:bg-blue-400'
                                disabled={form.code == '' || form.newPassword == '' || form.confirmNewPassword == '' || !passwordMatch || !strongPasswordCreated} 
                            >
                                Change Password
                            </button>
                            <div className={`w-full text-center mt-4 text-gray-500 text-sm font-medium`} >
                                <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={() => navigate('/login')}>Return to login</span>
                            </div>

                        </div>
                </AnimatePresence>
            </div>
            <div className={`w-full text-left mt-4 ml-8 text-gray-500 text-sm font-medium`} >
                Don't have an account? <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={() => navigate('/signup')}>Sign up</span>
            </div>

            <div className='grow shrink'/>
            <section className='mx-auto' >
                <div className='p-3'>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/" >© IoTFlows Inc 2024</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                </div>
            </section>

        </div>
    </div>
  )
}

export default ConfirmForgotPasswordPage