import { useRef, useState } from 'react'
import App from './App';
import LoginRoutes from './MyLoginPage/LoginRoutes';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useSearchParams } from 'react-router-dom'
import MqttService from './core/services/MqttService';
import { userInfo } from './atoms/userStates';
import { useRecoilValue } from 'recoil';

function CredentialsRouting() {
    const location = useLocation();
    const [user, setUser] = useState(null)
    const [hasReadUser, setHasReadUser] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const [token, setToken] = useState('')
    const [isUserOnline, setIsUserOnline] = useState(true)
    const dataFetchedRef = useRef(false);
    const loggedInUser = useRecoilValue(userInfo)


   

    useEffect(() => {
        let urlToken = searchParams.getAll('url_token')
        let passedToken = searchParams.getAll('passed_token')
        if(urlToken && urlToken[0]){
            setToken(urlToken[0])
        }else if(passedToken && passedToken[0]){
            setToken(passedToken[0])
        }
    }, [])
    
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUser(user)
                setHasReadUser(true)
            })
            .catch(
                setHasReadUser(true)
            )

    }, [])

    // useEffect(() => {
    //     console.log('location.state.userLogin', location)
    //     if(location && location.state && location.state.userLogin){
    //         setUser(location.state.userLogin)
    //     }
    // }, [location])

    const connectMQTTGlobally = () => {
        // console.log('i fire once')
        MqttService.connectClient(
        mqttSuccessHandler,
        mqttConnectionLostHandler
        );
    }

    const mqttSuccessHandler = () => {
        console.log("connected to mqtt");
        setIsUserOnline(true)
      };
    
      const mqttConnectionLostHandler = () => {
        console.log("lost connection to mqtt");
    
        setIsUserOnline(false)
      };

    
      

  return (
    <div className='scrollbar-hide'>
        {token || (hasReadUser && user && Object.keys(user).length > 0) ? (
            <App />
        ):(
            <LoginRoutes  />
        )}
    </div>
  )
}

export default CredentialsRouting