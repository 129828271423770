import React, { useEffect, useState } from 'react'
import { LightTooltip } from '../styles/Tooltip'
import CreateDeviceApiKey from '../components/modals/deviceModals/CreateDeviceApiKey'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { darkModeState } from '../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import { globalSelectedOrganization } from '../atoms/orgStates'
import DeleteGeneralModal from '../components/modals/deviceModals/DeleteGeneralModal'
import EditDeviceApiModal from '../components/modals/deviceModals/EditDeviceApiModal'
import EditDataStreamModal from '../components/modals/deviceModals/EditDataStreamModal'
import CreateDataStreamModal from '../components/modals/deviceModals/CreateDataStreamModal'
import CreateAction from '../components/modals/deviceModals/CreateAction'
import EditActionModal from '../components/modals/deviceModals/EditActionModal'
import ExecuteActionModal from '../components/modals/deviceModals/ExecuteActionModal'

function ApiMessagingPage({selectedDevice}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [deviceApiKeysList, setDeviceApiKeysList] = useState([])
    const [deviceDataStreamsList, setDeviceDataStreamsList] = useState([])
    const [deviceActionsList, setDeviceActionsList] = useState([])
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        if(selectedDevice && Object.keys(selectedDevice).length > 0){
            getDeviceApiKeys()
            getDeviceDataStreams()
            getDeviceActions()
        }
    }, [selectedDevice])

    const getDeviceApiKeys = () => {
        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/clients')
        .then(async response => {
            const data = await response.json();
          if(response.ok ){
            if(data && data.data){
                setDeviceApiKeysList(data.data)
            }
            
          }
        });
      }

    const getDeviceDataStreams = () => {
        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/data_streams')
        .then(async response => {
            const data = await response.json();
          if(response.ok ){
            if(data && data.data){
                setDeviceDataStreamsList(data.data)
            }
            
          }
        });
      }

    const getDeviceActions = () => {
        getData(apiLink + '/v1/devices/' + selectedDevice.device_uuid +'/actions')
        .then(async response => {
            const data = await response.json();
          if(response.ok ){
            if(data && data.data){
                setDeviceActionsList(data.data)
            }
            
          }
        });
      }


  return (
    <div className='overflow-scroll scrollbar-hide w-full'>
        {/* Device API Keys section */}
        <div className='flex flex-col'>
            <div className='flex w-full items-center border-b-[0.01em] dark:border-slate-600'>
                    <div className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-1 text-left items-center' >
                        <span>Device API Keys</span>
                        <LightTooltip title="Learn more about implementing device Api keys">
                            <button className='ml-2 items-center' onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/create-a-device-api-key', "_blank")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>
                            </button>
                        </LightTooltip>
                    </div>
                <div className='ml-auto'>
                    <div className='flex justify-end items-center py-3' >
                        <div className='flex items-center '>
                            <div className='flex items-center'>
                                <CreateDeviceApiKey btnType='standardBtn' selectedDevice={selectedDevice} getDeviceApiKeys={getDeviceApiKeys} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
            {deviceApiKeysList.map((apiKey) => (
                <Accordion
                    // defaultExpanded={true}
                    key={apiKey.client_id}
                    sx={{
                        backgroundColor:'transparent',
                        marginBottom:'8px',
                        boxShadow:'none',
                        border:'0px',
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color:isDark?'#cbd5e1':'#475569',}} />}
                    aria-controls={`${apiKey.client_id}-content`}
                    id={`${apiKey.client_id}-header`}
                    sx={{
                        backgroundColor:isDark?'#1e293b':'#f1f5f9',
                        borderRadius:'4px',
                        marginBottom: '0px',
                    }}
                    >
                        <div className='flex justify-between w-full'>
                            <div className={`text-gray-600 dark:text-gray-300`}>{apiKey.client_name}</div>
                            <div className='flex mx-2 space-x-2'>
                                <EditDeviceApiModal btnType='inlineIconBtn' apiKey={apiKey} refreshList={getDeviceApiKeys} />
                                <DeleteGeneralModal btnType='inlineIconBtn' refreshList={getDeviceApiKeys} item={apiKey.client_name} api={`/v1/clients/${apiKey.client_id}`} />
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails 
                        sx={{
                            backgroundColor:isDark?'#1e293b':'#f1f5f9',
                            borderRadius:'0px 0px 4px 4px',
                        }}
                    >
                    <div className='flex flex-col overflow-x-scroll scrollbar-hide pr-2 '>
                        <div className={`text-gray-500 dark:text-gray-400`}>Description:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{apiKey.client_description}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400`}>ID:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{apiKey.client_id}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400`}>Username:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{apiKey.username}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400`}>Authorized Topic:&nbsp;<code className="text-rose-600 dark:text-rose-300 text-sm">v1/organizations/{selectedOrganization}/projects/{selectedDevice.project_uuid}/#</code></div>
                    </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            </div>
        </div>
        {/* DeviceData Streams section */}
        <div className='flex flex-col mt-6'>
            <div className='flex w-full items-center border-b-[0.01em] dark:border-slate-600'>
                    <div className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-1 text-left items-center' >
                        <span>Device Data Streams</span>
                        <LightTooltip title="Learn more about implementing device data streams">
                            <button className='ml-2 items-center' onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/publish-and-subscribe-real-time-messages-with-node-red', "_blank")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>
                            </button>
                        </LightTooltip>
                    </div>
                <div className='ml-auto'>
                    <div className='flex justify-end items-center py-3' >
                        <div className='flex items-center '>
                            <div className='flex items-center'>
                                <CreateDataStreamModal btnType='standardBtn' selectedDevice={selectedDevice} refreshList={getDeviceDataStreams} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
            {deviceDataStreamsList.map((dataStream) => (
                <Accordion
                    key={dataStream.data_stream_uuid}
                    sx={{
                        backgroundColor:'transparent',
                        marginBottom:'8px',
                        boxShadow:'none',
                        border:'0px',
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color:isDark?'#cbd5e1':'#475569',}} />}
                    aria-controls={`${dataStream.data_stream_uuid}-content`}
                    id={`${dataStream.data_stream_uuid}-header`}
                    sx={{
                        backgroundColor:isDark?'#1e293b':'#f1f5f9',
                        borderRadius:'4px',
                        marginBottom: '0px',
                    }}
                    >
                        <div className='flex justify-between w-full'>
                            <div className={`text-gray-600 dark:text-gray-300`}>{dataStream.data_stream_name}</div>
                            <div className='flex mx-2 space-x-2'>
                                <EditDataStreamModal btnType='inlineIconBtn' dataStream={dataStream} refreshList={getDeviceDataStreams} selectedDevice={selectedDevice} />
                                <DeleteGeneralModal btnType='inlineIconBtn' refreshList={getDeviceDataStreams} item={dataStream.data_stream_name} api={`/v1/devices/${selectedDevice.device_uuid}/data_streams/${dataStream.data_stream_uuid}`} />
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails 
                        sx={{
                            backgroundColor:isDark?'#1e293b':'#f1f5f9',
                            borderRadius:'0px 0px 4px 4px',
                        }}
                    >
                    <div className='flex flex-col overflow-x-scroll scrollbar-hide pr-2 w-full'>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap	`}>Data Stream Description:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{dataStream.data_stream_description}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap	`}>Data Stream Unit:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{dataStream.data_stream_unit}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap	`}>Data Stream UUID:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{dataStream.data_stream_uuid}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap	`}>MQTT Topic:&nbsp;<code className="text-rose-600 dark:text-rose-300 text-sm text-wrap">v1/organizations/{selectedOrganization}/projects/{selectedDevice.project_uuid}/devices/{selectedDevice.device_uuid}/data-streams/{dataStream.data_stream_uuid}</code></div>
                    </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            </div>
        </div>
        {/* Device Actions section */}
        <div className='flex flex-col mt-6'>
            <div className='flex w-full items-center border-b-[0.01em] dark:border-slate-600'>
                    <div className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-1 text-left items-center' >
                        <span>Device Actions</span>
                        <LightTooltip title="Learn more about implementing device data streams">
                            <button className='ml-2 items-center' onClick={() => window.open('https://docs.iotflows.com/real-time-data-streams-alerts-and-actions/publish-and-subscribe-real-time-messages-with-node-red', "_blank")}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>
                            </button>
                        </LightTooltip>
                    </div>
                <div className='ml-auto'>
                    <div className='flex justify-end items-center py-3' >
                        <div className='flex items-center '>
                            <div className='flex items-center'>
                                <CreateAction btnType='standardBtn' selectedDevice={selectedDevice} refreshList={getDeviceActions} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
            {deviceActionsList.map((deviceAction) => (
                <Accordion
                    key={deviceAction.action_uuid}
                    sx={{
                        backgroundColor:'transparent',
                        marginBottom:'8px',
                        boxShadow:'none',
                        border:'0px',
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color:isDark?'#cbd5e1':'#475569',}} />}
                    aria-controls={`${deviceAction.action_uuid}-content`}
                    id={`${deviceAction.action_uuid}-header`}
                    sx={{
                        backgroundColor:isDark?'#1e293b':'#f1f5f9',
                        borderRadius:'4px',
                        marginBottom: '0px',
                    }}
                    >
                        <div className='flex justify-between w-full'>
                            <div className={`text-gray-600 dark:text-gray-300`}>{deviceAction.action_name}</div>
                            <div className='flex mx-2 space-x-2'>
                                <ExecuteActionModal btnType='inlineIconBtn' deviceAction={deviceAction}  selectedDevice={selectedDevice} />
                                <EditActionModal btnType='inlineIconBtn' deviceAction={deviceAction} refreshList={getDeviceActions} selectedDevice={selectedDevice} />
                                <DeleteGeneralModal btnType='inlineIconBtn' refreshList={getDeviceActions} item={deviceAction.action_name} api={`/v1/devices/${selectedDevice.device_uuid}/actions/${deviceAction.action_uuid}`} />
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails 
                        sx={{
                            backgroundColor:isDark?'#1e293b':'#f1f5f9',
                            borderRadius:'0px 0px 4px 4px',
                        }}
                    >
                    <div className='flex flex-col overflow-x-scroll scrollbar-hide pr-2'>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap`}>Action Description:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{deviceAction.action_description}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap`}>Action UUID:&nbsp;<span className={`text-gray-600 dark:text-gray-300 italic`}>{deviceAction.action_uuid}</span></div>
                        <div className={`text-gray-500 dark:text-gray-400 text-wrap`}>MQTT Topic:&nbsp;<code className="text-rose-600 dark:text-rose-300 text-sm text-wrap">v1/organizations/{selectedOrganization}/projects/{selectedDevice.project_uuid}/devices/{selectedDevice.device_uuid}/actions/{deviceAction.action_uuid}</code></div>
                    </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            </div>
        </div>
    </div>
  )
}

export default ApiMessagingPage