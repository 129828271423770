import { useState, useEffect } from "react";
import { apiLink } from "../ApiCalls/ApisVariables";
import { getData } from "../ApiCalls/DataApis";
import TablePagination from '@mui/material/TablePagination';
import { useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'



function PastInvoicesTab({selectedOrganization}) {
    const isDark = useRecoilValue(darkModeState);
    const [organizationInvoices, setOrganizationInvoices] = useState([])
    const [organizationInvoicesDic, setOrganizationInvoicesDic] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(3)
    const [currentBillingStartDate, setCurrentBillingStartDate] = useState('')
    const [currentBillingEndDate, setCurrentBillingEndDate] = useState('')
    const [nextBillingStartDate, setNextBillingStartDate] = useState('')
    const [nextBillingEndDate, setNextBillingEndDate] = useState('')
    const [selectedInvoiceLineItemsCurrentMonth, setSelectedInvoiceLineItemsCurrentMonth] = useState([])
    const [selectedInvoiceLineItemsNextMonth, setSelectedInvoiceLineItemsNextMonth] = useState([])
    const [selectedInvoice, setSelectedInvoice] = useState([])
    const [isDataReady, setIsDataReady] = useState(false)
    const [loadingInvoice, setLoadingInvoice] = useState(false)

    useEffect(() => {
      if(organizationInvoicesDic && Object.keys(organizationInvoicesDic).length > 0){
        setIsDataReady(true)
      }
    },[organizationInvoicesDic])


    useEffect(() => {
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/billing/invoice')
        .then(async response => {
            const data = await response.json();
            if(data.data){
                var modifyDate = {};
                for(var i = 0; i < Object.keys(data.data).length; i++){
                    // convert to milliseconds
                    modifyDate[i] = data.data[i].created * 1000;
                    modifyDate[i] = new Date(modifyDate[i]);
                    modifyDate[i] = modifyDate[i].toLocaleDateString() + ', ' + modifyDate[i].toLocaleTimeString([], {timeStyle: 'short'});
                    data.data[i].created = modifyDate[i];
                }
                var dic = {};
                for(var i=0; i<Object.keys(data.data).length; i++){
                    dic[data.data[i].id]= data.data[i]
                }
                setOrganizationInvoices(data.data)
                setOrganizationInvoicesDic(dic)
                setIsDataReady(true)

            
            }
        });

    },[selectedOrganization])


      const convertCost = (price) => {
        if(price){
          var stringvalue = '';
          if(price < 0) {
            stringvalue =  Math.abs(price/100)
            stringvalue = stringvalue.toLocaleString()
            stringvalue = '-$' + stringvalue
          }  else{
            stringvalue = price /100
            stringvalue = '$' + stringvalue.toLocaleString()
          }  
          return stringvalue;
        }else {
          return '$0';
        }
      }

      const convertStringCost = (price) => {
        if(price){
          var priceToInt = parseInt(price)
          if(priceToInt == 0){
            priceToInt = parseFloat(price)
          }
          
          priceToInt = priceToInt /100
          priceToInt = priceToInt.toLocaleString()
    
          return '$'+priceToInt;
        }
      }
    
      const convertStringCostTiered = (row) => {
        if(row){
          var price;
          var description = row.description
          if(description.includes('$0.00006')){
            price = 0.00006;
          }
          else if(description.includes('$0.00005')){
            price = 0.00005;
          }
          else if(description.includes('$0.00004')){
            price = 0.00004;
          }
          else if(description.includes('$0.00003')){
            price = 0.00003;
          }else {
            return ''
          }
    
          return '$'+ price;
        }
      }
    
      const convertFinalCosts = (amount) => {
        if(amount){
          var price = convertCost(amount)
          return price
        } else {
          return "$0.00"
        }
      }
    
      const dateConverter = (date) => {
        // date.toLocaleDateString() + ', ' + date.toLocaleTimeString();
        if(date != undefined){
          var modifyDate = date * 1000;
          modifyDate = new Date(modifyDate);
          modifyDate = modifyDate.toLocaleDateString([], {  month: 'short', day: 'numeric' })
          return modifyDate
        } else {
          return " "
        }
      }
    
      const billingCycleDateConverter = (start_date, end_date) => {
        if(start_date != undefined){
          var modifyStartDate = start_date*1000;
          var modifyEndDate = end_date*1000;
          modifyStartDate = new Date(modifyStartDate);
          modifyEndDate = new Date(modifyEndDate);
          modifyStartDate = modifyStartDate.toLocaleDateString([], {  month: 'long', day: 'numeric' });
          modifyEndDate = modifyEndDate.toLocaleDateString([], {  year: 'numeric', month: 'long', day: 'numeric' });
          return (modifyStartDate + ' - ' + modifyEndDate)
        } else {
          return " "
        }
      }


      const handleChangePage = (event, newPage) => {
        setPage(newPage)
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
      };

      const emptyRows = rowsPerPage - Math.min(rowsPerPage, organizationInvoices.length - page * rowsPerPage);

      const handleRowClick = (invoice) => {
        try {
          if(!loadingInvoice){
            setLoadingInvoice(true)
            getData(apiLink + '/v1/billing/invoice/' + invoice.id + '/invoice_line_items')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                  if(data.data){
                      setLoadingInvoice(false)

                      var current_month_items = [];
                      var next_month_items = [];
                      var current_month_date = data.data[0].period.start;
                      var current_date_index = 0;
                      for(var i=0; i<Object.keys(data.data).length; i++){
                          if( current_month_date < data.data[i].period.start){
                          current_month_date = current_month_date;
                          }else {
                          current_month_date = data.data[i].period.start;
                          current_date_index = i;
                          }
                      }
          
                      var next_month_date = data.data[0].period.start;
                      var next_date_index = 0;
                      for(var i=0; i<Object.keys(data.data).length; i++){
                          if( next_month_date > data.data[i].period.start){
                          next_month_date = next_month_date;
                          }else {
                          next_month_date = data.data[i].period.start;
                          next_date_index = i;
                          }
                      }
                      
                      setCurrentBillingStartDate(data.data[current_date_index].period.start)
                      setCurrentBillingEndDate(data.data[current_date_index].period.end)
                      setNextBillingStartDate(data.data[next_date_index].period.start)
                      setNextBillingEndDate(data.data[next_date_index].period.end)

      
                      var current_month_items = [];
                      var current_month_items_no_zeros = [];
                      var next_month_items = [];
                      var next_month_items_no_zeros = [];
                    for(var i=0; i < Object.keys(data.data).length; i++){
                      if(data.data[i].period.start < next_month_date){
                        current_month_items[i] = data.data[i];
                        if(current_month_items[i].amount){
                          current_month_items_no_zeros[i] = current_month_items[i];
                        }
                      }else {
                        next_month_items[i] = data.data[i];
                        if(next_month_items[i].amount){
                          next_month_items_no_zeros[i] = next_month_items[i];
                        }
                      }
                    }
                    setSelectedInvoiceLineItemsCurrentMonth(current_month_items_no_zeros)
                    setSelectedInvoiceLineItemsNextMonth(next_month_items_no_zeros)
                    setSelectedInvoice(invoice)
                }
              }
            });
          }
          
        } catch (error) {
          console.log(error.message)
          
        }
      }

  return (
    <div className='m-4' >
      {!isDataReady &&
        <div className="border-0 border-blue-300 shadow-iotflows-lg dark:shadow-lg-invert rounded-md p-4 w-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      }
        {isDataReady && organizationInvoices && Object.keys(organizationInvoices).length > 0 &&
        <div>
            <table className="table-auto w-full">
                <thead className=''>
                    <tr className="" >
                        <th className='font-bold tracking-wider px-2 py-0.5 text-2xl text-left text-gray-600 dark:text-gray-300' colSpan={4}>Invoices</th>
                    </tr>
                    <tr className='invoiceTableRow' >
                        <td className='invoiceTableCellHeader text-right'>Amount</td>
                        <td className='invoiceTableCellHeader' />
                        <td className='invoiceTableCellHeader' />
                        <td className='invoiceTableCellHeader'>INVOICE NUMBER</td>
                        <td className='invoiceTableCellHeader'>DESCRIPTION</td>
                        <td className='invoiceTableCellHeader'>DUE</td>
                        <td className='invoiceTableCellHeader'>CREATED</td>
                    </tr>
                </thead>
                <tbody className='tableBodyContainer'>
                    {(rowsPerPage > 0 ? 
                            organizationInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : organizationInvoices
                          ).map(invoice => (
                        <tr key={invoice.id} className="invoiceTableRow cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800" onClick={() => handleRowClick(invoice)}>
                            <td className='invoiceTableCellBody text-right'>{convertFinalCosts(invoice.total)}</td>
                            <td className='invoiceTableCellBody'>{invoice.currency}</td>
                            <td className='invoiceTableCellBody'> 
                                {invoice.status === 'paid' ? 
                                    <div className='rounded bg-[#cbf4c9] dark:bg-[#0e6245] h-4.5 py-[1px] px-[6px] text-center' >
                                        <span className='text-xs text-[#0e6245] dark:text-[#cbf4c9]'  >Paid</span>
                                    </div>
                                : null}
                                {invoice.status === 'open' ? 
                                    <div className='rounded bg-[#d6ecff] dark:bg-[#3d4eac] h-4.5 py-[1px] px-[6px] text-center' >
                                        <span  className='text-xs text-[#3d4eac] dark:text-[#d6ecff]'>Open</span>
                                    </div>
                                : null}
                                {invoice.status === 'void' ? 
                                    <div className='rounded bg-[#d1d1d1] dark:bg-[#8a8a8a] h-4.5 py-[1px] px-[6px] text-center' >
                                        <span  className='text-xs text-[#8a8a8a] dark:text-[#d1d1d1]' >Void</span>
                                    </div>
                                : null}
                                {invoice.status === 'draft' ? 
                                    <div className='rounded bg-[#d1d1d1] dark:bg-[#8a8a8a] h-4.5 py-[1px] px-[6px] text-center' >
                                        <span  className='text-xs text-[#8a8a8a] dark:text-[#d1d1d1]' >Draft</span>
                                    </div>
                                : null}
                                {invoice.status === 'uncollectible' ? 
                                    <div className='rounded bg-[#fff8d6] dark:bg-[#9c9133] h-4.5 py-[1px] px-[6px] text-center' >
                                        <span  className='text-xs text-[#9c9133] dark:text-[#fff8d6]' >Uncollectible</span>
                                    </div>
                                : null}   
                            </td>
                            <td className='invoiceTableCellBody'>{invoice.number}</td>
                            <td className='invoiceTableCellBody'>{invoice.description}</td>
                            <td className='invoiceTableCellBody'>{dateConverter(invoice.due_date)}</td>
                            <td className='invoiceTableCellBody'>{invoice.created}</td>
                        </tr>
                    ))}
                    {emptyRows > 0 && (
                        <tr className='invoiceTableCellBody' style={{ height: 40 * emptyRows }}>
                            <td colSpan={6} />
                        </tr>
                    )}
                </tbody>
            </table>
            <div className='text-left mr-auto'>
                <TablePagination
                    rowsPerPageOptions={[3, 6, 9, 12, { label: 'All', value: -1 }]}
                    component="div"
                    count={organizationInvoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        ".MuiTablePagination-toolbar": {
                          backgroundColor: "transparent",
                          color: isDark? '#9ca3af':'#4b5563',
                          fontFamily: 'Inter',

                        },
                        ".MuiTablePagination-selectIcon": {
                          backgroundColor: "transparent",
                          color: isDark? '#9ca3af':'#4b5563',
                          fontFamily: 'Inter',

                        },
                        ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                        //   fontWeight: "bold",
                          color: isDark? '#9ca3af':'#4b5563',
                          fontFamily: 'Inter',
                        }
                      }}
                />

            </div>
        </div>
        }


      {loadingInvoice &&
        <div className="border-0 border-blue-300 shadow-iotflows-lg dark:shadow-lg-invert rounded-md p-4 w-full mx-auto">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      }

        {/* SelectedInvoice */}
        {!loadingInvoice && selectedInvoice && Object.keys(selectedInvoice).length > 0 && 
        <div className='shadow-iotflows-lg dark:shadow-lg-invert rounded-lg p-4 mt-2'>
            <table className="table-auto w-full ">
                <thead className=''>
                    <tr className="" >
                        <th className='font-bold tracking-wider px-2 py-0.5 text-2xl text-left text-gray-600 dark:text-gray-300' colSpan={4}>Summary</th>
                    </tr>
                    <tr className="invoiceTableRow" >
                        <th className='text-sm px-2 py-3 text-left text-gray-500 dark:text-gray-400' colSpan={2}>
                        Invoice number: {selectedInvoice.number}
                        </th>
                        <th className='text-sm px-2 py-3 text-left text-gray-500 dark:text-gray-400 text-right justify-end' colSpan={2}>
                            <button className='flex ml-auto text-gray-700 dark:text-gray-400 rounded border border-gray-700 px-2 py-0.5' onClick={() => window.open(selectedInvoice.invoice_pdf, "_blank")}>
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
                                </svg>
                                <span>Invoice PDF</span>
                            </button>
                        </th>
                    </tr>
                    <tr className='invoiceTableRow' >
                        <td className='invoiceTableCellHeader'>DESCRIPTION</td>
                        <td className='invoiceTableCellHeader text-right'>QTY</td>
                        <td className='invoiceTableCellHeader text-right'>UNIT PRICE</td>
                        <td className='invoiceTableCellHeader text-right'>AMOUNT </td>
                    </tr>
                </thead>
                <tbody className='tableBodyContainer'>
                {currentBillingStartDate && currentBillingEndDate &&
                <tr className="invoiceTableRow">
                    <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                    {billingCycleDateConverter(currentBillingStartDate, currentBillingEndDate)}
                    </td>
                </tr>
                }
                {selectedInvoiceLineItemsCurrentMonth.map((row) => (
                    <tr key={row.id} className="invoiceTableRow">
                        <td className='invoiceTableCellBody'>{row.description}</td>
                        <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                        <td className='invoiceTableCellBody text-right'>
                            {row.price.product == 'prod_HrbX8g4tbaDC9f' ?
                                convertStringCostTiered(row)
                                :
                                convertStringCost(row.price.unit_amount_decimal? row.price.unit_amount_decimal : null)
                            }    
                        </td>
                        <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                    </tr>
                ))}
                {nextBillingStartDate && nextBillingEndDate &&
                    <tr className="invoiceTableRow">
                        <td className='text-xs font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3' colSpan={4}>
                        {billingCycleDateConverter(nextBillingStartDate, nextBillingEndDate)}
                        </td>
                    </tr>
                }
                    {selectedInvoiceLineItemsNextMonth.map((row) => (
                        <tr key={row.id} className="invoiceTableRow">
                            <td className='invoiceTableCellBody'>{row.description}</td>
                            <td className='invoiceTableCellBody text-right'>{row.quantity}</td>
                            <td className='invoiceTableCellBody text-right'>{convertStringCost(row.price.unit_amount_decimal)}</td>
                            <td className='invoiceTableCellBody text-right'>{convertCost(row.amount? row.amount:null)}</td>
                        </tr>
                    ))} 
                    <tr className="invoiceTableRow">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Subtotal</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(selectedInvoice.subtotal? selectedInvoice.subtotal : null)}</td>
                    </tr>
                    {organizationInvoicesDic[selectedInvoice.id].discount &&
                        <tr className="invoiceTableRow">
                            <td colSpan={1}/>
                            {organizationInvoicesDic[selectedInvoice.id].discount.coupon.percent_off != null ?
                            <td colSpan={2} className='invoiceTableCellBody text-right'>{`${organizationInvoicesDic[selectedInvoice.id].discount.coupon.name} (%${organizationInvoicesDic[selectedInvoice.id].discount.coupon.percent_off? organizationInvoicesDic[selectedInvoice.id].discount.coupon.percent_off : null})`}</td>
                            :
                            <td colSpan={2} className='invoiceTableCellBody text-right' >{`${organizationInvoicesDic[selectedInvoice.id].discount.coupon.name} (${convertCost(organizationInvoicesDic[selectedInvoice.id].discount.coupon.amount_off? organizationInvoicesDic[selectedInvoice.id].discount.coupon.amount_off: null)})`}</td>
                            }
                            <td className='invoiceTableCellBody text-right'>{convertFinalCosts(organizationInvoicesDic[selectedInvoice.id].total_discount_amounts[0].amount)}</td>
                        </tr>
                    }
                    <tr className="invoiceTableRow">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Total</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(organizationInvoicesDic[selectedInvoice.id].total? organizationInvoicesDic[selectedInvoice.id].total: null)}</td>
                    </tr>
                    <tr className="invoiceTableRow border-0">
                        <td colSpan={2}/>
                        <td className='invoiceTableCellBody text-right'>Amount due</td>
                        <td className='invoiceTableCellBody text-right'>{convertFinalCosts(organizationInvoicesDic[selectedInvoice.id].amount_due? organizationInvoicesDic[selectedInvoice.id].amount_due: null)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        }
    </div>
  )
}

export default PastInvoicesTab
