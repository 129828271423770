import React, { useState } from 'react'
import TableFooter from "../components/TableFooter";
import useTable from "../components/hooks/useTable";
import InstanceTableRow from './InstanceTableRow';
import CreateInstanceModal from '../components/modals/InstanceModals/CreateInstanceModal';
import FileManagementTable from './FileManagementTable';


function InstanceTable({data, rowsPerPage, refreshInstanceList, setSelectedCloudServer, setOpenFileManager}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <div className='space-y-4'>
        <table className="table-auto w-full mb-14">
                {/* <colgroup >
                    <col className='w-full'/>
                    <col className='w-[200px]'/>
                    
                </colgroup> */}
            <thead >
                <tr className='' >
                    <th className='text-2xl font-normal text-gray-600 dark:text-gray-300 px-0.5 py-3 text-left' colSpan={6}>
                        Cloud Node-RED Servers
                    </th>
                    <th className='' colSpan={4}>
                        <div className='ml-auto'>
                            <CreateInstanceModal btnType='standardBtn' refreshInstanceList={refreshInstanceList} />
                        </div>
                    </th>
                </tr>
                <tr className=''>
                    <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3' colSpan={10}>
                        <div className='flex justify-end'>
                            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
                        </div>
                    </th>
                </tr>
                <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Name`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Description`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Cloud Server Type`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{``}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{``}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{``}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`DNS`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`IP Address`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{`Created at`}</th>
                    <th className='text-sm font-medium text-gray-600 dark:text-gray-400 h-6 px-0.5 py-3'>{``}</th>
                </tr>
            </thead>
            <tbody className=''>
            {slice.map((el,index) => (
                <InstanceTableRow el={el} index={index} key={index} refreshInstanceList={refreshInstanceList} setSelectedCloudServer={setSelectedCloudServer} setOpenFileManager={setOpenFileManager} />
            ))}
            </tbody>
        </table>

    </div>
  )
}

export default InstanceTable