import SubNav from "../components/SubNav"
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import DeviceOverviewPage from "./DeviceOverviewPage";
import { apiLink } from "../ApiCalls/ApisVariables";
import { getData } from "../ApiCalls/DataApis";
import RebootDeviceModal from "../components/modals/deviceModals/RebootDeviceModal";
import DownloadDataSteamModal from "../components/modals/deviceModals/DownloadDataSteamModal";
import DeviceDataStreamsPage from "./DeviceDataStreamsPage";
import RemoteConnectionsPage from "./RemoteConnectionsPage";
import ApiMessagingPage from "./ApiMessagingPage";

function MainSelectedDevicePage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const {deviceUuid } = useParams()
    const [selectedSubLink, setSelectedSubLink] = useState(null)
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedDevice, setSelectedDevice] = useState([])
    const [deviceInfo, setDeviceInfo] = useState([])
    const [deviceGeolocation, setDeviceGeolocation] = useState([])
    const navigate = useNavigate()


    const subNav = {
        overview: {
            name:'Overview',
            pathName:`/${selectedOrganization}/devices/${deviceUuid}`,
            search:'?select=overview',
            url:'overview',
        },
        dataStreams : {
            name:'Data Streams',
            pathName:`/${selectedOrganization}/devices/${deviceUuid}`,
            search:'?select=dataStreams',
            url:'dataStreams',
        },
        remoteConnection : {
            name:'Secure Remote Connection',
            pathName:`/${selectedOrganization}/devices/${deviceUuid}`,
            search:'?select=remoteConnection',
            url:'remoteConnection',
        },
        messaging : {
            name:'APIs & Messaging',
            pathName:`/${selectedOrganization}/devices/${deviceUuid}`,
            search:'?select=messaging',
            url:'messaging',
        },
    }

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            // return shortcurrentDate  ;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }

    useEffect(() => {
        if(selectedOrganization && deviceUuid) {
            setSelectedSubLink(subNav['overview'])
            refreshDevice()
        }

    }, [selectedOrganization, deviceUuid])

    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch &&  selectedOrganization){
            // Pulling to see how cards should be grouped
            let selected = searchParams.get('select')
            
            if(selected){
                setSelectedSubLink(subNav[selected])
            }
        }

    }, [location, selectedOrganization])

    const refreshDevice = () => {
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/devices')
        .then(async response => {
        const data = await response.json();
            if(response.ok ){
                if(data && data.data){
                    // console.log('orga data', data.data)
                    data.data.map(device =>{
                        if(device.device_uuid == deviceUuid){
                            setSelectedDevice(device)
                            deviceInformationFormatting(device) 
                        }
                    })
                    
                }
            }
        });
    }

    const deviceInformationFormatting = (device) => {
        // set Geo information about device
        if(device){
            getData(apiLink + '/v1/geolocation/ipaddress/'+ device.device_peer_host)
                .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    if(data && data.data){
                        setDeviceGeolocation(data.data)
                    }
                }
            });
        }

        // format computer information
        if(Object.keys(device).length > 0 && device.device_system_information){
            let device_system_information = device.device_system_information;
            
            // memory
            let memory_free_bytes = device_system_information.mem.free
            let memory_used_bytes = device_system_information.mem.used
            
            // cpu
            let cpu_load_percent = device_system_information.currentLoad.currentLoad
            cpu_load_percent = cpu_load_percent?.toFixed(2)+ " %"
            
            // OS
            let os_distribution = device_system_information.osInfo.distro
            let os_architecture = device_system_information.osInfo.arch
        
            // network
            let default_network_ip4 = ''
            let default_network_ip4subnet = ''
            let default_network_mac = ''
            
            // Get the info of the default network interfaces
            // iterate through network interfaces
            device_system_information.networkInterfaces.map(networkInterface => {
                // check if this is the default one
                if(networkInterface.iface == device_system_information.networkInterfaceDefault)
                {
                    default_network_ip4         = networkInterface.ip4 
                    default_network_ip4subnet   = networkInterface.ip4subnet 
                    default_network_mac         = networkInterface.mac 
                }
            })
            
            
            let vitals = {
                memory_free_bytes,
                memory_used_bytes,
                cpu_load_percent,
                os_distribution,
                os_architecture,
                default_network_ip4,
                default_network_ip4subnet,
                default_network_mac
            }
            setDeviceInfo(vitals)
        }
        
    }

  return (
    <div className={`px-1 sm:pl-3 sm:pr-3 py-1 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide`}> 
        {/* Breadcrumv=bs */}
        <nav className='mb-2 flex items-center'>
            <ol className='hidden sm:flex'>
                <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/projects`)}>Projects</li>
                <li className='breadcrumbsSpacer'>/</li>
                <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/projects/${selectedDevice.project_uuid}`)}>{selectedDevice.project_name  ? selectedDevice.project_name :''}</li>
                <li className='breadcrumbsSpacer'>/</li>
                <li className='breadcrumbsCurrent'>{selectedDevice.device_name  ? selectedDevice.device_name :''}</li>
            </ol>
            <ol className='flex sm:hidden'>
                <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/projects`)}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                    <span>Devices</span>
                </li>
            </ol>
        </nav>
        {/* nagivations links within assets page */}
        {selectedOrganization && selectedSubLink && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}

        <div className='grid grid-cols-5 gap-4 p-4 bg-slate-100 dark:bg-slate-800 rounded-xl my-4'>
            <div className='flex flex-col space-y-1 col-span-2'>
                <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 my-auto text-base font-normal pr-2'>Name:</span>
                    <span className='pr-3'>
                    {selectedDevice.device_is_online ? (
                        <div className="relative">
                            <div className="w-4 h-4 bg-transparent"></div>
                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                        </div>
                    ):(
                        <div className="relative">
                            <div className="w-4 h-4 bg-transparent"></div>
                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                        </div>
                    )}
                    </span>
                    <span className='text-gray-600 dark:text-gray-300 text-base font-medium'>{selectedDevice.device_name? selectedDevice.device_name : ''}</span>
                </div>
                <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 my-auto text-base font-normal pr-2'>Description:</span>
                    <span className='text-gray-600 dark:text-gray-300 text-base font-medium'>{selectedDevice.device_description? selectedDevice.device_description : ''}</span>
                </div>
            </div>
            <div className='flex flex-col space-y-1 col-span-2'>
                <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 my-auto text-base font-normal pr-2'>Location:</span>
                    <span className='text-gray-600 dark:text-gray-300 text-base font-medium'>
                        {deviceGeolocation && deviceGeolocation.region_code ? 
                        deviceGeolocation.city +', '+ deviceGeolocation.region_code +', '+ deviceGeolocation.country_code 
                        :''}
                    </span>
                </div>
                <div className='flex items-center'>
                    <span className='text-gray-500 dark:text-gray-400 my-auto text-base font-normal pr-2'>Last Connection Established:</span>
                    <span className='text-gray-600 dark:text-gray-300 text-base font-medium'>{selectedDevice.device_last_heard? handleDateFormat(selectedDevice.device_last_heard) : ''}</span>
                </div>
            </div>
            <div className='items-center flex justify-end '>
                <div className='ml-auto'>
                    <RebootDeviceModal device={selectedDevice} btnType='standardBtn' />
                </div>
                <div className='ml-2'>
                    <DownloadDataSteamModal device={selectedDevice} btnType='tableBtn' />
                </div>
            </div>
        </div>

        {selectedOrganization && selectedSubLink && selectedDevice && Object.keys(selectedDevice).length > 0 && subLinkPage(selectedSubLink, selectedDevice, deviceInfo) }
        
    </div>
  )
}

export default MainSelectedDevicePage


const subLinkPage = (link, selectedDevice, deviceInfo) => {
    let linkUrl = link.url
    const allLinks = {
        overview: { link: "overview", content: <DeviceOverviewPage selectedDevice={selectedDevice} deviceInfo={deviceInfo} /> }, 
        dataStreams: { link: "dataStreams", content: <DeviceDataStreamsPage selectedDevice={selectedDevice}  /> },
        remoteConnection: { link: "remoteConnection", content: <RemoteConnectionsPage selectedDevice={selectedDevice} /> },
        messaging: { link: "messaging", content: <ApiMessagingPage selectedDevice={selectedDevice}  /> },
    };

    

      return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={allLinks[linkUrl].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
            {allLinks[linkUrl].content} 
        </motion.div>
      </AnimatePresence>
    )  
            
}