import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from '@mui/material';




function SshConnectedInfoModal({btnType, open, handlerCloseSshInfoModal, server_url_ssh, selectedDevice}) {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const [expanded, setExpanded] = useState(false);


  function closeModal() {
    setIsOpen(false)
    handlerCloseSshInfoModal()
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if(open){
        setIsOpen(true)
    }else{
        setIsOpen(false)
    }
  }, [open])

  

  const handleChange = (panel) => (event, isExpanded) => {
    console.log(event, isExpanded)
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="flex items-center justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>Access</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <svg className='text-blue-600 w-4 h-4 mt-auto mb-0.5' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
      </motion.button>
        }
        {btnType ==='inlineIconBtn' &&
        <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
          </svg>

        </button>
      }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-[90%] transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                    <motion.div
                    key='editNewClient'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                      Access your device remotely via SSH
                      </div>
                      <div className='px-4'>
                      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{backgroundColor:'transparent'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            sx={{
                                backgroundColor: isDark ? '#475569':'#fff',
                                borderRadius: '10px'
                            }}
                        >
                            <div className={`${isDark ? 'text-gray-300':'text-gray-600'}`}>One-line command</div>
                        </AccordionSummary>
                        <AccordionDetails style={{flexDirection: 'column'}}>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>1. Open an SSH client.</b>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            You can use the regular Terminal in MacOS or Linux. For Windows use <a href='https://docs.microsoft.com/en-us/windows/wsl/install' target="_blank" style={{ textDecoration: 'none'}}>WSL</a>.
                            </Typography> 
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>2. Connect to your device using its Public DNS:</b>             
                            </Typography> 
                            {selectedDevice.device_is_virtual?
                            <>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>ssh iotflows@{selectedDevice.device_uuid}.{server_url_ssh}.ssh -o ProxyCommand="openssl s_client -quiet -servername {selectedDevice.device_uuid}.{server_url_ssh}.ssh -connect {server_url_ssh}:443"</code>&nbsp;    
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                Admin user password is <strong>password</strong>
                            </Typography> 

                            <Alert  severity="warning"  >
                                It is highly recommended to change the password of your machine:
                                <br/>
                                <span>1. SSH into server with old <b>password</b></span>
                                <br/>
                                <span>2. Run <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>sudo passwd iotflows</code></span>
                                <br/>
                                <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                            </Alert> 
                            </>
                            :
                            <>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>ssh <span style={{color: 'rgb(74, 140, 207)'}}>username</span>@{selectedDevice.device_uuid}.{server_url_ssh}.ssh -o ProxyCommand="openssl s_client -quiet -servername {selectedDevice.device_uuid}.{server_url_ssh}.ssh -connect {server_url_ssh}:443"</code>&nbsp; 
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                Replace <span style={{color: 'rgb(74, 140, 207)'}}>username</span> with the username of your device such as pi, debian, ubuntu, or root.
                            </Typography> 

                            <Alert  severity="warning"  >
                                It is highly recommended to change the password of your machine:<br/>
                                <span>1. SSH into server with old <b>password</b></span>
                                <br/>
                                <span>2. Run <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>sudo passwd <span style={{color: 'rgb(74, 140, 207)'}}>USERNAME</span></code></span>
                                <br/>
                                <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                            </Alert> 
                            </>
                            }
                        </AccordionDetails>
                        </Accordion>
                        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{backgroundColor:'transparent'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            sx={{
                                backgroundColor: isDark ? '#475569':'#fff',
                                borderRadius: '10px'
                            }}
                        >
                            <div className={`${isDark ? 'text-gray-300':'text-gray-600'}`} >Using config file</div>
                        </AccordionSummary>
                        <AccordionDetails style={{flexDirection: 'column'}}>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>1. Open an SSH client.</b>
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            You can use the regular Terminal in MacOS or Linux. For Windows use <a href='https://docs.microsoft.com/en-us/windows/wsl/install' target="_blank" style={{ textDecoration: 'none'}}>WSL</a>.
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>2. Open the ssh config file:  </b>                
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>sudo nano ~/.ssh/config</code> 
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            Make sure you have the following server in your ssh configuration file, it may change:&nbsp;
                            <code className={`${isDark ? 'text-blue-300':'text-blue-600'} text-sm`}  >{server_url_ssh}</code>
                            </Typography> 
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>3. Add this host in a new line if it doesn't exist:</b>            
                            </Typography> 
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>Host *.{server_url_ssh}.ssh<br/>&nbsp;&nbsp;&nbsp;&nbsp;ProxyCommand openssl s_client -quiet -servername %h -connect {server_url_ssh}:443</code>                          
                            </Typography> 
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                            <b>4. Connect to your device using its Public DNS:</b>             
                            </Typography> 
                            {selectedDevice.device_is_virtual?
                            <>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>ssh iotflows@{selectedDevice.device_uuid}.{server_url_ssh}.ssh</code> &nbsp;  
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                Admin user password is <strong>password</strong>
                            </Typography> 

                            <Alert severity="warning"  >
                                It is highly recommended to change the password of your machine:<br/>
                                <span>1. SSH into server with old <b>password</b></span>
                                <br/>
                                <span>2. Run <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>sudo passwd iotflows</code></span>
                                <br/>
                                <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                            </Alert> 
                            </>
                            :
                            <>
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>ssh <span style={{color: 'rgb(74, 140, 207)'}}>username</span>@{selectedDevice.device_uuid}.{server_url_ssh}.ssh</code> &nbsp;  
                            </Typography>  
                            <Typography variant="subtitle1" gutterBottom sx={{color: isDark ? '#d1d5db':'#4b5563'}}>
                                Replace <span style={{color: 'rgb(74, 140, 207)'}}>username</span> with the username of your device such as pi, debian, ubuntu, or root.
                            </Typography> 

                            <Alert  severity="warning"  >
                                It is highly recommended to change the password of your machine:<br/>
                                <span>1. SSH into server with old <b>password</b></span>
                                <br/>
                                <span>2. Run <code className={`${isDark ? 'text-rose-300':'text-rose-600'} text-sm`}>sudo passwd <span style={{color: 'rgb(74, 140, 207)'}}>USERNAME</span></code></span>
                                <br/>
                                <span>3. Follow the instructions to <b>change</b> the <b>password</b></span>
                            </Alert> 
                            </>
                            }
                        </AccordionDetails>
                        </Accordion>
                      </div>
                      <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        {/* <button
                          type="submit"
                          className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitCreateClient}
                          disabled={form.device_name == '' || loading}
                        >
                          Edit
                          {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                        </button> */}
                      </div>
                    </motion.div>

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SshConnectedInfoModal