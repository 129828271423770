import React from 'react'
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

function CsvTable({headers, data}) {
    const isDark = useRecoilValue(darkModeState);
  return (
    <div className={`p-4 ${isDark? 'bg-slate-700':'text-slate-100'} rounded-lg `}>
        <div className='w-full flex'>
          <div className={`text-lg items-center font-bold ${isDark? 'text-gray-400':'text-gray-500'}`} >
            CSV Data
          </div>
        </div>
          <table className={`table-fixed w-full `}>
            <thead>
                <tr className={`border-b-[0] dark:border-slate-600 text-left ${isDark? 'text-gray-400':'text-gray-500'} `}>
                    {headers.map((header,index) => (
                    <th key={`${index}-header`}>{header}</th>
                    ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                  <tr key={index} className={`${isDark? 'border-slate-600 ':'hover:bg-slate-200'} border-b-[0.01em]`}>
                    {row.map((dataInCell, index) => (
                      <td key={`${index}-cell`} className={`${isDark? 'text-gray-300':'text-gray-600'} text-sm font-light truncate py-0.5`}>{dataInCell}</td>
                    ))}
                  </tr>
                
              ))}
            </tbody>
          </table>
        
        
      </div>
  )
}

export default CsvTable