import EditApiKeyModal from '../components/modals/ApiKeysModals/EditApiKeyModal';
import DeleteApiKeyModal from '../components/modals/ApiKeysModals/DeleteApiKeyModal';


function ApiKeysTableRow({el, refreshClientList}) {
    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return shortcurrentDate  ;
            // return currentStrTime + "  " + shortcurrentDate  ;
        }
    }
  return (
    <tr key={el.organization_event_id} className='border-b-[0.01em] dark:border-slate-500 hover:bg-slate-200 dark:hover:bg-slate-600'>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
                {el.client_name}
        </td>
        <td className=' font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.client_is_organization_subscription_only ? 
                <div className='text-sm rounded h-5 py-0.5 px-1.5 text-center w-[70px] bg-[#c9d0f4] ' >
                    <span className='text-xs' style={{ color:'rgb(14, 98, 69)'}}>Read Only</span>
                </div>
                :
                <div className='text-sm rounded h-6 py-0.5 px-1.5 text-center w-[115px] bg-[#cbf4c9]  '>
                <span className='text-xs' style={{ color:'rgb(138, 138, 138)'}} >Read/Write Access</span>
                </div>
            }
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 '>
                {el.client_description}
        </td>
        <td className='text-xs text-red-400 dark:text-red-400 px-1 py-3 '>
            <code>{el.client_id}</code>
        </td>
        <td className='text-sm font-light text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            <div className='flex items-center ml-auto justify-end'>
                <div className=''>
                    {handleDateFormat(el.client_created_at)}
                </div>
                <div className='w-10'>
                    <EditApiKeyModal client={el} refreshClientList={refreshClientList} btnType='inlineIconBtn' />
                </div>
                <div className='w-10'>
                    <DeleteApiKeyModal client={el} refreshClientList={refreshClientList} btnType='inlineIconBtn' />
                </div>

            </div>
        </td>
    </tr>
  )
}

export default ApiKeysTableRow