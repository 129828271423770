
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import toast from 'react-hot-toast';
import ReactJson from 'react-json-view';




function DeviceInfoModal({btnType, device}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    


  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  


  const dataJSONFormater = (deviceInfo) => {
    try{
      // var parsedDeviceData = JSON.parse(deviceInfo)

      return <ReactJson src={deviceInfo} theme={isDark ? 'monokai':''} /> 
    }
    catch(e){
      console.log('error converting to JSON')
      console.log(e)
      toast.error('Issues displaying device information')
      return deviceInfo
    }
  }
  

  return (
    <>
      <div className=" justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>download</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className={`flex p-2 text-sm items-baseline ml-2 ${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} outline-0 focus:outline-0`} onClick={openModal} >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
        </svg>
      </motion.button>
        }
        {btnType ==='inlineIconBtn' &&
            <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
        </button>
      }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-2xl transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                    <motion.div
                    key='editNewClient'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                      {device.device_name} device system information
                      </div>
                      <div className='px-4 max-h-[400px] overflow-scroll scrollbar-hide'>
                        {dataJSONFormater(device.device_system_information)}
                      </div>
                    </motion.div>
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                    <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    </div>

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default DeviceInfoModal