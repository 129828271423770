import ReactMapGL, {Marker, Popup} from 'react-map-gl';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import getCenter from 'geolib/es/getCenter';
import { useEffect, useState } from 'react';
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function Map({deviceList, devicesDic}) {
    const [viewport, setViewport] = useState(
        {
            width: '100%',
            height: '100%',
            latitude: 37.7577,
            longitude: -122.4376,
            zoom: 2,
        }
    )
    const [selectedLocation, setSelectedLocation] = useState([])
    const [devicesWithCoordinates, setDevicesWithCoordinates] = useState([])
    const [coordinates, setCoordinates] = useState([])

    

        useEffect(() => {
            if(deviceList && Object.keys(deviceList).length > 0 && devicesDic && Object.keys(devicesDic).length > 0){
                // remove logo
                let elements = document.getElementsByClassName('mapboxgl-ctrl-logo')    
                if(elements && elements.length) elements[0].parentNode.removeChild(elements[0]);
    
                //   Tranform devices to lat and long
                var adjustCoordinates = [];
                var newDeviceInfo = [];
                var center = [];
                deviceList.map((results, index) => {
                    if(results.device_peer_host){
                        getData(apiLink + '/v1/geolocation/ipaddress/'+ results.device_peer_host)
                        .then(async response => {
                        if(response.ok ){
                            const data = await response.json();
                            if(data.data){
                                devicesDic[results.device_uuid].latitude = data.data.latitude;
                                devicesDic[results.device_uuid].longitude = data.data.longitude;
                                devicesDic[results.device_uuid].country_code = data.data.country_code;
                                devicesDic[results.device_uuid].city = data.data.city;
                                devicesDic[results.device_uuid].country_flag_emoji = data.data.location.country_flag_emoji;
    
                                newDeviceInfo.push(devicesDic[results.device_uuid]);
                                let array = {'latitude': data.data.latitude, 'longitude': data.data.longitude};
                                adjustCoordinates.push(array);
                                setCoordinates(adjustCoordinates)
                                setDevicesWithCoordinates(newDeviceInfo)
                                center = getCenter(adjustCoordinates);
                                var newViewport = {
                                    width: '100%',
                                    height: '100%',
                                    latitude: center.latitude,
                                    longitude: center.longitude,
                                    zoom: 2,
                                }
                                setViewport(newViewport)
                            }
                        }
                        });
                    }
                })

            }

        }, [deviceList, devicesDic])

        useEffect(() => {
            if(Object.keys(devicesWithCoordinates).length > 0 && (Object.keys(devicesDic).length < Object.keys(devicesWithCoordinates).length)){
                // console.log("adjusting COORDINATES")
                //   Tranform devices to lat and long
                let adjustCoordinates = [];
                let newDeviceInfo = [];
                let center = [];
                deviceList.map((results, index) => {
                    if(results.device_peer_host){
                        getData(apiLink = '/v1/geolocation/ipaddress/'+ results.device_peer_host)
                        .then(async response => {
                        if(response.ok ){
                            const data = await response.json();
                            if(data.data){
                                devicesDic[results.device_uuid].latitude = data.data.latitude;
                                devicesDic[results.device_uuid].longitude = data.data.longitude;
                                devicesDic[results.device_uuid].country_code = data.data.country_code;
                                devicesDic[results.device_uuid].city = data.data.city;
                                devicesDic[results.device_uuid].country_flag_emoji = data.data.location.country_flag_emoji;
          
                                newDeviceInfo.push(devicesDic[results.device_uuid]);
                                let array = {'latitude': data.data.latitude, 'longitude': data.data.longitude};
                                adjustCoordinates.push(array);
                                setCoordinates(adjustCoordinates)
                                setDevicesWithCoordinates(newDeviceInfo)
          
                                center = getCenter(adjustCoordinates);
                                var newViewport = {
                                    width: '100%',
                                    height: '100%',
                                    latitude: center.latitude,
                                    longitude: center.longitude,
                                    zoom: 2,
                                }
                                setViewport(newViewport)
                            }
                        }
                        });
                    }
                })
              }

        }, [devicesWithCoordinates])


  return (
    <ReactMapGL 
            mapStyle='mapbox://styles/iotflows/cks2ifj255lqk17p2i5nwheqy'
            mapboxApiAccessToken='pk.eyJ1IjoiaW90Zmxvd3MiLCJhIjoiY2tzMmk2anFuMG9waTJ5bzRoN2FkbHJmNSJ9.OqgJVydjwZerowqBY-K2lA'
            {...viewport}
            width="100%"
            height="100%"
            attributionControl={false}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            
        >
          {Object.keys(devicesWithCoordinates).length <= Object.keys(devicesDic).length &&
            devicesWithCoordinates.map(result => (
                <div key={result.device_uuid}>
                    <Marker
                        latitude={result.latitude}
                        longitude={result.longitude}
                        // offsetLeft={-20}
                        // offsetTop={-10}
                    >
                        {devicesDic[result.device_uuid].device_is_online != undefined && devicesDic[result.device_uuid].device_is_online ? 
                            <div onClick={() => setSelectedLocation(result)}  className='relative'>
                                    <div className="w-4 h-4 bg-transparent"></div>
                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                            </div>
                        : 
                            <div onClick={() => setSelectedLocation(result)}  className="relative"> 
                                <div className="w-4 h-4 bg-transparent"></div>
                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-400"></div>
                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-400"></div>
                            </div> 
                        }
                    </Marker>

                    {/* Popup if we click on a marker */}
                    {selectedLocation.longitude === result.longitude ? (
                        <Popup 
                            onClose={() => setSelectedLocation([])}
                            closeOnClick={true}
                            latitude={result.latitude}
                            longitude={result.longitude}
                            // tipSize={15}
                        >
                            <div >
                                <div className='pt-2 pb-0.5 px-1 bg-transparent'>
                                    {result.device_name}
                                </div>
                                <div className='text-sm py-0.5 px-1'>
                                    {result.city},&nbsp;{result.country_code}&nbsp;{result.country_flag_emoji}
                                </div>
                              
                            </div>
                            
                        </Popup>
                        ): (
                            false
                        )
                    }
                </div>
            ))
        }
        </ReactMapGL>
  )
}

export default Map