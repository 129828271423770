import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';


function UptimeLineChart({chartName, data, tooltipTitle}) {
    const [chartOptions, setChartOptions] = useState({})
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        if(data){
            setChartOptions({
                scrollbar: {
                  enabled: false
                },
                credits: {enabled: false},
                title: {enabled: false},
                chart: {
                  height: '240px',
                  alignTicks: false,
                  backgroundColor: 'transparent'
                },
                time: {
                    timezoneOffset: new Date().getTimezoneOffset()
                  },
                rangeSelector:{
                allButtonsEnabled: true,
                buttons: [
                    {
                    type: 'day',
                    count: 1,
                    text: '1d'
                }, {
                    type: 'day',
                    count: 7,
                    text: '7d'
                }, {
                    type: 'day',
                    count: 14,
                    text: '14d'
                },{
                    type: 'month',
                    count: 1,
                    text: '1m'
                },{
                    type: 'all',
                    text: 'All'
                }],
                selected: 4,
                buttonTheme: { // styles for the buttons
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 8,
                    style: {
                        color: isDark? '#fff': '#3b82f6',
                        fontWeight: 'bold'
                    },
                    states: {
                        hover: {
                        },
                        select: {
                            fill: '#039',
                            style: {
                                color: 'white'
                            }
                        }
                    // disabled: { ... }
                    }
                    },
                },
                plotOptions: {
                    area: {
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    },
                    series: {
                        lineWidth: 0,
                      },
                },
                xAxis: {       
                  ordinal: false,
                  gridLineWidth: 1,
                  scrollbar: {enabled: false},
                  labels: {
                    style: {
                        color: isDark ? '#d1d5db':'#6b7280'
                    }
                }
                },
                yAxis: {
                  gridLineWidth: 0,
                  allowDecimals: false,
                  ceiling: 1,
                  max: 1,
                //   tickPositioner: function (min, max) {
                //     var ticks = [],
                //         interval = (max - min) / (this.chart.yAxis[0].tickPositions.length - 1);
    
                //     while (min <= max) {
                //         ticks.push(min);
                //         min += interval;
                //     }
    
                //     return ticks;
                //   },
                },
                series: [{
                  type: 'area',
                  color: isDark ? 'rgba(76, 175, 80,0.7)':'#4caf50',
                  data: data,        
                  step: 'left',
                  dataGrouping: {
                    units: [[
                      'millisecond', // unit name
                      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                  ], [
                      'second',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'minute',
                      [1, 2, 5, 10, 15, 30]
                  ], [
                      'hour',
                      [1, 2, 3, 4, 6, 8, 12]
                  ], [
                      'day',
                      [1]
                  ], [
                      'week',
                      [1]
                  ], [
                      'month',
                      [1, 3, 6]
                  ], [
                      'year',
                      null
                  ]]
                  }
                }],
                navigator: {
                  scrollbar: {enabled: false},
                  height: 15,
                  outlineWidth: 0,
                  outlineColor: '#ffffff',
                  xAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  yAxis: {
                    gridLineWidth: 0,
                    gridLineColor: '#ffffff',
                  },
                  chart: {
                    type: 'area',
                    color: '#4caf50',
                  },
                  series: {
                      type: 'area',
                      step: 'left',
                      color: '#4caf50',
                      lineWidth: 0.5,
                  }
                },
              })
        }
    }, [data, isDark])

  return (
    <div className='flex flex-col '>
        <div className='w-full flex justify-center text-xl text-gray-600 dark:text-gray-300 pt-1'>
            {chartName}
        </div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={chartOptions}
            />
        
    </div>
  )
}

export default UptimeLineChart