import { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import GeneralFormInput from '../../forms/GeneralFormInput';
import { postData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { Dialog, Transition } from '@headlessui/react'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-coffee";
import "ace-builds/src-noconflict/mode-css";

import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";




const languagesAvailable = [ 'json','xml', 'html','css' ]

function ExecuteActionModal({btnType, refreshList, deviceAction, selectedDevice}) {
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    let [isOpen, setIsOpen] = useState(false)
    let [loading, setLoading] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    let [editorScript, setEditorScript] = useState(`{ "testCommand": true}`)
    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState('Formatting Issues')
    let [showSelectedLanguage, setShowSelectedLanguage] = useState(false)
    let [languageSelected, setLanguageSelected] = useState('json')
    

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  
  
  const submitCreateClient = () => {
    try {
      if(!loading){
        setLoading(true)
        let editorScriptFormatted = editorScript;
        if(languageSelected == 'json'){
            editorScriptFormatted = JSON.parse(editorScript)
        }
        try{
            console.log(editorScriptFormatted)
            postData(apiLink + '/v1/actions/' + deviceAction.action_uuid,
            editorScriptFormatted)
            .then(async response => {
              const data = await response.json();
              if(response.ok){
                setLoading(false)
                toast.success('Action Executed')
                refreshList()
                closeModal()
                
              }
              else{
                try{
                  toast.error(data.message)
                  setLoading(false)
                }catch (e){
                  setLoading(false)
                  console.log('error')
                  toast.error(e.message)
                }
              }
            }) 
          }catch(err){
                console.log(err.message)
                toast.error('Incorrect JSON Format')
                setErrorMessage(err.toString())
            }
        } 
    } catch (error) {
      console.log(error.message)
    }

  }


  const handleSelectedLanguage = (language) => {

    setLanguageSelected(language)
    setShowSelectedLanguage(false)

  }

  const handleEditorChange = (newValue) => {
    setEditorScript(newValue)
  }

  

  return (
    <>
      <div className="flex items-center justify-end">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <svg className='btnMainIcon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
            <span className='btnMainText'>Edit Action</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <svg className='text-blue-600 w-4 h-4 mt-auto mb-0.5' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
      </motion.button>
        }
        {btnType ==='inlineIconBtn' &&
        <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.54 22H10C9.75 22 9.54 21.82 9.5 21.58L9.13 18.93C8.5 18.68 7.96 18.34 7.44 17.94L4.95 18.95C4.73 19.03 4.46 18.95 4.34 18.73L2.34 15.27C2.21 15.05 2.27 14.78 2.46 14.63L4.57 12.97L4.5 12L4.57 11L2.46 9.37C2.27 9.22 2.21 8.95 2.34 8.73L4.34 5.27C4.46 5.05 4.73 4.96 4.95 5.05L7.44 6.05C7.96 5.66 8.5 5.32 9.13 5.07L9.5 2.42C9.54 2.18 9.75 2 10 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.79 8.95 21.73 9.22 21.54 9.37L19.43 11L19.5 12V12.19C19 12.07 18.5 12 18 12C17.83 12 17.66 12 17.5 12.03C17.5 11.41 17.4 10.79 17.2 10.2L19.31 8.65L18.56 7.35L16.15 8.39C15.38 7.5 14.32 6.86 13.12 6.62L12.75 4H11.25L10.88 6.61C9.68 6.86 8.62 7.5 7.85 8.39L5.44 7.35L4.69 8.65L6.8 10.2C6.4 11.37 6.4 12.64 6.8 13.8L4.68 15.36L5.43 16.66L7.86 15.62C8.63 16.5 9.68 17.14 10.87 17.38L11.24 20H12.35C12.61 20.75 13 21.42 13.54 22M15.96 12.36C16 12.24 16 12.12 16 12C16 9.79 14.21 8 12 8S8 9.79 8 12 9.79 16 12 16C12.12 16 12.24 16 12.36 15.96C12.97 14.29 14.29 12.97 15.96 12.36M12 14C10.9 14 10 13.11 10 12S10.9 10 12 10 14 10.9 14 12 13.11 14 12 14M16 15V21L21 18L16 15Z"/>
            </svg>

        </button>
      }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <AnimatePresence mode='wait'>
                    <motion.div
                    key='editNewClient'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    >
                      <div className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}>
                        Execute Action
                      </div>
                      <div className='px-4'>
                        <div onClick={() => setShowSelectedLanguage(!showSelectedLanguage)} 
                            className={`${isDark ? 'bg-slate-600 text-blue-300':'bg-slate-50 text-blue-600'} cursor-pointer h-10 w-26 flex items-center w-fit px-2 rounded-t-lg relative`}
                        >
                            <span className='pl-1.5 uppercase' >
                                {languageSelected}
                            </span>
                            <span className='pl-1.5' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </span>
                            {showSelectedLanguage && 
                            <div className={`${isDark? 'bg-slate-600':'bg-slate-50'} w-[200px] flex flex-col absolute z-50 rounded-tr-xl rounded-b-xl p-1 top-10 left-0`}  >
                                {languagesAvailable.map(language => (
                                <div key={language} className={`w-full px-2 py-1 cursor-pointer flex ${isDark? 'text-blue-300 hover:bg-slate-500':'text-blue-600 hover:bg-slate-100'} `} onClick={() => handleSelectedLanguage(language)} >
                                    {language}
                                </div>
                                ))}
                            </div>
                            }
                        </div>
                            <AceEditor
                            placeholder="Place code here"
                            mode={languageSelected}
                            theme="solarized_dark"
                            name="blah2"
                            onChange={handleEditorChange}
                            fontSize={14}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={editorScript}
                            style={{width: 'auto', borderRadius: '0px 10px 10px 10px'}}
                            setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2,
                            useWorker: false
                            }}/>

                            {error && 
                            <div style={{marginTop:'14px', marginBottom: '10px'}}>
                                <span style={{fontSize:'14px', color: '#DC2626'}}>
                                {errorMessage}
                                </span>
                            </div>
                            }
                      </div>
                      <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitCreateClient}
                          disabled={ loading}
                        >
                          Execute
                          {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                        </button>
                      </div>
                    </motion.div>

                </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ExecuteActionModal