import { useState, useRef, useEffect } from 'react';
import GeneralFormInput from '../components/forms/GeneralFormInput';
import IoTFlowsLogo from '../images/IoTFlowsLogoBlackFont.png';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {Amplify,  Auth, Hub } from 'aws-amplify';
import {useLocation} from 'react-router-dom';
import {auth} from '../data/AwsConfigData'

Amplify.configure({
    Auth: auth
});


function VerifyEmailPage() {
    const navigate = useNavigate();
    const location = useLocation();

    var username = ''
    if(location && location.state && location.state.username ){
        username = location.state.username
    }

    const resendConfirmation = () => {
        try {
            Auth.resendSignUp(username)
            .then(data => {
                // console.log('resend data', data)
                // this.props.showSuccessSnackbar('Verification email has been sent')
            })
            .catch(err => {
                console.log('err', err)
                // this.props.showErrorSnackbar(err);
                }
            );
        } catch (err) {
            console.log('error resending code: ', err);
            // this.props.showErrorSnackbar(err.message)
        }
    }
   



  return (
    <div className='overflow-scroll h-screen'>
        <div className='fixed inset-x-0 top-[-250px] bottom-0 z-[-1] -skew-y-12'>
            <div className='absolute top-[-1000px] left-0 right-0 origin-[0 50%] h-[1630px] bg-slate-100 '>
            </div>
            <div className='top-[630px] left-[-10px] right-[calc(50%+135px)] h-12 absolute bg-blue-300 md:right-[calc(50%+505px)] xl:right-[calc(50%+605px)]'>
            </div>
            <div className='top-[580px] right-[-10px] left-[calc(100%-110px)] h-12 absolute bg-blue-300 md:left-[calc(100%-230px)]'>
            </div>
        </div>
        <div className='flex flex-col mx-auto pt-7 max-w-[382px] sm:pt-14 sm:max-w-[540px] h-full'>
            <div className='text-left ml-3 pl-5 pb-6 sm:pl-4 sm:pb-10 '>
                <img src={IoTFlowsLogo} className='h-10' alt="IoTFlows Inc" loading="lazy" />
            </div>
            <div className='shadow-iotflows-xl w-full bg-white rounded-2xl py-8 px-5 my-0 mx-4 sm:py-14 sm:px-12'>
                <AnimatePresence >
                        <div key={'resetSection'} >
                            <h1 className={`text-2xl font-medium text-gray-600`}>Thanks, check your email for a verification link to activate your account.</h1>
                            {username && 
                                <div className={`text-sm font-normal mb-2 mt-2 text-gray-600`}  >
                                Didn't get the email? Check your spam folder or <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={resendConfirmation}>resend</span>
                                </div>
                            }
                            

                            
                            <div className={`w-full text-center mt-4 text-gray-500 text-sm font-medium`} >
                                <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={() => navigate('/login')}>Return to login</span>
                            </div>

                        </div>
                </AnimatePresence>
            </div>

            <div className='grow shrink'/>
            <section className='mx-auto' >
                <div className='p-3'>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/" >© IoTFlows Inc 2024</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                </div>
            </section>

        </div>
    </div>
  )
}

export default VerifyEmailPage